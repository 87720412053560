import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminHeader from "./AdminHeader";
import toast from "react-hot-toast";
import { Contract_details, NODE_URL } from "../Components/Config";

const Airdrop = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [tokenAmount, setTokenAmount] = useState("");
  const [message, setMessage] = useState("");
  console.log(walletAddress, tokenAmount);

  const handleSend = async () => {
    console.log(walletAddress, tokenAmount);
    if (!walletAddress && !tokenAmount) {
      setMessage("Please enter both the wallet address and the token amount.");
      return;
    }
    if (!walletAddress) {
      setMessage("Please enter the wallet address.");
      return;
    }
    if (!tokenAmount || tokenAmount <= 0) {
      setMessage("Please enter a valid token amount.");
      return;
    }

    try {
      const transfer = await axios.get(
        `${NODE_URL}/apiv2/Airdrop?walletAddress=${walletAddress}&tokenAmount=${Number(
          tokenAmount
        )}`
      );

      if (transfer.data && transfer.data.status === "success") {
        setMessage(
          `Amount sent to admin: ${tokenAmount} tokens to ${walletAddress}`
        );
      } else {
        setMessage("Failed to send tokens.");
      }
    } catch (error) {
      console.error("Error sending tokens:", error);
      setMessage("Error sending tokens. Please try again.");
    }
  };

  const ConvertTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDateTime = date.toISOString().slice(0, 19).replace("T", " ");
    return formattedDateTime;
  };

  const [transactions, setTransactions] = useState([]);
  useEffect(() => {
    const FetchHistory = async () => {
      try {
        const res = await axios.get(
          `${NODE_URL}/apiv2/getAirdropTransactions`,
          {
            headers: {
              "x-api-key": "nqojwskkskksluyhucgnxqggcewpachi",
            },
          }
        );
        console.log(res);
        if (res.data.status === true) {
          setTransactions(res.data.data);
        } else {
          toast.error("Unable to fetch History");
        }
      } catch (error) {
        console.error("Error fetching history:", error);
        toast.error("Unable to fetch History");
      }
    };

    FetchHistory();
  }, []);

  const getChain = (id, type, hash = null) => {
    console.log(id, type);
    let URL, SYMBOL;
    switch (id) {
      case Contract_details.POLYGON_CHAIN_ID:
        URL = `${Contract_details.POLYGON_EXPLORER}${hash}`;
        SYMBOL = "POLYGON";
        break;
      case Contract_details.ETH_CHAIN_ID:
        URL = `${Contract_details.ETH_EXPLORER}${hash}`;
        SYMBOL = "ETHEREUM";
        break;
      case Contract_details.BSC_CHAIN_ID:
        URL = `${Contract_details.BSC_EXPLORER}${hash}`;
        SYMBOL = "BINANCE";
        break;
      default:
        URL = `${Contract_details.BASE_EXPLORER}${hash}`;
        SYMBOL = "BASE";
        break;
    }
    if (type == "Network") {
      return SYMBOL;
    } else {
      return URL;
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = transactions.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  return (
    <div
      className="bg-black bg-no-repeat bg-center bg-cover min-h-screen pb-10"
      style={{ backgroundImage: 'url("/assets/bcg-image.png")' }}
    >
      <AdminHeader />
      <div className="flex justify-center pt-10 2xl:pt-28 gap-10 py-5 px-5">
        <div className="w-[30%] mt-3 items-center">
          <div className="p-6 mx-auto rounded shadow-md bg-[#9d47d2]">
            <h1 className="text-xl font-bold mb-4 text-white">
              Transfer Tokens
            </h1>
            <div className="mb-4">
              <label className="block text-white">Wallet Address:</label>
              <input
                type="text"
                className="mt-1 block w-full bg-[#fff] text-black p-2 border border-gray-300 rounded"
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-white">Token Amount:</label>
              <input
                type="text"
                className="mt-1 block w-full bg-[#fff] text-black p-2 border border-gray-300 rounded"
                value={tokenAmount}
                onChange={(e) => setTokenAmount(e.target.value)}
              />
            </div>
            <button
              onClick={handleSend}
              className="w-full bg-[#000] text-white font-bold p-2 rounded"
            >
              Send
            </button>
            {message && (
              <p className="mt-4 text-center text-red-500">{message}</p>
            )}
          </div>
        </div>
        <div className="md:w-[70%] mx-auto border-2 border-[#787AA0] bg-[#9d47d2] backdrop-blur-[1px] rounded-3xl py-2 px-10 mt-2">
          <div className="flex justify-between pb-5">
            <div>
              <h1
                className="text-xl text-white font-bold font-impact tracking-wider"
                style={{
                  WebkitTextStrokeWidth: "0.5px",
                  WebkitTextStrokeColor: "black",
                }}
              >
                Transaction History
              </h1>
            </div>
            <div className="flex items-center">
              <p className="text-end text-sm px-3 mb-2 text-white">
                Show no of entity
                <select
                  className="ml-2 outline-none rounded-md bg-black border-[1px]"
                  onChange={handleDropdownChange}
                  value={entitiesPerPage}
                >
                  <option className="text-white" value="5">
                    5
                  </option>
                  <option className="text-white" value="10">
                    10
                  </option>
                  <option className="text-white" value="20">
                    20
                  </option>
                </select>
              </p>
            </div>
          </div>
          <div className="pb-5 rounded-xl overflow-hidden overflow-x-auto">
            <table className="w-full text-center">
              <thead>
                <tr>
                  <th className="text-white text-xs px-2">Tokens Purchased</th>
                  <th className="text-white text-xs px-2">
                    Date of transaction
                  </th>
                  <th className="text-white text-xs px-2">Payment Network</th>
                  <th className="text-white text-xs px-2">User Address</th>
                  <th className="text-white text-xs px-2">Token Purchased</th>
                  <th className="text-white text-xs px-2">Admin Trans Hash</th>
                  <th className="text-white text-xs px-2">
                    Admin Trans Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentEntities.map((transaction, index) => (
                  <tr
                    key={index}
                    className="h-16 text-sm md:text-base text-center rounded-md"
                  >
                    <td className="font-bold px-5 md:px-0 text-white">TRUMP</td>
                    <td className="font-bold px-5 md:px-0 text-white">
                      {ConvertTime(transaction.createdAt)}
                    </td>
                    <td className="font-bold px-5 md:px-0 text-white">
                      {getChain(transaction.chainId, "Network")}
                    </td>
                    <td className="font-bold px-5 md:px-0 text-white">
                      {transaction.userAddress !== null
                        ? `${transaction.userAddress.substring(0, 10)}...`
                        : ""}
                    </td>
                    <td className="font-bold px-5 md:px-0 text-white">
                      {transaction.TokenAmount}
                    </td>
                    <td className="font-bold px-5 md:px-0 text-white">
                      {transaction.TokenHash !== null ? (
                        <a
                          href={`https://explorer.solana.com/tx/${transaction.TokenHash}?cluster=devnet`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {`${transaction.TokenHash.substring(0, 10)}...`}
                        </a>
                      ) : (
                        "Yet to Transfer"
                      )}
                    </td>

                    <td className="font-bold px-5 md:px-0 text-white">
                      {transaction.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-2 flex justify-between text-xs px-3">
            <button
              className="bg-white/30 rounded-md px-5 py-1"
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            <button
              className="bg-white/30 rounded-md px-5 py-1"
              onClick={handleNextClick}
              disabled={currentEntities.length < entitiesPerPage}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Airdrop;