import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routers from "./route";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { WalletProvider } from "./Components/WalletContext";
import { Contract_details } from "./Components/Config";
import { TonConnectUIProvider } from '@tonconnect/ui-react';

const projectId = "5264b9e671c33081766f236f20fbe2b8";

const polygon = {
  chainId: Contract_details.POLYGON_CHAIN_ID,
  name: Contract_details.POLYGON_NAME,
  currency: "MATIC",
  explorerUrl: Contract_details.POLYGON_EXPLORER,
  rpcUrl: Contract_details.POLYGON_RPC,
};

const ethereum = {
  chainId: Contract_details.ETH_CHAIN_ID,
  name: Contract_details.ETH_NAME,
  currency: "ETH",
  explorerUrl: Contract_details.ETH_EXPLORER,
  rpcUrl: Contract_details.ETH_RPC,
};

const bsc = {
  chainId: Contract_details.BSC_CHAIN_ID,
  name: Contract_details.BSC_NAME,
  currency: "BNB",
  explorerUrl: Contract_details.BSC_EXPLORER,
  rpcUrl: Contract_details.BSC_RPC,
};

const base = {
  chainId: Contract_details.BASE_CHAIN_ID,
  name: Contract_details.BASE_NAME,
  currency: "ETH",
  explorerUrl: Contract_details.BASE_EXPLORER,
  rpcUrl: Contract_details.BASE_RPC,
};

const metadata = {
  name: "My Website",
  description: "My Website description",
  url: "https://realmemecoin.com",
  icons: ["https://skilleareum.ai/assets/logo.png"],
};

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  rpcUrl: "...",
  defaultChainId: 137,
});

createWeb3Modal({
  ethersConfig,
  chains: [ethereum, polygon, bsc, base],
  projectId,
  themeVariables: {
    "--w3m-accent": "orange",
  },
  enableAnalytics: true,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <WalletProvider>
      <TonConnectUIProvider manifestUrl="https://ton.vote/tonconnect-manifest.json">
        <Routers />
      </TonConnectUIProvider>
    </WalletProvider>
  </Router>
);

reportWebVitals();
