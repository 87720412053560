import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "animate.css";
import "../App.css";

const Header_admin = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleWidth = () => {
    setExpanded(!expanded);
  };

  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const [isDropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    if (isDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdown]);

  const Logout = async () => {
    window.sessionStorage.removeItem("adminLoggedIn");
    window.location.href = "/admin/login";
  };

  return (
    <>
      <div
        className={` ${
          expanded ? "w-[5%]" : "w-[100%]"
        } flex flex-col justify-between ease-in-out duration-500 bg-no-repeat bg-cover  bg-center  font-inter min-h-screen lg:block hidden`}
        style={{ backgroundImage: "url('/assets/fomo-adminside.png')" }}
      >
        <Toaster toastOptions={{ position: "top-right" }} />
        <div className="bg-[#37159F]/50 h-full">
          <div className="flex justify-end pt-5 relative "></div>
          <div className="px-3 mt-5">
            <div className="flex flex-col gap-3 items-center">
              <img
                src="/assets/Bitmap.png"
                className=" w-14 h-14 rounded-full"
              />
              <div className="flex flex-col gap-1 overflow-hidden">
                <p className=" text-white text-sm text-center font-poppins font-bold">
                  ADMIN
                </p>
                <p className=" text-white text-sm text-center font-poppins font-bold">
                  admin@rmc.com
                </p>
              </div>
            </div>
          </div>

          <hr className=" my-3 mx-3" />

          <div className="px-5 text-xs">
            <ul className="flex flex-col justify-center mt-2 ">
              <li className="hover:bg-[#2e2e31] hover:rounded-lg px-1 py-3 flex items-center gap-3 text-xs ">
                <a
                  href="/Admin/admindashboard"
                  className="text-white font-poppins overflow-hidden font-semibold"
                >
                  Master Admin
                </a>
              </li>

              <li className="hover:bg-[#2e2e31] hover:rounded-lg px-1 py-3 flex items-center gap-3 text-xs ">
                <a
                  href="/Admin/Userdashboard"
                  className="text-white font-poppins overflow-hidden font-semibold"
                >
                  User Management
                </a>
              </li>

              <li className="hover:bg-[#2e2e31] hover:rounded-lg px-1 py-3 flex items-center gap-3 text-xs ">
                <a
                  href="/Admin/Settings"
                  className="text-white font-poppins overflow-hidden font-semibold"
                >
                  Token Sale
                </a>
              </li>

              <li className="hover:bg-[#2e2e31] hover:rounded-lg px-1 py-3 flex items-center gap-3 text-xs ">
                <a
                  href="/Admin/Support"
                  className="text-white font-poppins overflow-hidden font-semibold"
                >
                  Chat App
                </a>
              </li>

              <li className="hover:bg-[#2e2e31] hover:rounded-lg px-1 py-3 flex items-center gap-3 text-xs ">
                <a
                  href="/Admin/Notification"
                  className="text-white font-poppins overflow-hidden font-semibold"
                >
                  Bot-Settings
                </a>
              </li>
            </ul>
          </div>
          <hr className=" my-5 mx-3" />
          <div className="px-2  ">
            <button
              className=" rounded-md px-3 mt-5  w-40 py-2 bg-white shadow-lg font-bold font-syne text-black  tracking-wider text-stroke"
              onClick={Logout}
            >
              Log Out
            </button>
          </div>
        </div>

        {/* <div className="px-5 flex flex-col gap-3 py-5">
        <div className="flex items-center gap-2">
          <img src="/assets/question.png" id="Help" />
          <p className="text-xs text-white/90 overflow-hidden">Help</p>
        </div>

        <div className="flex items-center gap-2">
          <img src="/assets/Log-out.png" id="Logout Account" />
          <p className="text-xs text-[#CC8889] overflow-hidden">
            Logout
          </p>
        </div>
      </div> */}

        <ReactTooltip anchorId="Dashboard" place="top" content="Dashboard" />

        <ReactTooltip anchorId="Audience" place="top" content="Audience" />

        <ReactTooltip anchorId="Posts" place="top" content="Posts" />

        <ReactTooltip anchorId="Schedules" place="top" content="Schedules" />

        <ReactTooltip anchorId="Income" place="top" content="Income" />

        <ReactTooltip anchorId="Settings" place="top" content="Settings" />

        <ReactTooltip anchorId="Logout" place="top" content="Logout" />
      </div>
      <div
        className="block lg:hidden  w-[100%] "
        style={{ backgroundImage: "url('/assets/purple.png')" }}
      >
        <div className="flex justify-between px-10 py-10">
          <div></div>
          <div className="ease-in-out duration-300">
            <button onClick={handleDropdown} ref={buttonRef}>
              <i className="fas fa-bars text-white fa-2xl"></i>
            </button>
          </div>
          {isDropdown && (
            <div
              className="dropdown-content text-black z-50 w-[15rem] bg-black absolute right-5 mt-2 top-20 animate__animated animate__fadeInUp flex justify-center"
              ref={dropdownRef}
            >
              <div className=" flex flex-col font-architect gap-3 text-xl  text-white rounded-lg p-5">
                <NavLink
                  to="/Admin/AdminDashboard"
                  onClick={() => setDropdown(false)}
                >
                  Admin Dashboard
                </NavLink>
                {/* <NavLink
              to="/Admin/notification"
              onClick={() => setDropdown(false)}
            >
              Bot-Settings
            </NavLink> */}
                <NavLink
                  to="/Admin/Userdashboard"
                  onClick={() => setDropdown(false)}
                >
                  User Management
                </NavLink>
                {/* <NavLink
              to="/Admin/notification/poll-results"
              onClick={() => setDropdown(false)}
            >
              Poll-Results
            </NavLink> */}
                {/* <NavLink to="/Admin/notification/managepoll" onClick={() => setDropdown(false)}>
              Poll-Settings
            </NavLink> */}
                {/* <NavLink to="/Admin/airdrop" onClick={() => setDropdown(false)}>
              Airdrop
            </NavLink> */}
                <NavLink
                  to="/Admin/Settings"
                  onClick={() => setDropdown(false)}
                >
                  Token-Sale
                </NavLink>
                <NavLink to="/Admin/Support" onClick={() => setDropdown(false)}>
                  Chat App
                </NavLink>
                <NavLink
                  to="/Admin/Notification"
                  onClick={() => setDropdown(false)}
                >
                  Bot-Settings
                </NavLink>

                <div>
                  <button
                    className="text-white text-xl bg-[#DB416B] font-architect rounded-xl px-3 py-1 border border-white hover:shadow-md hover:shadow-white text-stroke"
                    style={{
                      WebkitTextStrokeColor: "black",
                      WebkitTextStrokeWidth: "0.5px",
                    }}
                    onClick={Logout}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header_admin;
