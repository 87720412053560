import React from "react";
import Header_admin from "./Header_admin";

const Layout = ({ children }) => {
  return (
    <div className="flex">
        <Header_admin/>
      <div className="flex-grow">{children}</div>
    </div>
  );
};

export default Layout;