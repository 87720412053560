import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import AdminHeader from "./AdminHeader";
import {NODE_URL} from "../Components/Config";

export default function Platformhistory() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  console.log(address, chainId, isConnected);

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);
  const [transactions, setTransactions] = useState([]);

  const FetchTransactions = async () => {
    const response = await axios.get(
      `${NODE_URL}/apiv2/getPlatformTransactions`
    );
    let res = response.data.data;
    console.log(res)
    setTransactions(res);
  };

  useEffect(() => {
    if (address !== undefined) {
      FetchTransactions();
    }
  }, [address]);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = transactions.length > 0 ? transactions.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  ) : [];
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  return (
    <div
      className="md:h-screen overflow-hidden overflow-y-auto w-[100%] font-syne bg-black text-white"
      style={{ backgroundImage: 'url("/assets/bcg-image.png")' }}
    >
      <AdminHeader />
      <div className="md:w-[85%] mx-auto bg-[#9d47d2] rounded-2xl py-5 px-10 mt-10 2xl:mt-28">
        <div className="flex justify-between">
          <div>
            <h1 className="text-lg font-bold">Platform Transactions</h1>
          </div>
          <div className="flex items-center">
            <p className="text-end text-sm px-3 mb-2">
              Show no of entity
              <select
                className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                onChange={handleDropdownChange}
                value={entitiesPerPage}
              >
                <option className="text-black" value="5">
                  5
                </option>
                <option className="text-black" value="7">
                  7
                </option>
                <option className="text-black" value="10">
                  10
                </option>
              </select>
            </p>
          </div>
        </div>
        <div className="pb-5 rounded-xl overflow-hidden overflow-x-auto">
          <table className="w-full text-center">
            <thead>
              <tr>
                <th className="font-bold py-2 rounded-tl-xl px-3 ">Transaction Hash</th>
                <th className="font-bold px-5">Token used</th>
                <th className="font-bold px-5">Price</th>
                <th className="font-bold py-2 rounded-tr-xl">Coins</th>
              </tr>
            </thead>
            <tbody>
              {currentEntities.map((transaction, index) => (
                <tr
                  key={index}
                  className="h-14 text-sm md:text-base text-center rounded-md"
                >
                  <td className="font-bold px-5 md:px-0 ">{transaction.TokenHash}</td>
                  <td className="font-bold px-5 md:px-5 ">{transaction.Token}</td>
                  <td className="font-bold px-5 md:px-5 ">{transaction.TokenAmount}</td>
                  <td className="font-bold px-5 md:px-0 ">
                    {Number(transaction.CoinAmount).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-2 flex justify-between text-xs px-3">
          <button
            className="bg-white/30 rounded-md px-5 py-1 font-bold"
            onClick={handlePrevClick}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <button
            className="bg-white/30 rounded-md px-5 py-1 font-bold"
            onClick={handleNextClick}
            disabled={currentEntities.length < entitiesPerPage}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}