// import React from "react";
// import Header from "./Header";
// import { useState } from "react";

// const Bridge = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedOption, setSelectedOption] = useState("Choose Network");

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleOptionClick = (option) => {
//     setSelectedOption(option);
//     setIsOpen(false);
//   };

//   const [isOpen1, setIsOpen1] = useState(false);
//   const [selectedOption1, setSelectedOption1] = useState("Choose Network");

//   const toggleDropdown1 = () => {
//     setIsOpen1(!isOpen);
//   };

//   const handleOptionClick1 = (option) => {
//     setSelectedOption1(option);
//     setIsOpen1(false);
//   };

//   const [isOpen2, setIsOpen2] = useState(false);
//   const [selectedOption2, setSelectedOption2] = useState("Choose Network");

//   const toggleDropdown2 = () => {
//     setIsOpen2(!isOpen);
//   };

//   const handleOptionClick2 = (option) => {
//     setSelectedOption2(option);
//     setIsOpen2(false);
//   };
//   return (
//     <div className="font-playfair bg-[#5C27FE] h-screen overflow-y-auto">
//       <Header />
//       <div className="text-black  w-[100%]">
//         <div className="relative w-[100%]">
//           <div className="md:w-[100%] pb-10 z-10 relative">
//             <div className="w-[95%] md:w-[80%]  mx-auto sec-1 py-10">
//               <h1 className="text-4xl text-center font-bold text-white">
//                 Bridging
//               </h1>
//               <div className="bg-white/30 md:p-10 p-5 rounded-xl mt-10 shadow-md shadow-white/60  ">
//                 <p className="text-2xl font-bold text-center ">Current Token</p>

//                 <div className="mt-10 w-[90%] mx-auto ">
//                   <div className="flex md:flex-row flex-col justify-between">
//                     <div className="w-full">
//                       <label className="font-semibold text-lg">
//                         <p>Choose from Network</p>
//                       </label>
//                       <div className="">
//                         <div>
//                           <button
//                             type="button"
//                             className="md:w-[80%] w-full flex justify-between focus:outline-none mt-5 bg-white/50 border border-black p-4 rounded-lg"
//                             onClick={toggleDropdown}
//                           >
//                             {selectedOption}
//                             <svg
//                               className="-mr-1 ml-2 h-5 w-5"
//                               xmlns="http://www.w3.org/2000/svg"
//                               viewBox="0 0 20 20"
//                               fill="currentColor"
//                               aria-hidden="true"
//                             >
//                               <path
//                                 fillRule="evenodd"
//                                 d="M5.23 7.21a.75.75 0 011.06.02L10 10.98l3.71-3.75a.75.75 0 111.08 1.04l-4.25 4.25a.75.75 0 01-1.06 0L5.21 8.27a.75.75 0 01.02-1.06z"
//                                 clipRule="evenodd"
//                               />
//                             </svg>
//                           </button>
//                         </div>

//                         {isOpen && (
//                           <div className="md:w-[80%] w-full bg-[#975eb7] mt-1 rounded-md shadow-lg">
//                             <div className="py-1">
//                               <a
//                                 href="#"
//                                 className="block px-4 font-bold py-2 text-sm text-white hover:bg-[#8944B0]"
//                                 onClick={() => handleOptionClick("Ethereum")}
//                               >
//                                 Ethereum
//                               </a>
//                               <a
//                                 href="#"
//                                 className="block px-4 py-2 font-bold text-sm text-white hover:bg-[#8944B0]"
//                                 onClick={() => handleOptionClick("Polygon")}
//                               >
//                                 Polygon
//                               </a>
//                             </div>
//                           </div>
//                         )}
//                       </div>
//                     </div>

//                     <div className="w-full mt-10 md:mt-0">
//                       <label className="font-semibold text-lg">
//                         <p>Enter quantity</p>
//                       </label>
//                       <input
//                         type="text"
//                         className="md:w-[80%] w-full focus:outline-none mt-5 bg-white/50 border border-black p-4 rounded-lg"
//                         required
//                       />
//                     </div>
//                   </div>

//                   <div className="flex md:flex-row flex-col justify-between items-center mt-10">
//                     <div className="w-full">
//                       <label className="font-semibold text-lg">
//                         <p>Choose to Network</p>
//                       </label>
//                       <div className="w-[100%]">
//                         <div className="">
//                           <div className="">
//                             <button
//                               type="button"
//                               className="md:w-[80%] w-full flex justify-between focus:outline-none mt-5 bg-white/50 border border-black p-4 rounded-lg"
//                               onClick={toggleDropdown1}
//                             >
//                               {selectedOption1}
//                               <svg
//                                 className="-mr-1 ml-2 h-5 w-5"
//                                 xmlns="http://www.w3.org/2000/svg"
//                                 viewBox="0 0 20 20"
//                                 fill="currentColor"
//                                 aria-hidden="true"
//                               >
//                                 <path
//                                   fillRule="evenodd"
//                                   d="M5.23 7.21a.75.75 0 011.06.02L10 10.98l3.71-3.75a.75.75 0 111.08 1.04l-4.25 4.25a.75.75 0 01-1.06 0L5.21 8.27a.75.75 0 01.02-1.06z"
//                                   clipRule="evenodd"
//                                 />
//                               </svg>
//                             </button>
//                           </div>

//                           {isOpen1 && (
//                             <div className="bg-[#975eb7] mt-1 rounded-md font-bold shadow-lg w-[80%]">
//                               <div className="py-1">
//                                 <a
//                                   href="#"
//                                   className="block px-4 py-2 text-sm font-bold text-white hover:bg-[#8944B0]"
//                                   onClick={() => handleOptionClick1("USDT")}
//                                 >
//                                   USDT
//                                 </a>
//                                 <a
//                                   href="#"
//                                   className="block px-4 py-2 text-sm font-bold text-white hover:bg-[#8944B0]"
//                                   onClick={() => handleOptionClick1("MATIC")}
//                                 >
//                                   MATIC
//                                 </a>
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                       </div>
//                     </div>

//                     <div className="w-full mt-5 md:mt-3">
//                       <p className="font-semibold">Available : 124 Tokens</p>
//                       <button className="mt-5 font-bold border border-black bg-white px-10 py-2 rounded-2xl shadow-md shadow-black/30">
//                         Confirm
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="background shading bg-[#C156DD]/60 blur-3xl p-5 absolute top-0 left-0 right-0 w-full h-[90%] z-0 "></div>
//         </div>
//       </div>
//       <div className="w-full modal-overlay fixed inset-0 absolute top-[90px] z-10 bg-black bg-opacity-30 backdrop-blur-xl ">
//         <div className="flex justify-center pt-32">
//           <img className="lg:w-4/12" src="/assets/coming-soon.png"></img>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Bridge;

import React, { useEffect, useState, useContext } from "react";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { WalletContext } from "./WalletContext";
import { ethers, Wallet } from "ethers";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import {
  Network,
  NODE_URL,
  RPC_URL,
  Contract_details,
  STAKE_CONTRACT,
  TOKEN_CONTRACT,
  BASE_API_KEY,
  Bridge_Contracts,
  MORALIS_API,
} from "./Config";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import Moralis from "moralis";
import { BigNumber } from "bignumber.js";

const Bridge = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedFrom, setSelectedFrom] = useState("Choose Network");
  const [selectedTo, setSelectedTo] = useState("Choose Network");
  const [Amount, setAmount] = useState(0);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const toggleDropdown1 = () => setIsOpen1(!isOpen1);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const { walletAddress, walletAddressType } = useContext(WalletContext);
  const [tokenBalance, settokenBalance] = useState(0);

  const [FromBridge, setFromBridge] = useState(null);
  const [ToBridge, setToBridge] = useState(null);
  const [FromBridgeABI, setFromBridgeABI] = useState(null);
  const [ToBridgeABI, setToBridgeABI] = useState(null);

  const [FromToken, setFromToken] = useState(null);
  const [ToToken, setToToken] = useState(null);
  const [FromTokenABI, setFromTokenABI] = useState(null);
  const [ToTokenABI, setToTokenABI] = useState(null);
  // let networks;
  // if (Network === "MAINNET") {
  //   networks = [
  //     {
  //       name: "ETHEREUM",
  //       symbol: "ERC-20",
  //       img: "https://etherscan.io/images/svg/brands/ethereum-original.svg",
  //       chainId: 1,
  //     },
  //     {
  //       name: "POLYGON",
  //       symbol: "MATIC",
  //       img: "https://polygon.technology/_nuxt/img/polygon-logo.2d6c08e.svg",
  //       chainId: 137,
  //     },
  //     {
  //       name: "BASE",
  //       symbol: "BASE",
  //       img: "https://base.org/images/base.svg",
  //       chainId: 8453,
  //     },
  //     {
  //       name: "BSC",
  //       symbol: "BEP-20",
  //       img: "https://bscscan.com/images/svg/brands/bnb.svg",
  //       chainId: 56,
  //     },
  //     {
  //       name: "AVALANCHE",
  //       symbol: "AVAX",
  //       img: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png",
  //       chainId: 43114,
  //     },
  //   ];
  // } else {
  //   networks = [
  //     {
  //       name: "ETHEREUM (Sepolia Testnet)",
  //       symbol: "ETH",
  //       img: "https://etherscan.io/images/svg/brands/ethereum-original.svg",
  //       chainId: 11155111,
  //     },
  //     {
  //       name: "POLYGON (Amoy Testnet)",
  //       symbol: "MATIC",
  //       img: "https://polygon.technology/_nuxt/img/polygon-logo.2d6c08e.svg",
  //       chainId: 1442,
  //     },
  //     {
  //       name: "BASE (Sepolia Testnet)",
  //       symbol: "ETH",
  //       img: "https://base.org/images/base.svg",
  //       chainId: 84531,
  //     },
  //     {
  //       name: "BSC (Testnet)",
  //       symbol: "BNB",
  //       img: "https://bscscan.com/images/svg/brands/bnb.svg",
  //       chainId: 97,
  //     },
  //     {
  //       name: "AVALANCHE (Fuji Testnet)",
  //       symbol: "AVAX",
  //       img: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png",
  //       chainId: 43113,
  //     },
  //   ];
  // }

  const networks = [
    {
      name: "ETHEREUM (Sepolia Testnet)",
      symbol: "ETH",
      img: "https://etherscan.io/images/svg/brands/ethereum-original.svg",
      chainId: 11155111,
    },
    {
      name: "POLYGON (Amoy Testnet)",
      symbol: "MATIC",
      img: "https://polygon.technology/_nuxt/img/polygon-logo.2d6c08e.svg",
      chainId: 80002,
    },
    {
      name: "BASE (Sepolia Testnet)",
      symbol: "ETH",
      img: "https://base.org/images/base.svg",
      chainId: 84532,
    },
    {
      name: "BSC (Testnet)",
      symbol: "BNB",
      img: "https://bscscan.com/images/svg/brands/bnb.svg",
      chainId: 97,
    },
    {
      name: "AVALANCHE (Fuji Testnet)",
      symbol: "AVAX",
      img: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png",
      chainId: 43113,
    },
  ];

  useEffect(() => {
    if (walletAddressType !== null && walletAddressType !== "EVM") {
      toast.error("Please connect EVM wallet");
    }
  }, [walletAddressType, walletAddress]);

  const handleFromClick = async (option) => {
    setSelectedFrom(option);

    if (selectedTo === option.name) {
      setSelectedTo("Choose Network");
    }

    try {
      const NetworkOption = networks.find(
        (list) => list.chainId == option.chainId
      );
      // console.log(NetworkOption, option, chainId);
      let networkDetails;

      if (Network == "TESTNET") {
        networkDetails = {
          chain: option.chainId,
          chainName:
            option.chainId == 80002
              ? Contract_details.POLYGON_NAME
              : option.chainId == 11155111
              ? Contract_details.ETH_NAME
              : option.chainId == 97
              ? Contract_details.BSC_NAME
              : option.chainId == 43113
              ? Contract_details.AVAX_NAME
              : Contract_details.BASE_NAME,
          symbol:
            option.chainId == 80002
              ? "MATIC"
              : option.chainId == 11155111
              ? "ETH"
              : option.chainId == 97
              ? "tBNB"
              : option.chainId == 43113
              ? "AVAX"
              : "BASE",
          RPC:
            option.chainId == 80002
              ? Contract_details.POLYGON_RPC
              : option.chainId == 11155111
              ? Contract_details.ETH_RPC
              : option.chainId == 97
              ? Contract_details.BSC_RPC
              : option.chainId == 43113
              ? Contract_details.AVAX_RPC
              : Contract_details.BASE_RPC,
          Exp:
            option.chainId == 80002
              ? Contract_details.POLYGON_EXPLORER
              : option.chainId == 11155111
              ? Contract_details.ETH_EXPLORER
              : option.chainId == 97
              ? Contract_details.BSC_EXPLORER
              : option.chainId == 43113
              ? Contract_details.AVAX_EXPLORER
              : Contract_details.BASE_EXPLORER,
        };
      } else {
        networkDetails = {
          chain: option.chainId,
          chainName:
            option.chainId == 137
              ? Contract_details.POLYGON_NAME
              : option.chainId == 1
              ? Contract_details.ETH_NAME
              : option.chainId == 56
              ? Contract_details.BSC_NAME
              : option.chainId == 43114
              ? Contract_details.AVAX_NAME
              : Contract_details.BASE_NAME,
          symbol:
            option.chainId == 137
              ? "MATIC"
              : option.chainId == 1
              ? "ETH"
              : option.chainId == 56
              ? "BNB"
              : option.chainId == 43114
              ? "AVAX"
              : "BASE",
          RPC:
            option.chainId == 137
              ? Contract_details.POLYGON_RPC
              : option.chainId == 1
              ? Contract_details.ETH_RPC
              : option.chainId == 56
              ? Contract_details.BSC_RPC
              : option.chainId == 43114
              ? Contract_details.AVAX_RPC
              : Contract_details.BASE_RPC,
          Exp:
            option.chainId == 137
              ? Contract_details.POLYGON_EXPLORER
              : option.chainId == 1
              ? Contract_details.ETH_EXPLORER
              : option.chainId == 56
              ? Contract_details.BSC_EXPLORER
              : option.chainId == 43114
              ? Contract_details.AVAX_EXPLORER
              : Contract_details.BASE_EXPLORER,
        };
      }
      if (NetworkOption) {
        if (chainId === parseInt(NetworkOption.chainId)) {
          setIsOpen(false);
          return;
        } else {
          try {
            await walletProvider.request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: ethers.utils.hexValue(
                    parseInt(NetworkOption.chainId)
                  ),
                },
              ],
            });
            toast.success("Network changed successfully");
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await walletProvider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: ethers.utils.hexValue(networkDetails.chain),
                      chainName: networkDetails.chainName,
                      nativeCurrency: {
                        name: networkDetails.chainName,
                        symbol: networkDetails.symbol,
                        decimals: 18,
                      },
                      rpcUrls: [networkDetails.RPC],
                      blockExplorerUrls: [networkDetails.Exp],
                    },
                  ],
                });
                toast.success("Network added and switched successfully");
              } catch (addError) {
                toast.error("Failed to add the network");
                console.error("Error adding the network:", addError);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            } else {
              toast.error("Failed to switch the network");
              console.error("Error switching the network:", switchError);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          }
        }
      } else {
        toast.error("Please select a valid network");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      setTimeout(() => window.location.reload(), 1000);
    }

    setIsOpen(false);
  };

  const handleToClick = (option) => {
    setSelectedTo(option);
    if (selectedFrom === option.name) setSelectedFrom("Choose Network");
    setIsOpen1(false);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const clearFromSelection = (e) => {
    e.stopPropagation();
    setSelectedFrom("Choose Network");
  };

  const clearToSelection = (e) => {
    e.stopPropagation();
    setSelectedTo("Choose Network");
  };

  const getTokenBalance = async () => {
    try {
      console.log("coming", walletAddress, FromToken);
      if (walletAddress !== null) {
        const bal = await axios.get(
          `https://api-sepolia.basescan.org/api?module=account&action=tokenbalance&contractaddress=${FromToken}&address=${walletAddress}&tag=latest&apikey=${BASE_API_KEY}`
        );
        console.log(bal.data);
        if (bal.data.status == 1) {
          settokenBalance(bal.data.result / 10 ** 18);
        }
      } else {
        settokenBalance(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBalance = async (userAddress, tokenAddress, chain) => {
    try {
      console.log(userAddress, tokenAddress, chain);

      if (!Moralis.Core.isStarted) {
        await Moralis.start({
          apiKey: MORALIS_API,
        });
      }

      const response = await Moralis.EvmApi.token.getWalletTokenBalances({
        chain: chain,
        tokenAddresses: [tokenAddress],
        address: userAddress,
      });

      let tokenAmount = response.raw[0].balance;
      let decimal = response.raw[0].decimals;
      console.log(tokenAmount / 10 ** decimal);
      settokenBalance((tokenAmount / 10 ** decimal).toFixed());
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    console.log(walletAddress, FromToken, selectedFrom);
    if (walletAddress !== null && FromToken !== null) {
      // getTokenBalance();
      getBalance(walletAddress, FromToken, selectedFrom.chainId);
    }
  }, [walletAddress, FromToken, selectedFrom]);

  useEffect(() => {
    console.log(selectedFrom);
    if (selectedFrom !== "Choose Network") {
      switch (selectedFrom.chainId) {
        case Contract_details.POLYGON_CHAIN_ID:
          setFromBridge(Bridge_Contracts.POLYGON_CONTRACT);
          setFromTokenABI(Bridge_Contracts.POLYGON_ABI);
          setFromToken(Bridge_Contracts.POLYGON_TOKEN);
          setFromBridgeABI(Bridge_Contracts.POLYGON_BRIDGE_ABI);
          break;
        case Contract_details.ETH_CHAIN_ID:
          setFromBridge(Bridge_Contracts.ETHEREUM_CONTRACT);
          setFromTokenABI(Bridge_Contracts.ETHEREUM_ABI);
          setFromToken(Bridge_Contracts.ETHEREUM_TOKEN);
          setFromBridgeABI(Bridge_Contracts.ETHEREUM_BRIDGE_ABI);
          break;
        case Contract_details.BSC_CHAIN_ID:
          setFromBridge(Bridge_Contracts.BSC_CONTRACT);
          setFromTokenABI(Bridge_Contracts.BSC_ABI);
          setFromToken(Bridge_Contracts.BSC_TOKEN);
          setFromBridgeABI(Bridge_Contracts.BSC_BRIDGE_ABI);
          break;
        case Contract_details.AVAX_CHAIN_ID:
          setFromBridge(Bridge_Contracts.AVAX_CONTRACT);
          setFromTokenABI(Bridge_Contracts.AVAX_ABI);
          setFromToken(Bridge_Contracts.AVAX_TOKEN);
          setFromBridgeABI(Bridge_Contracts.AVAX_BRIDGE_ABI);
          break;
        default:
          setFromBridge(Bridge_Contracts.BASE_CONTRACT);
          setFromTokenABI(Bridge_Contracts.BASE_ABI);
          setFromToken(Bridge_Contracts.BASE_TOKEN);
          setFromBridgeABI(Bridge_Contracts.BASE_BRIDGE_ABI);
          break;
      }
    }
    console.log(selectedTo);
    if (selectedTo !== "Choose Network") {
      switch (selectedTo.chainId) {
        case Contract_details.POLYGON_CHAIN_ID:
          setToBridge(Bridge_Contracts.POLYGON_CONTRACT);
          setToTokenABI(Bridge_Contracts.POLYGON_ABI);
          setToToken(Bridge_Contracts.POLYGON_TOKEN);
          setToBridgeABI(Bridge_Contracts.POLYGON_BRIDGE_ABI);
          break;
        case Contract_details.ETH_CHAIN_ID:
          setToBridge(Bridge_Contracts.ETHEREUM_CONTRACT);
          setToTokenABI(Bridge_Contracts.ETHEREUM_ABI);
          setToToken(Bridge_Contracts.ETHEREUM_TOKEN);
          setToBridgeABI(Bridge_Contracts.ETHEREUM_BRIDGE_ABI);
          break;
        case Contract_details.BSC_CHAIN_ID:
          setToBridge(Bridge_Contracts.BSC_CONTRACT);
          setToTokenABI(Bridge_Contracts.BSC_ABI);
          setToToken(Bridge_Contracts.BSC_TOKEN);
          setToBridgeABI(Bridge_Contracts.BSC_BRIDGE_ABI);
          break;
        case Contract_details.AVAX_CHAIN_ID:
          setToBridge(Bridge_Contracts.AVAX_CONTRACT);
          setToTokenABI(Bridge_Contracts.AVAX_ABI);
          setToToken(Bridge_Contracts.AVAX_TOKEN);
          setToBridgeABI(Bridge_Contracts.AVAX_BRIDGE_ABI);
          break;
        default:
          setToBridge(Bridge_Contracts.BASE_CONTRACT);
          setToTokenABI(Bridge_Contracts.BASE_ABI);
          setToToken(Bridge_Contracts.BASE_TOKEN);
          setToBridgeABI(Bridge_Contracts.BASE_BRIDGE_ABI);
          break;
      }
    }
  }, [selectedFrom, selectedTo, chainId]);

  // const Transfer = async () => {
  //   if (selectedFrom === "Choose Network") {
  //     toast.error("Please choose the 'From' network.");
  //     return;
  //   }

  //   if (selectedTo === "Choose Network") {
  //     toast.error("Please choose the 'To' network.");
  //     return;
  //   }

  //   if (Amount <= 0) {
  //     toast.error("Please enter a valid amount greater than 0.");
  //     return;
  //   }

  //   let provider, signer;
  //   try {
  //     provider = new ethers.providers.Web3Provider(walletProvider);
  //     signer = await provider.getSigner();

  //     const ApproveAmount = ethers.utils.parseUnits(Amount.toString(), 18);

  //     const approveLoadingToast = toast.loading("Approving token...");
  //     try {
  //       const ApproveToken = new ethers.Contract(
  //         FromToken,
  //         FromTokenABI,
  //         signer
  //       );
  //       const ApproveTransaction = await ApproveToken.approve(
  //         FromBridge,
  //         ApproveAmount,
  //         {
  //           gasPrice: ethers.utils.parseUnits("100", "gwei"),
  //           gasLimit: 2000000,
  //         }
  //       );

  //       let app = await ApproveTransaction.wait();
  //       console.log(app);

  //       const approvalEvent = app.events.find(event => event.event === "Approval");
  //       if (approvalEvent) {
  //           const approvedAmountBigNumber = approvalEvent.args[2];

  //           if (approvedAmountBigNumber) {
  //               const approvedAmount = approvedAmountBigNumber.toString();
  //               console.log("Approved amount:", approvedAmount/10 ** 18);
  //           } else {
  //               console.error("Error: Approved amount BigNumber is undefined.");
  //           }
  //       } else {
  //           console.error("Approval event not found.");
  //       }

  //       toast.dismiss(approveLoadingToast);
  //       toast.success("Token approved successfully!");
  //     } catch (error) {
  //       toast.dismiss(approveLoadingToast);
  //       toast.error("Token approval failed.");
  //       console.error("Token approval failed:", error);
  //       return;
  //     }

  //     const depositLoadingToast = toast.loading("Depositing token...");
  //     let DepositTransaction;
  //     try {
  //       const DepositToken = new ethers.Contract(
  //         FromBridge,
  //         FromBridgeABI,
  //         signer
  //       );
  //       DepositTransaction = await DepositToken.deposit(ApproveAmount, {
  //         gasPrice: ethers.utils.parseUnits("100", "gwei"),
  //         gasLimit: 2000000,
  //       });

  //       let dep = await DepositTransaction.wait();
  //       console.log(dep);

  //       toast.dismiss(depositLoadingToast);
  //       toast.success("Token deposited successfully!");
  //     } catch (error) {
  //       toast.dismiss(depositLoadingToast);
  //       toast.error("Token deposit failed.");
  //       console.error("Token deposit failed:", error);
  //       return; // Stop further execution
  //     }

  //     const DepositTrxID = DepositTransaction.hash;
  //     console.log(DepositTrxID, walletAddress, ApproveAmount.toString());

  //     // Generate signature
  //     let signature;
  //     try {
  //       signature = await GenerateSign(
  //         DepositTrxID,
  //         walletAddress,
  //         ApproveAmount.toString()
  //       );
  //     } catch (error) {
  //       toast.error("Signature generation failed.");
  //       console.error("Signature generation failed:", error);
  //       return; // Stop further execution
  //     }

  //     // Switch network
  //     const switchLoadingToast = toast.loading("Switching network...");
  //     try {
  //       const switchResult = await SwitchNetwork(selectedTo.chainId);
  //       if (switchResult === 0) {
  //         throw new Error("Network switch failed.");
  //       }
  //       toast.dismiss(switchLoadingToast);
  //       toast.success("Network switched successfully!");
  //     } catch (error) {
  //       toast.dismiss(switchLoadingToast);
  //       toast.error("Network switch failed.");
  //       console.error("Network switch failed:", error);
  //       return; // Stop further execution
  //     }

  //     // Update provider and signer after network switch
  //     provider = new ethers.providers.Web3Provider(walletProvider);
  //     signer = await provider.getSigner();

  //     // Withdraw the token
  //     const withdrawLoadingToast = toast.loading("Withdrawing token...");
  //     try {
  //       const WithdrawToken = new ethers.Contract(
  //         ToBridge,
  //         ToBridgeABI,
  //         signer
  //       );
  //       const WithdrawTransaction = await WithdrawToken.withdraw(
  //         DepositTrxID,
  //         ApproveAmount,
  //         signature,
  //         {
  //           gasPrice: ethers.utils.parseUnits("100", "gwei"),
  //           gasLimit: 2000000,
  //         }
  //       );

  //       let withdraw = await WithdrawTransaction.wait();
  //       console.log(withdraw);

  //       toast.dismiss(withdrawLoadingToast);
  //       toast.success("Token withdrawn successfully!");
  //       console.log(WithdrawTransaction);
  //     } catch (error) {
  //       toast.dismiss(withdrawLoadingToast);
  //       toast.error("Token withdrawal failed.");
  //       console.error("Token withdrawal failed:", error);
  //     }
  //   } catch (error) {
  //     console.error("Transfer failed:", error);
  //     toast.error("Transfer failed. Check console for details.");
  //   }
  // };

  const Transfer = async () => {
    if (selectedFrom === "Choose Network") {
      toast.error("Please choose the 'From' network.");
      return;
    }

    if (selectedTo === "Choose Network") {
      toast.error("Please choose the 'To' network.");
      return;
    }

    if (Amount <= 0) {
      toast.error("Please enter a valid amount greater than 0.");
      return;
    }

    let provider, signer;
    try {
      provider = new ethers.providers.Web3Provider(walletProvider);
      signer = await provider.getSigner();

      const ApproveAmount = ethers.utils.parseUnits(Amount.toString(), 18);

      const approveLoadingToast = toast.loading("Approving token...");
      let DepositTrxID = "";
      let WithdrawTrxID = "";
      let depositStatus = "";
      let withdrawStatus = "";

      try {
        const ApproveToken = new ethers.Contract(
          FromToken,
          FromTokenABI,
          signer
        );
        const ApproveTransaction = await ApproveToken.approve(
          FromBridge,
          ApproveAmount,
          {
            gasPrice: ethers.utils.parseUnits("100", "gwei"),
            gasLimit: 2000000,
          }
        );

        let app = await ApproveTransaction.wait();
        console.log(app);

        const approvalEvent = app.events.find(
          (event) => event.event === "Approval"
        );
        if (approvalEvent) {
          const approvedAmountBigNumber = approvalEvent.args[2];
          if (approvedAmountBigNumber) {
            const approvedAmount = approvedAmountBigNumber.toString();
            console.log("Approved amount:", approvedAmount / 10 ** 18);
          } else {
            console.error("Error: Approved amount BigNumber is undefined.");
          }
        } else {
          console.error("Approval event not found.");
        }

        toast.dismiss(approveLoadingToast);
        toast.success("Token approved successfully!");
      } catch (error) {
        toast.dismiss(approveLoadingToast);
        toast.error("Token approval failed.");
        console.error("Token approval failed:", error);
        return;
      }

      const depositLoadingToast = toast.loading("Depositing token...");
      let DepositTransaction;
      try {
        const DepositToken = new ethers.Contract(
          FromBridge,
          FromBridgeABI,
          signer
        );
        DepositTransaction = await DepositToken.deposit(ApproveAmount, {
          gasPrice: ethers.utils.parseUnits("100", "gwei"),
          gasLimit: 2000000,
        });

        let dep = await DepositTransaction.wait();
        DepositTrxID = DepositTransaction.hash;
        depositStatus = "Success";
        console.log(dep);

        toast.dismiss(depositLoadingToast);
        toast.success("Token deposited successfully!");
      } catch (error) {
        depositStatus = "Failed";
        toast.dismiss(depositLoadingToast);
        toast.error("Token deposit failed.");
        console.error("Token deposit failed:", error);
        return;
      }

      let signature;
      try {
        signature = await GenerateSign(
          DepositTrxID,
          walletAddress,
          ApproveAmount.toString()
        );
      } catch (error) {
        toast.error("Signature generation failed.");
        console.error("Signature generation failed:", error);
        return;
      }

      const switchLoadingToast = toast.loading("Switching network...");
      try {
        const switchResult = await SwitchNetwork(selectedTo.chainId);
        if (switchResult === 0) {
          throw new Error("Network switch failed.");
        }
        toast.dismiss(switchLoadingToast);
        // toast.success("Network switched successfully!");
      } catch (error) {
        toast.dismiss(switchLoadingToast);
        // toast.error("Network switch failed.");
        console.error("Network switch failed:", error);
        return;
      }

      provider = new ethers.providers.Web3Provider(walletProvider);
      signer = await provider.getSigner();

      const withdrawLoadingToast = toast.loading("Withdrawing token...");
      try {
        const WithdrawToken = new ethers.Contract(
          ToBridge,
          ToBridgeABI,
          signer
        );
        const WithdrawTransaction = await WithdrawToken.withdraw(
          DepositTrxID,
          ApproveAmount,
          signature,
          {
            gasPrice: ethers.utils.parseUnits("100", "gwei"),
            gasLimit: 2000000,
          }
        );

        let withdraw = await WithdrawTransaction.wait();
        WithdrawTrxID = WithdrawTransaction.hash;
        withdrawStatus = "Success";
        console.log(withdraw);

        toast.dismiss(withdrawLoadingToast);
        toast.success("Token withdrawn successfully!");
        console.log(WithdrawTransaction);
      } catch (error) {
        withdrawStatus = "Failed";
        toast.dismiss(withdrawLoadingToast);
        toast.error("Token withdrawal failed.");
        console.error("Token withdrawal failed:", error);
      }

      try {
        const response = await axios.post(`${NODE_URL}/apiv2/bridge`, {
          fromNetwork: selectedFrom.name,
          toNetwork: selectedTo.name,
          walletAddress: walletAddress,
          amount: Amount,
          depositTrxHash: DepositTrxID,
          depositStatus: depositStatus,
          withdrawTrxHash: WithdrawTrxID,
          withdrawStatus: withdrawStatus,
        });

        console.log("Transfer data saved:", response);
        if (response.status === 200) {
          toast.success("Stored Successfully");
        }
      } catch (error) {
        console.error("Failed to send transfer data to backend:", error);
        toast.error("Failed to send transfer data to server.");
      }
    } catch (error) {
      console.error("Transfer failed:", error);
      toast.error("Transfer failed. Check console for details.");
    }
  };

  console.log(process.env.BRIDGE_ADMIN_PRIVATE_KEY);

  const GenerateSign = async (DepositTrxID, walletAddress, ApproveAmount) => {
    try {
      console.log(DepositTrxID, walletAddress, ApproveAmount);
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const ADMIN_KEY = process.env.BRIDGE_ADMIN_PRIVATE_KEY;
      console.log(process.env.BRIDGE_ADMIN_PRIVATE_KEY);
      const sign_wallet = new ethers.Wallet(
        "98f37d66281fa986909a20870074dd70d525ef403aa311f72ad2b8641059d2da",
        provider
      );
      const SignerHash = ethers.utils.solidityKeccak256(
        ["bytes32", "address", "uint256"],
        [DepositTrxID, walletAddress, ApproveAmount]
      );
      const hashData = ethers.utils.arrayify(SignerHash);
      let sign = await sign_wallet.signMessage(hashData);
      sign = ethers.utils.splitSignature(sign);
      return sign;
    } catch (error) {
      console.error(error);
    }
  };

  const SwitchNetwork = async (NetworkId) => {
    try {
      await walletProvider.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: ethers.utils.hexValue(parseInt(NetworkId)),
          },
        ],
      });
      toast.success("Network changed successfully");
      return;
    } catch (error) {
      console.log("Error While Switching network : ", error);
      toast.success("Error While Switching networ");
      return;
    }
  };

  return (
    <div className="font-playfair bg-[#5C27FE] h-screen overflow-y-auto">
      <Header />
      <Toaster toastOptions={{ position: "top-center" }} />
      <div className="text-black w-[100%]">
        <div className="relative w-[100%]">
          <div className="md:w-[100%] pb-10 z-10 relative">
            <div className="w-[95%] md:w-[80%] mx-auto sec-1 ">
              <h1 className="text-4xl text-center font-bold text-white">
                Bridging
              </h1>
              <div className="bg-white/30 md:p-10 p-5 rounded-xl mt-10 shadow-md shadow-white/60">
                <p className="text-2xl font-bold text-center">Current Token</p>

                <div className="mt-10 w-[90%] mx-auto">
                  <div className="flex md:flex-row flex-col justify-between">
                    <div className="w-full">
                      <label className="font-semibold text-lg">
                        <p>Choose from Network</p>
                      </label>
                      <div className="relative">
                        <button
                          type="button"
                          className="md:w-[80%] w-full flex justify-between focus:outline-none mt-5 bg-white/50 border border-black p-4 rounded-lg relative"
                          onClick={toggleDropdown}
                        >
                          {selectedFrom.name ?? "Choose Network"}
                          {selectedFrom !== "Choose Network" && (
                            <button
                              onClick={clearFromSelection}
                              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-600 hover:text-gray-800"
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </button>
                          )}
                          <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 10.98l3.71-3.75a.75.75 0 111.08 1.04l-4.25 4.25a.75.75 0 01-1.06 0L5.21 8.27a.75.75 0 01.02-1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>

                        {isOpen && (
                          <div className="absolute top-full left-0 mt-1 bg-[#975eb7] rounded-md shadow-lg z-10 w-[80%] max-h-60 overflow-y-auto">
                            <div className="py-1">
                              {networks
                                .filter(
                                  (network) => network.name !== selectedTo.name
                                )
                                .map((network) => (
                                  <div
                                    key={network.chainId}
                                    className="block px-4 font-bold py-2 text-sm text-white hover:bg-[#8944B0]"
                                    onClick={() => handleFromClick(network)}
                                  >
                                    {network.name}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="w-full mt-10 md:mt-0">
                      <label className="font-semibold text-lg">
                        <p>Enter quantity</p>
                      </label>
                      <input
                        type="text"
                        className="md:w-[80%] w-full focus:outline-none mt-5 bg-white/50 border border-black p-4 rounded-lg"
                        value={Amount}
                        onChange={handleAmountChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col justify-between items-center mt-10">
                    <div className="w-full">
                      <label className="font-semibold text-lg">
                        <p>Choose to Network</p>
                      </label>
                      <div className="relative">
                        <button
                          type="button"
                          className="md:w-[80%] w-full flex justify-between focus:outline-none mt-5 bg-white/50 border border-black p-4 rounded-lg relative"
                          onClick={toggleDropdown1}
                        >
                          {selectedTo.name ?? "Choose Network"}
                          {selectedTo !== "Choose Network" && (
                            <button
                              onClick={clearToSelection}
                              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-600 hover:text-gray-800"
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </button>
                          )}
                          <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 10.98l3.71-3.75a.75.75 0 111.08 1.04l-4.25 4.25a.75.75 0 01-1.06 0L5.21 8.27a.75.75 0 01.02-1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>

                        {isOpen1 && (
                          <div className="absolute top-full left-0 mt-1 bg-[#975eb7] rounded-md font-bold shadow-lg w-[80%] max-h-60 overflow-y-auto z-10">
                            <div className="py-1">
                              {networks
                                .filter(
                                  (network) =>
                                    network.name !== selectedFrom.name
                                )
                                .map((network) => (
                                  <div
                                    key={network.chainId}
                                    className="block px-4 py-2 text-sm font-bold text-white hover:bg-[#8944B0]"
                                    onClick={() => handleToClick(network)}
                                  >
                                    {network.name}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="w-full mt-5 md:mt-3">
                      <p className="font-semibold">
                        Available : {tokenBalance} Tokens
                      </p>
                      <button
                        className="mt-5 font-bold border border-black bg-white px-10 py-2 rounded-2xl shadow-md shadow-black/30"
                        onClick={Transfer}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="background shading bg-[#C156DD]/60 blur-3xl p-5 absolute top-0 left-0 right-0 w-full h-[90%] z-0"></div>
        </div>
      </div>
      <div className="w-full modal-overlay fixed inset-0 absolute top-[90px] z-10 bg-black bg-opacity-30 backdrop-blur-xl ">
        <div className="flex justify-center pt-32">
          <img className="lg:w-4/12" src="/assets/coming-soon.png"></img>
        </div>
      </div>
    </div>
  );
};

export default Bridge;
