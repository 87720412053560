import React, { createContext, useState, useEffect } from 'react';

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [walletAddressType, setWalletAddressType] = useState(null);

  useEffect(() => {
    const updateWalletDetails = () => {
      const storedAddress = localStorage.getItem("walletAddress");
      const storedAddressType = localStorage.getItem("walletAddressType");
      setWalletAddress(storedAddress);
      setWalletAddressType(storedAddressType);
    };
    updateWalletDetails();

    const intervalId = setInterval(updateWalletDetails, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <WalletContext.Provider value={{ walletAddress, walletAddressType, setWalletAddress, setWalletAddressType }}>
      {children}
    </WalletContext.Provider>
  );
};
