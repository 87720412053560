import React, { useState } from "react";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";
const Faq = () => {
  const faq = [
    {
      question: " What is RealMEME Coin (RMC)?",
      answer:
        "RealMEME Coin (RMC) is a unique cryptocurrency that blends the excitement of meme culture with the tangible value of real-world assets. By holding RMC, you gain access to exclusive opportunities, luxury experiences, and a community-driven ecosystem.",
    },
    {
      question: " Who is GoldPaw?",
      answer:
        "GoldPaw is the charismatic mascot of RealMEME Coin, symbolizing wealth, luxury, and the spirit of the RMC community. With his stylish glasses and confident demeanor, GoldPaw leads the way in the world of memes and real-world assets.",
    },
    {
      question: "How can I purchase RMC tokens?",
      answer:
        "RMC tokens can be purchased during the presale stages at discounted rates or later on public cryptocurrency exchanges. Stay tuned for announcements on our website and social media channels for the latest information on purchasing RMC.",
    },
    {
      question: "What are the benefits of holding RMC tokens?",
      answer:
        "Holding RMC tokens grants you exclusive access to a range of benefits, including staking rewards, discounts on luxury experiences, access to exclusive events, and the opportunity to invest in tokenized real-world assets. You also become a member of CryptoNation, our exclusive virtual community.",
    },
    {
      question: "How does Crypto Island work for RMC holders?",
      answer:
        "Crypto Island is a private, luxurious getaway exclusively for RMC holders. It offers access to luxury villas, yachts, jets, and special events at a fraction of the usual cost. By holding RMC, you can enjoy these experiences by paying only for basic utilities and operational costs.",
    },
    {
      question:
        "What kind of real-world assets will RMC holder have access to?",
      answer:
        "Real-world assets are physical assets like luxury villas, penhouses, yachts, cars and if we all put enough efforts – Crypto Island – base for Independent Crypto Nation.",
    },
    {
      question: "How is the RMC community involved in decision-making?",
      answer:
        "RMC holders have voting rights in the governance of CryptoNation and other aspects of the RealMEME ecosystem. Through a decentralized governance model, holders can vote on key decisions, including new features, development projects, and community initiatives.",
    },
    {
      question: "What exclusive events can RMC holders access?",
      answer:
        "RMC holders gain access to a variety of exclusive events, including VIP parties, luxury retreats, and high-profile conferences. These events are designed to offer networking opportunities, entertainment, and a taste of the high life that comes with being part of the RMC community.",
    },
    {
      question: "How can I stay updated on RMC developments?",
      answer:
        "Stay connected with us through our official website, social media channels, and community forums. We regularly post updates, news, and important announcements about RMC and the RealMEME ecosystem.",
    },
    {
      question: "What is the future of RealMEME Coin?",
      answer:
        "The future of RealMEME Coin is bright and ambitious. We plan to expand the ecosystem with new features, partnerships, and opportunities for our community. Our goal is to continue blending the digital world with real-world assets, creating a unique and valuable experience for all RMC holders.",
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div>
      <div className="bg-[#37159F] bg-cover font-poppins text-white h-screen overflow-hidden overflow-y-auto relative">
      <Header />
        <div
          className="bg-no-repeat bg-cover min-h-screen py-14"
          style={{ backgroundImage: "url(../assets/Ellipse_73.png)" }}
        >
          
          <div className="flex justify-center items-center gap-5">
            <img src="../assets/image_179.png" className="w-24" />
            <p className="font-bold text-4xl mt-10">FAQs</p>
          </div>

          <div className="md:w-[75%] w-[90%] mx-auto">
            {faq.map((data, index) => (
              <div
                key={index}
                onClick={() => toggleExpand(index)}
                className={`bg-[#0F10128C] duration-500 p-5 transform transition ease-in-out mb-2 ${
                  expandedIndex === index ? "h-full" : "h-full"
                }`}
              >
                <div className="flex ">
                  <div className="w-[90%] flex gap-2 items-center">
                    <img src="../assets/diamondicon.png" />
                    <h1 className="font-semibold py-2">{data.question}</h1>
                  </div>
                  <div className="w-[10%] flex items-center justify-center">
                    <button className="font-bold">
                      {expandedIndex === index ? (
                        <FontAwesomeIcon
                          icon={faXmark}
                          className={`transform transition-transform duration-500`}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faPlus}
                          className={`transform transition-transform duration-500`}
                        />
                      )}
                    </button>
                  </div>
                </div>
                <div
                  className={`duration-300 ease-in-out overflow-hidden ${
                    expandedIndex === index
                      ? "pb-3 max-h-[300px] pl-5 pr-10  rounded-b-md"
                      : "max-h-0"
                  }`}
                >
                  <p className=" font-light text-[#EFEFEB] duration-500">
                    {data.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
