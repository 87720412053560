import React, { useEffect, useState } from "react";
import axios from "axios";
import AdminHeader from "./AdminHeader";

const PollResults = () => {
  const [pollResults, setPollResults] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get("http://localhost:3003/api/results") // Make sure the URL matches your server's endpoint
      .then((response) => {
        setPollResults(response.data.results);
      })
      .catch((error) => {
        setError("Error fetching poll results");
        console.error("Error fetching poll results:", error);
      });
  }, []);

  return (
    <div
      className="bg-page min-h-screen"
      style={{ backgroundImage: 'url("/assets/bcg-image.png")' }}
    >
      <AdminHeader />
      <div className="p-6">
      <h1 className="text-4xl font-bold mb-8 text-white">Poll Results</h1>
      {error && <p className="text-red-500">{error}</p>}
      <table className="min-w-full bg-white rounded-lg shadow-lg">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Poll Question</th>
            <th className="py-2 px-4 border-b">Option</th>
            <th className="py-2 px-4 border-b">Count</th>
          </tr>
        </thead>
        <tbody>
          {pollResults.map((poll, pollIndex) => (
            <React.Fragment key={pollIndex}>
              <tr className="bg-gray-200">
                <td
                  className="py-2 px-4 border-b"
                  rowSpan={poll.options.length}
                >
                  {poll.question}
                </td>
                <td className="py-2 px-4 border-b">{poll.options[0].option}</td>
                <td className="py-2 px-4 border-b">{poll.options[0].count}</td>
              </tr>
              {poll.options.slice(1).map((option, optionIndex) => (
                <tr key={optionIndex}>
                  <td className="py-2 px-4 border-b">{option.option}</td>
                  <td className="py-2 px-4 border-b">{option.count}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default PollResults;
