import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import AdminHeader from "./AdminHeader";
import {
  Contract_details,
  NODE_URL,
  RPC_URL,
  TOKEN_CONTRACT,
} from "../Components/Config";
import ABI from "../Abi.json";

const Dashboard = () => {
  const [tokenSupply, setTokenSupply] = useState(null);
  const [PreOrder, setPreOrder] = useState(null);
  const [PreOrderBalance, setPreOrderBalance] = useState(null);
  const [Purchased, setPurchased] = useState(null);
  const [PurchasedinUSD, setPurchasedinUSD] = useState(null);

  const fetchTokenSupply = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
      const tokenContract = new ethers.Contract(TOKEN_CONTRACT, ABI, provider);
      const totalSupply = await tokenContract.totalSupply();
      const formattedSupply = ethers.utils.formatUnits(totalSupply, 18);
      console.log("Total Supply:", formattedSupply);
      setTokenSupply(formattedSupply);
    } catch (err) {
      console.error(err);
      setTokenSupply(null);
    }
  };

  const fetchDetails = async () => {
    const response = await axios.get(`${NODE_URL}/apiv2/getPlatformStats`);
    console.log(response.data);
    if (response.data) {
      setPreOrder(response.data.Presale);
      setPreOrderBalance(response.data.TokenAmount);
      setPurchased(response.data.CoinAmount);
      setPurchasedinUSD(response.data.CoinAmountUSD);
    }
  };

  useEffect(() => {
    fetchTokenSupply();
    fetchDetails();
  }, []);

  return (
    <div
      className="bg-black relative bg-no-repeat bg-center bg-cover min-h-screen"
      style={{ backgroundImage: 'url("/assets/bcg-image.png")' }}
    >
      <AdminHeader />
      <div className="lg:container mx-auto lg:px-8 px-2">
        <div className="flex justify-center ">
          <div className="w-[100%]">
            <div className="flex lg:justify-center gap-10 items-center justify-center lg:flex-row sm:flex-wrap flex-col pt-5 2xl:pt-28">
              <div className="lg:w-[30%] sm:w-2/3 w-full bg-[#0a0e26] bg-opacity-70 border rounded-2xl p-3 my-3">
                <div className="flex items-end justify-end">
                  <span className="text-white font-semibold">
                    {/* <FontAwesomeIcon
                      icon={faEllipsisV}
                      size="lg"
                      style={{ color: "#bcbfc6" }}
                    /> */}
                  </span>
                </div>
                <div className="flex justify-center">
                  <div className="flex flex-col">
                    <div className="flex justify-center">
                      <div className=" bg-[#9f46e8] p-3 rounded-full w-12 h-12">
                        <img
                          src="/assets/wallet.png"
                          alt="wallet"
                          className=" object-cover w-6 h-6"
                        />
                      </div>
                    </div>
                    <p className="text-slate-300 text-center font-medium my-4 text-base">
                      Total Supply
                    </p>
                    <p className="text-white text-center font-bold text-xl">
                      {tokenSupply ?? 0.0}
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:w-[30%] sm:w-2/3 w-full bg-[#0a0e26] bg-opacity-70 border rounded-2xl p-3 my-3">
                <div className="flex items-end justify-end">
                  <span className="text-white font-semibold">
                    {/* <FontAwesomeIcon
                      icon={faEllipsisV}
                      size="lg"
                      style={{ color: "#bcbfc6" }}
                    /> */}
                  </span>
                </div>
                <div className="flex justify-center">
                  <div className="flex flex-col">
                    <div className="flex justify-center">
                      <div className=" bg-[#9f46e8] p-3 rounded-full w-12 h-12">
                        <img
                          src="/assets/walletcash.png"
                          alt="wallet"
                          className=" object-cover w-6 h-6"
                        />
                      </div>
                    </div>
                    <p className="text-slate-300 text-center font-medium my-4 text-base">
                      Presale Limit
                    </p>
                    <p className="text-white text-center font-bold text-xl">
                      {PreOrder}
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:w-[30%] sm:w-2/3 w-full bg-[#0a0e26] bg-opacity-70 border rounded-2xl p-3 my-3">
                <div className="flex items-end justify-end">
                  <span className="text-white font-semibold">
                    {/* <FontAwesomeIcon
                      icon={faEllipsisV}
                      size="lg"
                      style={{ color: "#bcbfc6" }}
                    /> */}
                  </span>
                </div>
                <div className="flex justify-center">
                  <div className="flex flex-col">
                    <div className="flex justify-center">
                      <div className=" bg-[#9f46e8] p-3 rounded-full w-12 h-12">
                        <img
                          src="/assets/dollar.png"
                          alt="dollar"
                          className="object-cover w-6 h-6"
                        />
                      </div>
                    </div>
                    <p className="text-slate-300 text-center font-medium my-4 text-base">
                      Presale Balance
                    </p>
                    <p className="text-white text-center font-bold text-xl">
                    {(PreOrder - Purchased).toFixed(2) ?? 0.0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center ">
          <div className="w-[100%]">
            <div className="flex gap-10 items-center justify-center lg:flex-row sm:flex-wrap flex-col">
              <div className="lg:w-[33%] sm:w-2/3 w-full bg-[#0a0e26] bg-opacity-70 border rounded-2xl p-3 my-3">
                <div className="flex items-end justify-end">
                  <span className="text-white font-semibold">
                    {/* <FontAwesomeIcon
                      icon={faEllipsisV}
                      size="lg"
                      style={{ color: "#bcbfc6" }}
                    /> */}
                  </span>
                </div>
                <div className="flex justify-center">
                  <div className="flex flex-col">
                    <div className="flex justify-center">
                      <div className=" bg-[#9f46e8] p-3 rounded-full w-12 h-12">
                        <img
                          src="/assets/dollar.png"
                          alt="dollar"
                          className="object-cover w-6 h-6"
                        />
                      </div>
                    </div>
                    <p className="text-slate-300 text-center font-medium my-4 text-base">
                      Purchased Quantity (USD)
                    </p>
                    <p className="text-white text-center font-bold text-xl">
                      {Number(PurchasedinUSD).toFixed(4) ?? 0.0}
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:w-[33%] sm:w-2/3 w-full bg-[#0a0e26] bg-opacity-70 border rounded-2xl p-3 my-3">
                <div className="flex items-end justify-end">
                  <span className="text-white font-semibold">
                    {/* <FontAwesomeIcon
                      icon={faEllipsisV}
                      size="lg"
                      style={{ color: "#bcbfc6" }}
                    /> */}
                  </span>
                </div>
                <div className="flex justify-center">
                  <div className="flex flex-col">
                    <div className="flex justify-center">
                      <div className=" bg-[#9f46e8] p-3 rounded-full w-12 h-12">
                        <img
                          src="/assets/walletcash.png"
                          alt="wallet"
                          className=" object-cover w-6 h-6"
                        />
                      </div>
                    </div>
                    <p className="text-slate-300 text-center font-medium my-4 text-base">
                    Purchased Asset Quantity 
                    </p>
                    <p className="text-white text-center font-bold text-xl">
                      {Purchased ?? 0.0} 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;