import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "./Layout";
import { NODE_URL, Contract_details, Network } from "../Components/Config";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

export default function UserDetails() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const walletAddress = query.get("wallet");

  useEffect(() => {
    if (walletAddress) {
      fetchTransactionDetails(walletAddress);
    }
  }, [walletAddress]);

  const fetchTransactionDetails = async (walletAddress) => {
    try {
      const response = await fetch(
        `${NODE_URL}/apiv2/getTransactionDetails?walletAddress=${walletAddress}`
      );
      const result = await response.json();
      if (result.status) {
        setTransactions(result.data);
      }
    } catch (error) {
      console.error("Error fetching transaction details: ", error);
    } finally {
      setLoading(false);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);
  const [search, setSearch] = useState("");

  const filteredEntries = transactions.filter((entry) =>
    entry.TokenHash.includes(search)
  );

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  let currentEntities = filteredEntries.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const getChain = (id, type, hash = null) => {
    // console.log(id, type, hash);
    let URL, SYMBOL;
    switch (id) {
      case Contract_details.POLYGON_CHAIN_ID:
        URL = `${Contract_details.POLYGON_EXPLORER_TX}${hash}`;
        SYMBOL = "POLYGON";
        break;
      case Contract_details.ETH_CHAIN_ID:
        URL = `${Contract_details.ETH_EXPLORER_TX}${hash}`;
        SYMBOL = "ETHEREUM";
        break;
      case Contract_details.BSC_CHAIN_ID:
        URL = `${Contract_details.BSC_EXPLORER_TX}${hash}`;
        SYMBOL = "BINANCE";
        break;
      case Contract_details.SOL_CHAIN_ID:
        URL = `${Contract_details.SOL_EXPLORER_TX}${hash}`;
        SYMBOL = "SOLANA";
        break;
      case Contract_details.TON_CHAIN_ID:
        URL = `${Contract_details.TON_EXPLORER_TX}${hash}`;
        SYMBOL = "TON";
        break;
      case Contract_details.TRON_CHAIN_ID:
        URL = `${Contract_details.TRON_EXPLORER_TX}${hash}`;
        SYMBOL = "TRON";
        break;
      case Contract_details.BTC_CHAIN_ID:
        URL = `${Contract_details.BTC_EXPLORER_TX}${hash}`;
        SYMBOL = "BTC";
        break;
      default:
        URL = `${Contract_details.BASE_EXPLORER_TX}${hash}`;
        SYMBOL = "BASE";
        break;
    }
    if (type == "Network") {
      return SYMBOL;
    } else {
      return URL;
    }
  };

  const ConvertTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDateTime = date.toISOString().slice(0, 19).replace("T", " ");
    return formattedDateTime;
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/Admin/Userdashboard`);
  };

  return (
    <div className="flex flex-col lg:flex-row">
      <Layout></Layout>

      <div className="w-[100%] bg-cover bg-center bg-no-repeat relative flex items-center bg-[#37159F]">
        <div className="z-10 w-full h-screen py-10 overflow-hidden overflow-y-auto font-syne text-white ">
          <div className="px-16">
            <div className="bg-[#0A0F2185] w-10 rounded-lg flex items-center justify-center ">
              <button onClick={handleBack}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            </div>
          </div>

          <div className="w-[90%]  mx-auto backdrop-blur-sm bg-[#0A0F2185] border border-[#FFFFFF5C] rounded-2xl py-5 px-5 md:px-10 mt-5 font-syne">
            <div className="flex justify-between pb-5">
              <div>
                <input
                  type="search"
                  className="border border-white/50 rounded-md py-2 px-5 outline-none bg-transparent"
                  placeholder="Enter User Hash"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="flex items-center">
                <p className="text-end text-sm px-3 mb-2">
                  Show no of entity
                  <select
                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                    onChange={handleDropdownChange}
                    value={entitiesPerPage}
                  >
                    <option className="text-black" value="5">
                      5
                    </option>
                    <option className="text-black" value="7">
                      7
                    </option>
                    <option className="text-black" value="10">
                      10
                    </option>
                  </select>
                </p>
              </div>
            </div>
            <div className="pb-5 rounded-xl overflow-hidden overflow-x-auto">
              <table className="w-full text-center ">
                <thead className="text-[#00FFC2]">
                  <tr>
                    <th>Paid Amount</th>
                    <th>Payment type</th>
                    <th>Tokens paid</th>
                    <th>TRX hash</th>
                    <th>Date & Time</th>
                    <th>Token claim Status</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#FFFFFF63]">
                  {loading ? (
                    <tr>
                      <td colSpan="7">Loading...</td>
                    </tr>
                  ) : currentEntities.length > 0 ? (
                    currentEntities.map((entity, index) => (
                      <tr
                        key={index}
                        className="h-16 text-sm md:text-base text-center rounded-md "
                      >
                        <td className="px-5 md:px-0">
                          {parseFloat(entity.TokenAmountUSD).toFixed(2)}
                        </td>
                        <td className="px-5 md:px-0">
                          {entity.Token} ({getChain(entity.chainId, "Network")})
                        </td>
                        <td className="px-5 md:px-0">
                          {parseFloat(entity.CoinAmount).toFixed(2)}
                        </td>
                        <td className="px-5 md:px-0 text-blue-500">
                          {entity.TokenHash ? (
                            <a
                              href={getChain(
                                entity.chainId,
                                "hash",
                                entity.TokenHash
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="hover:underline"
                            >
                              {entity.TokenHash.substring(0, 10) + "..."}
                            </a>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="px-5 md:px-0">
                          {ConvertTime(entity.createdAt)}
                          <br /> {entity.time}
                        </td>
                        <td
                          className={`px-5 md:px-0 font-bold ${
                            entity.CoinStatus === "success"
                              ? "text-[#00ff00]"
                              : "text-[#FF0000]"
                          }`}
                        >
                          {entity.CoinStatus === "success"
                            ? "success"
                            : "Pending"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-2 flex justify-between text-xs px-3">
              <button
                className="bg-white/30 rounded-md px-5 py-1"
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                Prev
              </button>
              <button
                className="bg-white/30 rounded-md px-5 py-1"
                onClick={handleNextClick}
                disabled={currentEntities.length < entitiesPerPage}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div className="background shading absolute bg-[#C156DD]/60 z-0 h-[90%] w-[100%] blur-3xl"></div>
      </div>
    </div>
  );
}
