import React from "react";
import Header from "./Header";

const Aboutus = () => {
  return (
    <div className='font-playfair  bg-[#5C27FE]'>
    <Header />
    <div className='text-white  bg-[#5C27FE] relative'>
      <div className='flex justify-center items-start'>
        <div className='pb-20 z-10'>
          <div className='sec-1 '>
            <div className='py-5 flex justify-center'>
              <img src='/assets/Star 2.png' />
            </div>
            <div className='py-5 flex justify-start absolute left-[20%]'>
              <img src='/assets/Star 2.png' />
            </div>
            <div className='py-5 flex justify-start absolute top-5 right-[30%]'>
              <img src='/assets/Star 2.png' />
            </div>
            <div className='py-5 flex justify-start absolute top-0 right-[5%]'>
              <img src='/assets/Star 7.png' />
            </div>
            <div className='flex justify-center items-center'>
              <img src='/assets/image 184.png' />
            </div>
            <div className='lg:block hidden absolute right-0 top-24 '>
              <img src='/assets/Vector 29.png'  />
            </div>
          </div>
               <div className='absolute left-5'>
           <img src='/assets/Star 5.png' />
               </div>
          <div className='sec-2 bg-cover bg-[#C156DDA6] border rounded-lg w-[90%] mx-auto py-10 ' >
            <h1 className='text-3xl font-bold text-center underline underline-offset-8'>About us</h1>
            <div className='py-10 lg:w-[70%] w-[90%]  mx-auto '>
              <p className='text-center text-lg text-black  font-semibold font-poppins'>RealMEME Coin (RMC) is an innovative cryptocurrency that blends the fun and engaging aspects of meme culture with the tangible value of real-world /assets. The RMC is designed to provide both entertainment and real-world utility, creating a unique opportunity for crypto enthusiasts and traditional investors alike. </p>
            </div>
            <div className='w-[80%] mx-auto lg:py-0 py-10 relative px-10 bg-white/40  border-4 rounded-2xl border-white/60 '>
             <div className='flex lg:flex-row flex-col justify-center items-center'>
             <img src='/assets/image 193.png' />
             <div className='lg:mt-0 mt-10'>
              <p className='lg:px-10 px-5 font-light text-xl font-poppins text-black'>Meet Golden Paw, the coolest dog in the crypto jungle! With his bling, shades, and swag, Golden Paw is here to show you how to live the billionaire's life while keeping things fun and fabulous. He's also leading the charge in building the CryptoNation, our very own independent state! </p>
             </div>
         
             </div>
             <div className='absolute right-5 bottom-20 '>
               <img src='/assets/Star 2.png' className='w-8 h-8 opacity-50 ' />
               </div>
               <div className='absolute -right-10 bottom-10 '>
               <img src='/assets/Star 2.png' className='w-8 h-8 opacity-50 ' />
               </div>
             <div>
            
             </div>
            </div>
            <div className='absolute left-9'>
           <img src='/assets/Star 5.png' />
               </div>
            <div className='sec-3 pt-10 w-[90%] mx-auto '>
              <h2 className='text-2xl font-bold text-center'>Story: The Adventures of Golden Paw</h2>

              <div className='font-poppins py-10 text-black font-semibold'>
                  <div>
                  <p className='text-lg'>Chapter 1: The Genesis of Wealth </p>
                   <p>In the heart of CryptoVille, where the digital currency revolution thrives, Doby lives. He exudes a charismatic charm and an air of affluence, always dressed in the finest gold chains and designer sunglasses. His journey began with a vision to blend the whimsical world of memes with the tangible value of real-world /assets, thus giving birth to RealMEME Coin (RMC). </p> 
                  </div>

                  <div className='pt-10'>
                 <p className='text-lg'>Chapter 2: The Quest for Treasure </p>
                 <p>Golden Paw set out on a grand adventure, traveling across the globe to gather valuable /assets. With every new asset acquired, Doby's legend grew, attracting investors eager to join his journey. </p>
                  </div>

                  <div className='pt-10'>
                     <p className='text-lg'>Chapter 3: Building an Empire </p>
                     <p>Next, Doby negotiated deals to acquire prime real estate, bringing the world of high finance into the realm of digital currency. </p>
                  </div>

                  <div className='pt-10'>
                      <p className='text-lg'>Chapter 4: The Collector's Dream </p>
                      <p>Doby's adventures led him to the serene temples of Kyoto, where he discovered rare artifacts and collectibles. These treasures were added to the RealMEME ecosystem, further increasing the coin's value and appeal.  </p>

                  </div>

                  <div className='pt-10'>
                       <p className='text-lg'>Chapter 5: The Global Network </p>
                       <p>From the vineyards of France to the beaches of Brazil, RealMEME Coin became synonymous with innovation and prosperity. Investors from all corners of the globe joined the adventure, contributing to the growth and success of the coin. </p>
                       <p>Epilogue: The Legacy Continues </p>
                       <p>Golden Paw's journey is far from over. As RealMEME Coin continues to grow, so does the community of investors who believe in the vision of blending digital /assets with real-world value.  </p>
                  </div>

                 
              </div>

              <div className='font-poppins'>
                      <p className='font-bold'>Join Golden Paw and the RealMEME Coin Community Today! </p>
                  </div>
          
          </div>
          </div>

         
        </div>
        <img src='/assets/Ellipse 73.png' className='w-[100%]  absolute z-0' />
      </div>
    </div>
  </div>
  );
};

export default Aboutus;