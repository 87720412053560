import { Contract_details } from "./Components/Config";

const EthereumTokens = [
  {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
    img: "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  },
  {
    name: "Tether USD",
    symbol: "USDT",
    decimals: Contract_details.ETH_USDT_DECIMAL,
    img: "https://etherscan.io/token/images/tethernew_32.png",
    address: Contract_details.ETH_USDT_CONTRACT,
  },
  {
    name: "USDC",
    symbol: "USDC",
    decimals: Contract_details.ETH_USDC_DECIMAL,
    img: "https://etherscan.io/token/images/centre-usdc_28.png",
    address: Contract_details.ETH_USDC_CONTRACT,
  },
  {
    name: "SHIBA INU",
    symbol: "SHIB",
    decimals: Contract_details.ETH_SHIB_DECIMAL,
    img: "https://etherscan.io/token/images/shibatoken_32.png",
    address: Contract_details.ETH_SHIB_CONTRACT,
  },
];

const PolygonTokens = [
  {
    name: "Matic Token",
    symbol: "MATIC",
    address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    decimals: 18,
    img: "https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.7.2.0",
  },
  {
    name: "(PoS) Tether USD",
    symbol: "USDT",
    address: Contract_details.POLYGON_USDT_CONTRACT,
    decimals: Contract_details.POLYGON_USDT_DECIMAL,
    img: "https://polygonscan.com/token/images/tether_32.png",
  },
  {
    symbol: "USDC",
    address: Contract_details.POLYGON_USDC_CONTRACT,
    decimals: Contract_details.POLYGON_USDC_DECIMAL,
    name: "USD Coin",
    img: "https://polygonscan.com/token/images/usdc_32.png",
  },
];

const BscTokens = [
  {
    name: "BNB",
    symbol: "BNB",
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    decimals: 18,
    img: "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg?v=24.7.2.1",
  },
  {
    name: "Binance-Peg USD Coin",
    symbol: "BUSD",
    address: Contract_details.BSC_BUSD_CONTRACT,
    decimals: Contract_details.BSC_BUSD_DECIMAL,
    img: "https://bscscan.com/token/images/busd_32_2.png",
  },
];

const BaseTokens = [
  {
    name: "BASE",
    symbol: "BASE",
    address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    decimals: 18,
    img: "https://basescan.org/assets/base/images/svg/logos/chain-light.svg?v=24.7.2.0",
  },
  {
    name: "USD Coin",
    symbol: "USDC",
    address: Contract_details.BASE_USDC_CONTRACT,
    decimals: Contract_details.BASE_USDC_DECIMAL,
    img: "https://basescan.org/token/images/centre-usdc_28.png",
  },
];

const SolTokens = [
  {
    name: "SOL",
    symbol: "SOL",
    decimals: 9,
    img: "https://solscan.io/_next/static/media/solPriceLogo.76eeb122.png",
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  },
];

const TonTokens = [
  {
    name: "TON",
    symbol: "TON",
    decimals: 9,
    img: "https://cryptologos.cc/logos/toncoin-ton-logo.png",
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  },
];

const TronTokens = [
  {
    name: "TRON",
    symbol: "TRX",
    address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    decimals: 6,
    img: "https://static.tronscan.org/production/logo/trx.png",
  },
  {
    name: "Tether USD",
    symbol: "USDT",
    address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
    decimals: 6,
    img: "https://static.tronscan.org/production/logo/usdtlogo.png",
  },
];

const BtcTokens = [
  {
    name: "BTC",
    symbol: "BTC",
    address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    decimals: 8,
    img: "https://btcscan.org/img/icons/Bitcoin-menu-logo.svg",
  },
];

export {
  EthereumTokens,
  PolygonTokens,
  BscTokens,
  BaseTokens,
  SolTokens,
  TonTokens,
  TronTokens,
  BtcTokens,
};
