import React from "react";

export default function Footer() {
  return (
    <div className="border-t border-white pb-8">
      <div className="flex justify-center">
        <div className="w-[80%] flex flex-col lg:flex-row justify-between py-10">
          <div className="w-[85%]">
            <img src="/assets/Frame 6.png"></img>
            <p className="text-white lg:text-lg text-base font-poppins mt-4">
              All content on this website is for informational purposes only and
              should not be construed as financial, investment, or trading
              advice. Investing in cryptocurrencies involves significant risk,
              and you should do your own research before making any investment
              decisions.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="w-[80%] border-b border-white py-5 flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between items-center">
          <div className="flex lg:gap-16  gap-2">
            <a className="text-white  lg:text-lg text-base cursor-pointer font-poppins">
              Home
            </a>
            <a className="text-white lg:text-lg text-base cursor-pointer font-poppins">
              About
            </a>
            <a className="text-white lg:text-lg text-base cursor-pointer font-poppins">
              Services
            </a>
            <a className="text-white lg:text-lg text-base cursor-pointer font-poppins">
              Price
            </a>
            <a className="text-white lg:text-lg text-base cursor-pointer font-poppins">
              News
            </a>
          </div>
          <div className="flex gap-10">
            <a className="" href="https://x.com/RealGoldPaw" target="_blank">
              <i
                class="fa-brands fa-2xl fa-square-x-twitter"
                style={{ color: "#f7f7f8" }}
              ></i>
            </a>
            <a className="" href="https://discord.com/channels/1274941772618924063/1274941772618924067" target="_blank">
              <i class="fa-brands fa-2xl fa-discord" style={{ color: "#f5f7fa" }}></i>
            </a>
            <a className="" href="https://t.me/Real_Meme_Coin" target="_blank">
              <i class="fa-brands fa-2xl fa-telegram" style={{ color: "#f5f7fa" }}></i>
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <div className="w-[80%] flex flex-col gap-5 lg:gap-0 lg:flex-row justify-bteween">
          <div className="lg:w-[50%]">
            <p className="font-poppins lg:text-lg text-base">
              <i
                class="fa-regular fa-copyright"
                style={{ color: "#f9fafb" }}
              ></i>
              &nbsp;2024 RealMemecoin • All Rights Reserved
            </p>
          </div>
          <div className="lg:w-[50%] flex lg:justify-end  gap-10 items-center">
            <p className=" lg:text-lg text-base">Terms</p>
            <p className=" lg:text-lg text-base">Privacy</p>
          </div>
        </div>
      </div>
    </div>
  );
}
