import React, { useState, useEffect } from "react";

const initialDate = new Date("2024-09-06T00:00:00");

const Countdown = () => {
  const getInitialTargetTime = () => {
    const storedTargetTime = localStorage.getItem("targetTime");
    return storedTargetTime ? new Date(storedTargetTime) : initialDate;
  };

  const [targetTime, setTargetTime] = useState(getInitialTargetTime);

  const calculateTimeLeft = (targetDate) => {
    const currentTime = new Date();
    const difference = targetDate - currentTime;

    if (difference <= 0) {
      return { days: "00", hours: "00", minutes: "00", seconds: "00" };
    } else {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return {
        days: days.toString().padStart(2, "0"),
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0"),
      };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetTime));

  useEffect(() => {
    const updateCountdown = () => {
      const newTimeLeft = calculateTimeLeft(targetTime);

      if (
        newTimeLeft.days === "00" &&
        newTimeLeft.hours === "00" &&
        newTimeLeft.minutes === "00" &&
        newTimeLeft.seconds === "00"
      ) {
        const newTargetTime = new Date(
          targetTime.getTime() + 3 * 24 * 60 * 60 * 1000
        );
        setTargetTime(newTargetTime);
        localStorage.setItem("targetTime", newTargetTime);
      } else {
        setTimeLeft(newTimeLeft);
      }
    };

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [targetTime]);


  return (
    <div className="w-full flex justify-center">
      <div className="flex justify-center items-center space-x-2 md:space-x-4 bg-white/60 rounded-2xl border px-3 md:px-5 pt-3 pb-1">
        {Object.entries(timeLeft).map(([unit, value], index) => (
          <div key={index} className="flex flex-col items-center ">
            <div className="flex space-x-1  ">
              {value.split("").map((char, i) => (
                <div
                  key={i}
                  className={`border-[0.5px] border-black/30 ${
                    char === ":"
                      ? "text-700 font-digital text-5xl flex justify-center items-center "
                      : "bg-white rounded-md shadow-md text-purple-700 font-digital text-2xl md:text-3xl md:text-[44px] flex justify-center items-center w-8 md:w-10 h-12 md:h-[56px]"
                  }`}
                  style={{
                    background:
                      char === ":"
                        ? "none"
                        : "linear-gradient(180deg, rgba(255, 255, 255, 0.80), rgba(186, 172, 217, 0.65)) ",
                    color: "black",
                    boxShadow:
                      char === ":" ? "none" : "0 0 10px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {char}
                </div>
              ))}
            </div>
            <div className="text-xs md:text-lg font-poppins italic text-black font-medium mt-2 ">
              {unit.toUpperCase()}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Countdown;