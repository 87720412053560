import Header from "./Header";
import Footer from "./Footer";
import "./Landing.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Countdown from "./Timer";

import React, { useEffect, useState, useRef, useContext } from "react";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { token_abi } from "../ABI";
import { ethers } from "ethers";
import { BigNumber } from "bignumber.js";
import toast, { Toaster } from "react-hot-toast";
import { WalletContext } from "./WalletContext";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import QRCode from "qrcode.react";
import axios from "axios";

import {
  Contract_details,
  NODE_URL,
  RPC_URL,
  TOKEN_CONTRACT,
  SOL_ADMIN_ADDRESS,
  EVM_ADMIN_ADDRESS,
  TON_ADMIN_ADDRESS,
  TRON_ADMIN_ADDRESS,
  BTC_ADMIN_ADDRESS,
} from "./Config";
import {
  PublicKey,
  Connection,
  Transaction,
  TransactionInstruction,
  SystemProgram,
  clusterApiUrl,
  sendAndConfirmRawTransaction,
} from "@solana/web3.js";
import { Buffer } from "buffer";
const { Cell } = require("ton");

export default function Landing() {
  const { walletAddress, walletAddressType } = useContext(WalletContext);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [tonConnectUI] = useTonConnectUI();

  const initialDate = new Date("2024-09-06T00:00:00");

  const getInitialTargetTime = () => {
    const storedTargetTime = localStorage.getItem("targetTime");
    return storedTargetTime ? new Date(storedTargetTime) : initialDate;
  };

  const [targetTime, setTargetTime] = useState(getInitialTargetTime);

  const calculateTimeLeft = (targetDate) => {
    const currentTime = new Date();
    const difference = targetDate - currentTime;

    if (difference <= 0) {
      return { days: "00", hours: "00", minutes: "00", seconds: "00" };
    } else {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return {
        days: days.toString().padStart(2, "0"),
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0"),
      };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetTime));

  useEffect(() => {
    const updateCountdown = () => {
      const newTimeLeft = calculateTimeLeft(targetTime);

      if (
        newTimeLeft.days === "00" &&
        newTimeLeft.hours === "00" &&
        newTimeLeft.minutes === "00" &&
        newTimeLeft.seconds === "00"
      ) {
        const newTargetTime = new Date(
          targetTime.getTime() + 3 * 24 * 60 * 60 * 1000
        );
        setTargetTime(newTargetTime);
        localStorage.setItem("targetTime", newTargetTime);
      } else {
        setTimeLeft(newTimeLeft);
      }
    };

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [targetTime]);

  const [Amount, setAmount] = useState(0);
  const [TokenAmount, setTokenAmount] = useState(0);
  const [selectedToken, setSelectedToken] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState({});
  const [TokenList, setTokenList] = useState([]);

  const [PreOrder, setPreOrder] = useState(null);
  const [TokenUSDprice, setTokenUSDprice] = useState(null);
  const [MinQuantity, setMinQuantity] = useState(null);
  const [MaxQuantity, setMaxQuantity] = useState(null);
  const [PreSaleStatus, setPreSaleStatus] = useState(null);
  const [Purchased, setPurchased] = useState(0);

  const [PreOrderBalance, setPreOrderBalance] = useState(null);
  const [Percentage, setPercentage] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [solanaWalletAddress, setSolanaWalletAddress] = useState(null);

  const [adminWallet, setAdminWallet] = useState(null);
  const [hasShownWalletToast, setHasShownWalletToast] = useState(false);
  const [hasShownInputToast, setHasShownInputToast] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const isAnyTokenSelected = selectedIndex !== null;
  const walletToastTimeoutRef = useRef(null);
  const inputToastTimeoutRef = useRef(null);

  const [fromAddress, setFromAddress] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [qrPopup, setQrPopup] = useState("");
  const [confirmPopup, setConfirmPopup] = useState("");

  const [NetworkDropdown, setNetworkDropdown] = useState(false);
  const [TokenDropdown, setTokenDropdown] = useState(false);
  const [filteredNetworks, setFilteredNetworks] = useState([]);

  const [Text, setText] = useState(false);

  const networks = {
    ethereum: {
      chainId: 1,
      rpcUrls: [Contract_details.ETH_RPC],
      chainName: "Ethereum Mainnet",
      nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
      blockExplorerUrls: [Contract_details.ETH_EXPLORER],
    },
    polygon: {
      chainId: 137,
      rpcUrls: [Contract_details.POLYGON_RPC],
      chainName: "Polygon Mainnet",
      nativeCurrency: { name: "Matic", symbol: "MATIC", decimals: 18 },
      blockExplorerUrls: [Contract_details.POLYGON_EXPLORER],
    },
    base: {
      chainId: 8453,
      rpcUrls: [Contract_details.BASE_RPC],
      chainName: "Base Mainnet",
      nativeCurrency: { name: "Base Coin", symbol: "BASE", decimals: 18 },
      blockExplorerUrls: [Contract_details.BASE_EXPLORER],
    },
    bsc: {
      chainId: 56,
      rpcUrls: [Contract_details.BSC_RPC],
      chainName: "Binance Smart Chain",
      nativeCurrency: { name: "Binance Coin", symbol: "BNB", decimals: 18 },
      blockExplorerUrls: [Contract_details.BSC_EXPLORER],
    },
  };

  const NetworkList = [
    {
      name: "ETHEREUM",
      symbol: "ETH",
      img: "https://etherscan.io/images/svg/brands/ethereum-original.svg",
      chainId: "1",
    },
    {
      name: "BSC",
      symbol: "BSC",
      img: "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg?v=24.7.2.0",
      chainId: "56",
    },
    {
      name: "BASE",
      symbol: "ETH",
      img: "https://basescan.org/assets/base/images/svg/logos/chain-light.svg?v=24.7.2.0",
      chainId: "8453",
    },
    {
      name: "POLYGON",
      symbol: "MATIC",
      img: "https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.6.3.0",
      chainId: "137",
    },
    {
      name: "SOLANA",
      symbol: "SOL",
      img: "https://cryptologos.cc/logos/solana-sol-logo.png",
      chainId: "900",
    },
    {
      name: "TON",
      symbol: "TON",
      img: "https://cryptologos.cc/logos/toncoin-ton-logo.png",
      chainId: "622463",
    },
    {
      name: "TRON",
      symbol: "TRX",
      img: "https://cryptologos.cc/logos/tron-trx-logo.png",
      chainId: "1000",
    },
    {
      name: "BITCOIN",
      symbol: "BTC",
      img: "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
      chainId: "10",
    },
  ];

  const TokensList = {
    1: [
      {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
        img: "https://etherscan.io/images/svg/brands/ethereum-original.svg",
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      },
      {
        name: "Tether USD",
        symbol: "USDT",
        decimals: Contract_details.ETH_USDT_DECIMAL,
        img: "https://etherscan.io/token/images/tethernew_32.png",
        address: Contract_details.ETH_USDT_CONTRACT,
      },
      {
        name: "USDC",
        symbol: "USDC",
        decimals: Contract_details.ETH_USDC_DECIMAL,
        img: "https://etherscan.io/token/images/centre-usdc_28.png",
        address: Contract_details.ETH_USDC_CONTRACT,
      },
      {
        name: "SHIBA INU",
        symbol: "SHIB",
        decimals: Contract_details.ETH_SHIB_DECIMAL,
        img: "https://etherscan.io/token/images/shibatoken_32.png",
        address: Contract_details.ETH_SHIB_CONTRACT,
      },
    ],
    56: [
      {
        name: "BNB",
        symbol: "BNB",
        address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        decimals: 18,
        img: "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg?v=24.7.2.1",
      },
      {
        name: "Binance-Peg USD Coin",
        symbol: "BUSD",
        address: Contract_details.BSC_BUSD_CONTRACT,
        decimals: Contract_details.BSC_BUSD_DECIMAL,
        img: "https://bscscan.com/token/images/busd_32_2.png",
      },
    ],
    8453: [
      {
        name: "BASE",
        symbol: "BASE",
        address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
        decimals: 18,
        img: "https://basescan.org/assets/base/images/svg/logos/chain-light.svg?v=24.7.2.0",
      },
      {
        name: "USD Coin",
        symbol: "USDC",
        address: Contract_details.BASE_USDC_CONTRACT,
        decimals: Contract_details.BASE_USDC_DECIMAL,
        img: "https://basescan.org/token/images/centre-usdc_28.png",
      },
    ],
    137: [
      {
        name: "Matic Token",
        symbol: "MATIC",
        address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
        decimals: 18,
        img: "https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.7.2.0",
      },
      {
        name: "(PoS) Tether USD",
        symbol: "USDT",
        address: Contract_details.POLYGON_USDT_CONTRACT,
        decimals: Contract_details.POLYGON_USDT_DECIMAL,
        img: "https://polygonscan.com/token/images/tether_32.png",
      },
      {
        symbol: "USDC",
        address: Contract_details.POLYGON_USDC_CONTRACT,
        decimals: Contract_details.POLYGON_USDC_DECIMAL,
        name: "USD Coin",
        img: "https://polygonscan.com/token/images/usdc_32.png",
      },
    ],
    900: [
      {
        name: "SOL",
        symbol: "SOL",
        decimals: 9,
        img: "https://solscan.io/_next/static/media/solPriceLogo.76eeb122.png",
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      },
    ],
    622463: [
      {
        name: "TON",
        symbol: "TON",
        decimals: 9,
        img: "https://cryptologos.cc/logos/toncoin-ton-logo.png",
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      },
    ],
    1000: [
      {
        name: "TRON",
        symbol: "TRX",
        address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
        decimals: 6,
        img: "https://static.tronscan.org/production/logo/trx.png",
      },
      {
        name: "Tether USD",
        symbol: "USDT",
        address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
        decimals: 6,
        img: "https://static.tronscan.org/production/logo/usdtlogo.png",
      },
    ],
    10: [
      {
        name: "BTC",
        symbol: "BTC",
        address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
        decimals: 8,
        img: "https://btcscan.org/img/icons/Bitcoin-menu-logo.svg",
      },
    ],
  };

  const getAllTokens = () => {
    const tokenMap = {};
    Object.values(TokensList)
      .flat()
      .forEach((token) => {
        if (!tokenMap[token.symbol]) {
          tokenMap[token.symbol] = token;
        }
      });
    return Object.values(tokenMap);
  };
  let TokenTotal = getAllTokens();

  const handleNetworkDropdown = () => {
    setNetworkDropdown(!NetworkDropdown);
  };

  const handleTokenDropdown = () => {
    setTokenDropdown(!TokenDropdown);
  };

  const choseTokens = (index, token) => {
    const availableNetworks = Object.keys(TokensList)
      .filter((chainId) => {
        return TokensList[chainId].some((t) => t.symbol === token.symbol);
      })
      .map((chainId) => {
        return NetworkList.find((network) => network.chainId === chainId);
      });

    console.log(availableNetworks);
    setFilteredNetworks(availableNetworks);
    setSelectedToken(token);
    setTokenDropdown(false);
    setSelectedNetwork({});
  };

  const choseNetworkList = async (index, network) => {
    if (!selectedToken) {
      toast.error("No token selected.");
      console.error("No token selected.");
      return;
    }

    if (walletAddress === null) {
      toast.error("Connect the wallet");
      console.error("No token selected.");
      return;
    }
    let selected = NetworkList.find((n) => n.chainId === network.chainId);

    if (!selected) {
      console.error("Selected network not found.");
      return;
    }

    const chain = selected.chainId;
    console.log(walletAddressType, chain, chainId, selected);

    let evmChains = [1, 137, 56, 8453];

    const isEVM = walletAddressType === "EVM";
    const isChainMatch = chain === chainId;
    const isPhantomSOL =
      walletAddressType === "PHANTOM" && selected.symbol === "SOL";
    const isTON = walletAddressType === "TON" && selected.symbol === "TON";
    const isTRON = walletAddressType === "TRON" && selected.symbol === "TRX";
    const isBTC = walletAddressType === "BTC" && selected.symbol === "BTC";

    if (isEVM && isChainMatch) {
      setSelectedNetwork(selected);
    } else if (isEVM && !isChainMatch && evmChains.includes(Number(chain))) {
      let result = await switchNetwork(Number(chain));
      if (result) {
        setSelectedNetwork(selected);
      } else {
        setSelectedNetwork(null);
      }
    } else if (isPhantomSOL || isTON || isTRON || isBTC) {
      setSelectedNetwork(selected);
    } else {
      toast.error(`Switch to ${selected.name} Wallet`);
      setSelectedNetwork(null);
    }

    // Now, set the selected token in the selected network
    const token = TokensList[chain].find(
      (t) => t.symbol === selectedToken.symbol
    );
    if (token) {
      setSelectedToken(token);
    }

    setNetworkDropdown(false);
  };

  const switchNetwork = async (chainId) => {
    const network = Object.values(networks).find(
      (net) => net.chainId === chainId
    );

    if (!network) {
      console.error("Network not supported.");
      toast.error("Network not supported.");
      return false;
    }

    try {
      await walletProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexValue(chainId) }],
      });

      toast.success(`Switched to ${network.chainName}`);
      return true;
    } catch (switchError) {
      console.error("Switch Error:", switchError);

      if (switchError.code === 4902) {
        try {
          await walletProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: ethers.utils.hexValue(chainId),
                chainName: network.chainName,
                nativeCurrency: network.nativeCurrency,
                rpcUrls: network.rpcUrls,
                blockExplorerUrls: network.blockExplorerUrls,
              },
            ],
          });
          toast.success(`Added and switched to ${network.chainName}`);
          return true;
        } catch (addError) {
          console.error("Add Error:", addError);
          toast.error("Failed to add network.");
          return false;
        }
      } else {
        toast.error("Failed to switch network.");
        return false;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValidAddress = isValidSolanaAddress(solanaWalletAddress);
    console.log(isValidAddress, walletAddress, solanaWalletAddress);
    if (isValidAddress) {
      const res = await axios.post(`${NODE_URL}/apiv2/addSolanaAddress`, {
        address: walletAddress,
        solanaWalletAddress: solanaWalletAddress,
      });
      if (res.data && res.data.status === "success") {
        console.log("Solana Wallet Address:", solanaWalletAddress, res.data);
        setShowPopup(false);
        Transfer();
      } else {
        console.error("Error : ", res);
        toast.error("Re-enter Solana Wallet Address");
      }
    } else {
      console.error("Invalid Solana Wallet Address");
      toast.error("Invalid Solana Wallet Address");
    }
  };

  const isValidSolanaAddress = (address) => {
    try {
      new PublicKey(address);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (isNaN(value)) {
      if (!hasShownInputToast) {
        toast.error("Please enter numerical values only");
        setHasShownInputToast(true);

        if (inputToastTimeoutRef.current) {
          clearTimeout(inputToastTimeoutRef.current);
        }
        inputToastTimeoutRef.current = setTimeout(() => {
          setHasShownInputToast(false);
        }, 5000);
      }
      return;
    }

    if (walletAddress === null && !hasShownWalletToast) {
      toast.error("Please connect the wallet");
      setHasShownWalletToast(true);

      if (walletToastTimeoutRef.current) {
        clearTimeout(walletToastTimeoutRef.current);
      }
      walletToastTimeoutRef.current = setTimeout(() => {
        setHasShownWalletToast(false);
      }, 5000);
    }
    console.log(value);
    setAmount(value);
  };

  useEffect(() => {
    return () => {
      if (walletToastTimeoutRef.current) {
        clearTimeout(walletToastTimeoutRef.current);
      }
      if (inputToastTimeoutRef.current) {
        clearTimeout(inputToastTimeoutRef.current);
      }
    };
  }, []);

  const validateAmount = async () => {
    let Token;
    let value;
    if (Amount > 0) {
      value = Amount;
    } else {
      value = 0;
    }
    if (selectedToken.symbol === "WMATIC") {
      Token = "MATIC";
    } else if (selectedToken.symbol === "USDC.e") {
      Token = "USDC";
    } else {
      Token = selectedToken.symbol;
    }

    const response = await axios.get(
      `https://min-api.cryptocompare.com/data/price?api_key=830c94225fed427911d9c8e4ea29b220d78e466ee4f597fa8ecce33ee602355c&fsym=${Token}&tsyms=USD`
    );
    const data = response.data;

    if (data.Response === "Error") {
      console.error("Error in API response:", data.Message);
      return;
    }

    const conversionRate = data ? data.USD : 0;
    console.log("Conversion Rate:", conversionRate);

    setTokenAmount((value * conversionRate).toFixed(4));

    console.log((value * conversionRate).toFixed(4) / Number(TokenUSDprice), Number(MaxQuantity))
    if ((value * conversionRate).toFixed(4) / Number(TokenUSDprice) > Number(MaxQuantity)) {
      setText(true)
    } else {
      setText(false)
    }
  };

  useEffect(() => {
    if (selectedToken !== null) {
      validateAmount();
    }
  }, [Amount, selectedToken]);

  useEffect(() => {
    console.log(walletAddressType);
    if (walletAddressType !== null) {
      if (walletAddressType === "PHANTOM") {
        setAdminWallet(SOL_ADMIN_ADDRESS);
      } else if (walletAddressType === "TON") {
        setAdminWallet(TON_ADMIN_ADDRESS);
      } else if (walletAddressType === "TRON") {
        setAdminWallet(TRON_ADMIN_ADDRESS);
      } else if (walletAddressType === "BTC") {
        setAdminWallet(BTC_ADMIN_ADDRESS);
      } else {
        setAdminWallet(EVM_ADMIN_ADDRESS);
      }
    } else {
      setAdminWallet(null);
    }
  }, [walletAddress, walletAddressType]);

  const Transfer = async () => {
    try {
      if (walletAddress === null) {
        toast.error("Please connect your wallet and Try Again");
        return false;
      }
      if (!Amount || Amount <= 0 || !selectedToken) {
        toast.error("Please enter all fields correctly.");
        return false;
      }

      if (PreSaleStatus === "inactive") {
        toast.error("Pre-Sale Ended");
        return false;
      }

      const tokenAmountInMeme = Number(
        (Number(TokenAmount) / Number(TokenUSDprice)).toFixed(2)
      );
      console.log(TokenAmount, tokenAmountInMeme, MinQuantity, MaxQuantity); // 5.0000000000 5 10

      if (tokenAmountInMeme < Number(MinQuantity)) {
        toast.error("Minimum Purchase Amount is " + MinQuantity + " RMC");
        return false;
      }

      if (tokenAmountInMeme > Number(MaxQuantity)) {
        toast.error("Maximum Purchase Amount is " + MaxQuantity + " RMC");
        return false;
      }

      console.log(Number(PreOrderBalance) > Number(PreOrder));
      if (Number(PreOrderBalance) > Number(PreOrder)) {
        toast.error("PreOrder Sale is Over");
        return false;
      }

      console.log(selectedNetwork)
      if (selectedNetwork === null) {
        toast.error("Please select the Network");
        return;
      }


      if (walletAddressType === "PHANTOM" && selectedNetwork.name === "SOLANA") {
        if (selectedToken.symbol === "SOL") {
          TransferSOL(walletAddress, SOL_ADMIN_ADDRESS, Amount);
        } else {
          // transferSPLToken(
          //   new PublicKey(walletAddress),
          //   new PublicKey(walletAddress),
          //   new PublicKey(selectedToken.address),
          //   Amount,
          //   selectedToken.decimals
          // );
        }
      } else if (walletAddressType === "TON" && selectedNetwork.name === "TON") {
        TransferTON(TON_ADMIN_ADDRESS, Amount);
      } else if (walletAddressType === "TRON" && selectedNetwork.name === "TRON") {
        TransferTRON(TRON_ADMIN_ADDRESS, Amount);
      } else if (walletAddressType === "BTC" && selectedNetwork.name === "BITCOIN") {
        TransferBTC(BTC_ADMIN_ADDRESS, Amount);
      } else if (
        walletAddressType === "EVM" &&
        ["ETHEREUM", "POLYGON", "BSC", "BASE"].includes(selectedNetwork.name)
      ) {
        TransferTokens();
      } else {
        toast.error("Choose the correct Network");
        return;
      }

    } catch (error) {
      console.error("Error chosing network : ", error);
    }
  };

  const TransferTokens = async () => {
    console.log(Amount, selectedToken, address, solanaWalletAddress);

    if (!address || !isConnected) {
      toast.error("Please connect your wallet and Try Again");
      return false;
    }
    if (!Amount || Amount <= 0 || !selectedToken || !address) {
      toast.error("Please enter all fields correctly.");
      return false;
    }

    if (PreSaleStatus === "inactive") {
      toast.error("Pre-Sale Ended");
      return false;
    }

    const tokenAmountInMeme = Number(
      (Number(TokenAmount) / Number(TokenUSDprice)).toFixed(2)
    );
    console.log(TokenAmount, tokenAmountInMeme, MinQuantity, MaxQuantity); // 5.0000000000 5 10

    if (tokenAmountInMeme < Number(MinQuantity)) {
      toast.error("Minimum Purchase Amount is " + MinQuantity + " MEME");
      return false;
    }

    if (tokenAmountInMeme > Number(MaxQuantity)) {
      toast.error("Maximum Purchase Amount is " + MaxQuantity + " MEME");
      return false;
    }

    if (!isConnected) {
      toast.error("Please connect the wallet.");
      return false;
    }
    console.log(Number(PreOrderBalance) > Number(PreOrder));
    if (Number(PreOrderBalance) > Number(PreOrder)) {
      toast.error("PreOrder Sale is Over");
      return false;
    }

    const TokenAddress = selectedToken.address;
    const TokenAbi = token_abi;
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const contractInstance = new ethers.Contract(
      TokenAddress,
      TokenAbi,
      signer
    );

    const TransferAmount = new BigNumber(
      Amount * 10 ** selectedToken.decimals
    ).toFixed(0);
    const recipientAddress = EVM_ADMIN_ADDRESS;

    const toastId1 = toast.loading("Token transaction in progress", {
      duration: 3000,
    });
    let transferTransaction;
    setTimeout(() => {
      toast.dismiss(toastId1);
    }, 2000);
    try {
      if (
        selectedToken.symbol === "MATIC" ||
        selectedToken.symbol === "ETH" ||
        selectedToken.symbol === "BNB" ||
        selectedToken.symbol === "BASE"
      ) {
        transferTransaction = await signer.sendTransaction({
          to: recipientAddress,
          value: TransferAmount,
        });
      } else {
        transferTransaction = await contractInstance.transfer(
          recipientAddress,
          TransferAmount
        );
      }

      console.log(`${selectedToken.symbol} transaction successful`);
      const result = await transferTransaction.wait();
      const Check = {
        address: address,
        Token: selectedToken.symbol,
        amount: Amount,
        amountUSD: TokenAmount,
        CoinAmount: TokenAmount / Number(TokenUSDprice),
        CoinAmountUSD: TokenUSDprice,
        TokenHash: result.transactionHash,
        network: chainId,
        solanaWalletAddress: solanaWalletAddress,
        TokenStatus: result.status,
      };
      console.log(Check);
      if (result && result.transactionHash) {
        const CoinAmt = TokenAmount / Number(TokenUSDprice);
        const receiveTokens = await axios.get(
          `${NODE_URL}/apiv2/getMemeTokens?address=${address}&Token=${selectedToken.symbol}&amount=${Amount}&amountUSD=${TokenAmount}&CoinAmount=${CoinAmt}&CoinAmountUSD=${TokenUSDprice}&TokenHash=${result.transactionHash}&network=${chainId}&solanaWalletAddress=${solanaWalletAddress}&TokenStatus=${result.status}`
        );
        console.log(receiveTokens);
        if (receiveTokens.data.status === "success") {
          toast.success(`${selectedToken.symbol} transaction successful`, {
            id: toastId1,
          });
          toast.success(
            "Your Tokens will be Claimable Once  Presale is Over!...."
          );
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else {
        toast.error("Transaction failed", { id: toastId1 });
      }
    } catch (error) {
      console.log(error, "error");
      const errorData = error.error && error.error.data;
      if (
        errorData &&
        errorData.code === 3 &&
        errorData.message.includes("transfer amount exceeds balance")
      ) {
        toast.error("Insufficient balance to perform this transaction");
      } else if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
        toast.error("Transaction may fail or may require manual gas limit");
      } else {
        toast.error(
          "An unexpected error occurred while during this Transaction"
        );
      }
      console.error("Error performing the transaction:", error);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const TransferTON = async (TON_ADMIN_ADDRESS, amount) => {
    try {
      const toastId1 = toast.loading("Token transaction in progress", {
        duration: 3000,
      });
      setTimeout(() => {
        toast.dismiss(toastId1);
      }, 2000);
      let amount1 = amount * 1e9;

      const transaction = {
        messages: [
          {
            address: TON_ADMIN_ADDRESS,
            amount: amount1.toString(),
          },
        ],
      };
      const response = await tonConnectUI.sendTransaction(transaction);

      console.log(response);
      const boc = response.boc;

      const cell = Cell.fromBoc(Buffer.from(boc, "base64"))[0];
      const signature = cell.hash().toString("hex");
      console.log("Transaction Hash:", signature);

      const Check = {
        address: address,
        Token: selectedToken.symbol,
        amount: Amount,
        amountUSD: TokenAmount,
        CoinAmount: TokenAmount / Number(TokenUSDprice),
        CoinAmountUSD: TokenUSDprice,
        TokenHash: signature,
        network: "622463",
        solanaWalletAddress: solanaWalletAddress,
        TokenStatus: "success",
      };
      console.log(Check);
      if (signature) {
        const CoinAmt = TokenAmount / Number(TokenUSDprice);
        const receiveTokens = await axios.get(
          `${NODE_URL}/apiv2/getMemeTokens?address=${walletAddress}&Token=${selectedToken.symbol}&amount=${Amount}&amountUSD=${TokenAmount}&CoinAmount=${CoinAmt}&CoinAmountUSD=${TokenUSDprice}&TokenHash=${signature}&network=622463&solanaWalletAddress=${solanaWalletAddress}&TokenStatus=1`
        );
        console.log(receiveTokens);
        if (receiveTokens.data.status === "success") {
          toast.success(`${selectedToken.symbol} transaction successful`, {
            id: toastId1,
          });
          toast.success(
            "Your Tokens will be Claimable Once  Presale is Over!...."
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      } else {
        toast.error("Transaction failed", { id: toastId1 });
      }
    } catch (error) {
      toast.error("An unexpected error occurred while during this Transaction");
      console.error("Error performing the transaction:", error);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      console.error("Transfer failed:", error);
    }
  };

  const TransferTRON = async (TRON_ADMIN_ADDRESS, amount) => {
    if (!window.tronWeb) {
      toast.error("Please install Tron wallet.");
      return;
    }
    try {
      const toastId1 = toast.loading("Token transaction in progress", {
        duration: 3000,
      });
      setTimeout(() => {
        toast.dismiss(toastId1);
      }, 2000);

      const amountInSun = window.tronWeb.toSun(amount);
      const transaction = await window.tronWeb.trx.sendTransaction(
        TRON_ADMIN_ADDRESS,
        amountInSun
      );

      const signature = transaction.txid;
      let status;
      if (transaction.result) {
        status = 1;
      } else {
        status = 0;
      }
      const Check = {
        address: address,
        Token: selectedToken.symbol,
        amount: Amount,
        amountUSD: TokenAmount,
        CoinAmount: TokenAmount / Number(TokenUSDprice),
        CoinAmountUSD: TokenUSDprice,
        TokenHash: signature,
        network: "1000",
        solanaWalletAddress: solanaWalletAddress,
        TokenStatus: status,
      };
      console.log(Check);
      if (transaction) {
        const CoinAmt = TokenAmount / Number(TokenUSDprice);
        const receiveTokens = await axios.get(
          `${NODE_URL}/apiv2/getMemeTokens?address=${walletAddress}&Token=${selectedToken.symbol}&amount=${Amount}&amountUSD=${TokenAmount}&CoinAmount=${CoinAmt}&CoinAmountUSD=${TokenUSDprice}&TokenHash=${signature}&network=622463&solanaWalletAddress=${solanaWalletAddress}&TokenStatus=${status}`
        );
        console.log(receiveTokens);
        if (receiveTokens.data.status === "success") {
          toast.success(`${selectedToken.symbol} transaction successful`, {
            id: toastId1,
          });
          toast.success(
            "Your Tokens will be Claimable Once  Presale is Over!...."
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      } else {
        toast.error("Transaction failed", { id: toastId1 });
      }
    } catch (error) {
      toast.error("An unexpected error occurred while during this Transaction");
      console.error("Error performing the transaction:", error);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      console.error("Transfer failed:", error);
    }
  };

  const TransferBTC = async () => {
    try {
      setQrPopup(true);
    } catch (error) {
      console.error("Transfer failed:", error);
    }
  };

  const TransferSOL = async () => {
    try {
      setQrPopup(true);
    } catch (error) {
      console.error("Transfer failed:", error);
    }
  };

  const FetchUserAddress = async () => {
    const response = await axios.get(
      `${NODE_URL}/apiv2/checkAddress?address=${walletAddress}`
    );
    let res =
      response.data.length > 0 ? response.data[0].solanaWalletAddress : null;
    setSolanaWalletAddress(res);
  };

  const FetchPreOrder = async () => {
    try {
      const response = await axios.get(`${NODE_URL}/apiv2/FetchPreOrder`);

      let res = response.data.data[0];
      if (response.status === 200) {
        setPreOrder(res.PreOrder);
        setTokenUSDprice(res.TokenUSDprice);
        setMinQuantity(res.MinAmount);
        setMaxQuantity(res.MaxAmount);
        setPreOrderBalance(
          Number(res.PreOrder) - Number(response.data.totalCoinAmount)
        );
        setPurchased(response.data.totalCoinAmount);
        setPreSaleStatus(res.PresaleStatus);
        let number =
          Number(res.PreOrder) - Number(response.data.totalCoinAmount);
        let cal = (number / res.PreOrder) * 100;
        setPercentage(100 - cal);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    FetchPreOrder();

    const interval = setInterval(() => {
      FetchPreOrder();
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (walletAddress !== null) {
      FetchUserAddress();
    }
  }, [walletAddress]);

  const getSolanaTransaction = async (transactionHash, fromAddress) => {
    const connection = new Connection(
      "https://red-special-sea.solana-mainnet.quiknode.pro/71805dc676a9b32991fd57b913fd3b7b6dff75f2",
      "confirmed"
    );

    let data = await connection.getConfirmedTransaction(transactionHash);
    if (!data || !data.transaction || !data.meta) {
      return { status: "failed", message: "Invalid transaction data" };
    }

    const keys = data.transaction._message.accountKeys;
    const sender = new PublicKey(keys[0]);
    const receiver = new PublicKey(keys[1]);
    console.log(sender.toString(), receiver.toString());
    console.log(fromAddress, adminWallet);

    if (sender.toString() !== fromAddress) {
      return { status: "failed", message: "Sender not found" };
    }
    if (receiver.toString() !== adminWallet) {
      return { status: "failed", message: "Receiver not found" };
    }

    const date = new Date(data.blockTime * 1000);

    let totalTransferred = 0;
    const preBalances = data.meta.preBalances;
    const postBalances = data.meta.postBalances;
    const fee = data.meta.fee;

    for (let i = 0; i < preBalances.length; i++) {
      let diff = preBalances[i] - postBalances[i];
      if (diff > fee) {
        totalTransferred = diff - fee;
        break;
      }
    }
    console.log(totalTransferred, selectedToken.decimals);

    const amountTransferred = totalTransferred / 10 ** selectedToken.decimals;
    console.log(amountTransferred);
    setAmount(amountTransferred);

    return {
      status: "success",
      Date: date,
      Amount: amountTransferred,
    };
  };

  const handleConfirm = async () => {
    try {
      const toastId1 = toast.loading("Token transaction in progress", {
        duration: 5000,
      });

      const result = await axios.get(
        `${NODE_URL}/apiv2/getcheckHash?hash=${transactionHash}`
      );

      console.log(result);

      if (result.data.status === true) {
        let hashData;
        console.log(walletAddressType);

        if (walletAddressType === "BTC") {
          hashData = await getBitcoinTransaction(transactionHash, fromAddress);
        } else if (walletAddressType === "PHANTOM") {
          hashData = await getSolanaTransaction(transactionHash, fromAddress);
        }

        console.log(hashData);

        if (hashData.status === "failed") {
          toast.error(hashData.message, { id: toastId1 });
        } else {
          const Check = {
            address: walletAddress,
            Token: selectedToken.symbol,
            amount: hashData.Amount,
            amountUSD: TokenAmount,
            CoinAmount: TokenAmount / Number(TokenUSDprice),
            CoinAmountUSD: TokenUSDprice,
            TokenHash: transactionHash,
            network: walletAddressType === "BTC" ? "1" : "900",
            solanaWalletAddress: solanaWalletAddress,
            TokenStatus: "success",
          };

          const receiveTokens = await axios.get(
            `${NODE_URL}/apiv2/getMemeTokens?address=${walletAddress}&Token=${selectedToken.symbol
            }&amount=${Check.amount}&amountUSD=${TokenAmount}&CoinAmount=${Check.CoinAmount
            }&CoinAmountUSD=${TokenUSDprice}&TokenHash=${transactionHash}&network=${walletAddressType === "BTC" ? "1" : "900"
            }&solanaWalletAddress=${solanaWalletAddress}&TokenStatus=1`
          );

          if (receiveTokens.data.status === "success") {
            toast.success(`${selectedToken.symbol} transaction successful`, {
              id: toastId1,
            });
            toast.success(
              "Your Tokens will be Claimable Once Presale is Over!"
            );
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        }
      } else {
        toast.error("Transaction already exists", { id: toastId1 });
      }
      setQrPopup(false);
      setConfirmPopup(false);
    } catch (error) {
      toast.error("An unexpected error occurred during this Transaction");
      console.error("Error performing the transaction:", error);
      setQrPopup(false);
      setConfirmPopup(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const getBitcoinTransaction = async (transactionHash, fromAddress) => {
    const url = `https://blockstream.info/testnet/api/tx/${transactionHash}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      if (!data || !data.vin || !data.vout) {
        return { status: "failed", message: "Invalid transaction data" };
      }

      let senderFound = false;
      let receiverFound = false;
      let totalTransferred = 0;

      const inputAddress = data.vin[0].prevout.scriptpubkey_address;
      console.log(inputAddress, fromAddress);
      if (inputAddress === fromAddress) {
        senderFound = true;
      }

      data.vout.forEach((output) => {
        if (output.scriptpubkey_address === adminWallet) {
          receiverFound = true;
          totalTransferred += output.value;
        }
      });

      if (!senderFound) {
        return { status: "failed", message: "Sender not found" };
      }
      if (!receiverFound) {
        return { status: "failed", message: "Receiver not found" };
      }

      const date = new Date(data.status.block_time * 1000);

      const amountTransferred = totalTransferred / 10 ** 8;
      console.log(amountTransferred);
      return {
        status: "success",
        Date: date,
        Amount: amountTransferred,
      };
    } catch (error) {
      return { status: "failed", message: error.message };
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(adminWallet);
    toast.success("Address copied to clipboard!");
  };

  const settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    autoplaySpeed: 9000,
    initialSlide: 1,
  };
  const settings1 = {
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    autoplaySpeed: 5000,
    initialSlide: 1,
  };

  return (
    <div className="font-playfair">
      <div className=" text-white bg-gradient-to-b from-[#8E3EED] to-[#682CFA] overflow-hidden">
        <div className="sec-1  bg-no-repeat bg-cover bg-center relative ">
          <video
            autoPlay
            loop
            muted
            className="absolute inset-0 w-full h-full object-cover"
          >
            <source
              src="/assets/New Project (online-video-cutter.com) (2).mp4"
              type="video/mp4"
            />
          </video>
          <div className="relative z-10 ">
            <Header />
          </div>
          <div className="relative flex flex-col md:flex-row w-[90%] md:w-[85%] mx-auto">
            <div className="flex items-end md:w-[15%]">
              <img src="assets/image 185.png" className="hidden md:block"></img>
            </div>

            <div className=" md:w-[50%]">
              <h1 className="text-[#FFF800] para-text text-center text-3xl md:text-5xl font-snap ITC">
                REAL MEME COIN
              </h1>
              <h2 className="text-xl md:text-2xl mt-3 text-center font-joti One">
                From 1 dollar to a CryptoNation
              </h2>
              <div className="md:w-[90%] 2xl:w-[80%]  mx-auto mb-5 mt-5 relative font-fuzzy px-10 pb-5 pt-5 bg-[#9643eb] rounded-2xl border border-black">
                <div className="flex justify-center">
                  <div className="flex justify-center items-center">
                    <p
                      className="text-2xl font-joti pb-3 text-[#FDE85C] font-semibold"
                      style={{
                        WebkitTextStrokeWidth: "0.75px",
                        WebkitTextStrokeColor: "black",
                      }}
                    >
                      Buy RMC Presale
                    </p>
                  </div>
                </div>
                <Countdown />
                <div>
                  <p className="text-center my-3">Until next price increase</p>
                  <div className='flex justify-between font-medium mb-1'>
                    <p>Total Sales</p>
                    <p>Total Volume</p>
                  </div>

                  <div className="w-full h-2 bg-white rounded">
                    <div
                      className={`h-2 rounded bg-[#C156DD]`}
                      style={{ width: `${Percentage}%` }}
                    ></div>
                  </div>
                  <div className="flex justify-between mt-2 ">
                    <p>{Purchased.toFixed()}</p>
                    <p>{PreOrder}</p>
                  </div>

                  <p className="text-center text-xl text-[#FFF800]">
                    <span className="text-[#FFF800] font-semibold">1 </span>{" "}
                    RMC={" "}
                    <span className="text-[#FFF800] font-semibold">
                      {TokenUSDprice}
                    </span>{" "}
                    USD
                  </p>

                  <p className="">Select Token</p>
                  <div className="relative">
                    <div
                      className={`cursor-pointer py-2 px-5 w-full rounded-xl text-black flex justify-between items-center ${selectedIndex !== null
                        ? "bg-white  brightness-100"
                        : `bg-white ${isAnyTokenSelected
                          ? "brightness-50"
                          : "brightness-100"
                        }`
                        }`}
                      onClick={handleTokenDropdown}
                    >
                      <div className="flex items-center">
                        {selectedToken ? (
                          <>
                            <img
                              src={selectedToken.img}
                              alt={selectedToken.symbol}
                              className="md:w-8 md:h-8 w-5 h-5 border-none"
                            />
                            <div className="ml-3">
                              <p className="text-center font-bold text-lg">
                                {selectedToken.symbol}
                              </p>
                            </div>
                          </>
                        ) : (
                          <p className="text-center  text-lg">Choose Token</p>
                        )}
                      </div>

                      <i
                        className="fa-solid fa-chevron-down"
                        style={{ color: "black" }}
                      ></i>
                    </div>

                    {TokenDropdown && (
                      <div className="absolute z-30 bg-white border text-black rounded-lg overflow-hidden border-black/50 left-0 w-full h-[200px] overflow-y-auto custom-scrollbar1">
                        {TokenTotal.length > 0 ? (
                          TokenTotal.map((data, index) => (
                            <div
                              key={index}
                              className={`px-5 py-2 rounded-2xl hover:bg-black/20`}
                            >
                              <div
                                className="flex items-center gap-5 cursor-pointer "
                                onClick={() => choseTokens(index, data)}
                              >
                                <img
                                  src={data.img}
                                  className="w-5 h-5"
                                  alt={`Dropdown item ${data.symbol}`}
                                />
                                <p className="text-sm">{data.symbol}</p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="px-5 py-2 text-sm text-gray-400">
                            Select the Network
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col md:flex-row gap-5 mt-3">
                    <div className="md:w-[50%]">
                      <p className="">Select Network</p>
                      <div className="relative inline-block w-full">
                        <div
                          className="cursor-pointer py-2 px-3 w-full flex justify-between items-center bg-white border rounded-xl"
                          onClick={handleNetworkDropdown}
                        >
                          <div className="flex items-center">
                            {selectedNetwork?.img ? (
                              <>
                                <img
                                  src={selectedNetwork.img}
                                  className="w-6 h-6"
                                  alt={`${selectedNetwork.name} icon`}
                                />
                                <div className="ml-3">
                                  <p className="text-center text-black font-bold text-lg">
                                    {selectedNetwork.name}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <p className="text-center text-black text-lg">
                                Choose Network
                              </p>
                            )}
                          </div>
                          <i
                            className="fa-solid fa-chevron-down"
                            style={{ color: "black" }}
                          ></i>
                        </div>

                        {NetworkDropdown && (
                          <div className="absolute z-20 bg-white border text-black rounded-lg border-black/50 left-0 w-full">
                            {filteredNetworks.map((data, index) => (
                              <div
                                key={index}
                                className={`px-5 py-2 hover:bg-black/20 `}
                              >
                                <div
                                  className="flex items-center gap-5 cursor-pointer"
                                  onClick={() => choseNetworkList(index, data)}
                                >
                                  <img
                                    src={data.img}
                                    className="w-5 h-5"
                                    alt={`Dropdown item ${data.name}`}
                                  />
                                  <p className="text-sm">{data.name}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <p className=" mt-3">
                        You Receive:{" "}
                        <span>
                          {TokenAmount
                            ? (TokenAmount / Number(TokenUSDprice)).toFixed(2)
                            : TokenUSDprice}{" "}
                          <span className="font-normal">RMC</span>
                        </span>
                      </p>
                    </div>
                    <div className="md:w-[50%]">
                      <p className="">Enter Quantity</p>
                      <input
                        className="w-[100%] mb-3 text-lg bg-white px-3 py-2 rounded-xl text-black  outline-none placeholder:text-black"
                        placeholder="0"
                        onChange={(e) => handleInputChange(e)}
                      ></input>
                      {Text === true && (
                        <span className="text-[#F9B44F] ">
                          The amount entered should be less than the maximum.
                        </span>
                      )}
                    </div>
                  </div>


                </div>
                <div className="flex justify-center mt-5">
                  <a className="bg-gradient-to-r from-[#37159F] to-[#C156DD] flex items-center justify-center border text-white border-black rounded-md shadow-sm shadow-black w-40 h-10 ">
                    <button
                      className="text-white font-Lacquer"
                      onClick={Transfer}
                    >
                      Buy Now
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="md:w-[35%] flex items-end">
              <div>
                <img className="dog" src="/assets/bigdog.png"></img>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="hidden md:block">
          <div className="bg-gradient-to-b from-[#8E3EED] to-[#682CFA] border-y border-black flex justify-around py-5 px-5 md:px-10">
            <div className="md:w-[15%]">
              <h4 className="text-white lg:text-lg text-base font-serif font-semibold">
                REAL MEME COIN
              </h4>
            </div>
            <div className="md:w-[15%]">
              <h4 className="text-white lg:text-lg text-base font-serif font-semibold">
                REAL MEME COIN
              </h4>
            </div>
            <div className="md:w-[15%]">
              <h4 className="text-white lg:text-lg text-base font-serif font-semibold">
                REAL MEME COIN
              </h4>
            </div>
            <div className="md:w-[15%]">
              <h4 className="text-white lg:text-lg text-base font-serif font-semibold">
                REAL MEME COIN
              </h4>
            </div>
          </div>
        </div>
        <div className="block md:hidden bg-gradient-to-b from-[#8E3EED] to-[#682CFA] border-y border-black">
          <div className=" flex justify-around py-5 px-5 md:px-10">
            <div className="w-[40%]">
              <h4 className="text-white  md:text-base text-sm font-serif font-semibold">
                REAL MEME COIN
              </h4>
            </div>
            <div className="w-[40%]">
              <h4 className="text-white md:text-base text-sm font-serif font-semibold">
                REAL MEME COIN
              </h4>
            </div>
          </div>
          <div className="flex justify-around py-5 px-5 md:px-10">
            <div className="w-[40%]">
              <h4 className="text-white md:text-base text-sm font-serif font-semibold">
                REAL MEME COIN
              </h4>
            </div>
            <div className="w-[40%]">
              <h4 className="text-white  md:text-base text-sm font-serif font-semibold">
                REAL MEME COIN
              </h4>
            </div>
          </div>
        </div>

        <div
          className="sec-2  bg-no-repeat   relative  mt-2"
          style={{ backgroundImage: "url('')" }}
        >
          <div className="flex flex-col gap-2 lg:flex-row items-center  ">
            <div className="lg:-translate-x-8 2xl:mt-2">
              <img className="dog" src="/assets/Group 2.png"></img>
            </div>
            <div className="md:w-[70%] grid content-center ">
              <div className=" ">
                <p className="text-2xl md:text-8xl md:text-left text-center text-[#FFF800] second-head font-joti One">
                  Enjoy the Billionaire's Lifestyle!
                </p>
                <p className="mt-7 md:text-4xl font-fuzzy Bubbles md:text-left text-center  md:w-[75%] w-[90%] md:mx-0 mx-auto">
                  Meet GoldPaw, who embodies the luxourious lifestyle that
                  RealMEME Coin offers.
                </p>
                <div className="my-8 flex justify-center md:justify-start">
                  <a
                    href="/assets/REAL  MEME  COIN WHITE PAPER.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className=" bg-gradient-to-r from-[#37159F] to-[#C156DD] border text-white border-black rounded-md shadow-sm shadow-black w-40 h-10 font-Lacquer font-bold">
                      Whitepaper
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sec-3 md:py-20 py-12">
          <h1 className="md:text-2xl font-syne font-bold mx-auto w-[75%] text-center">
            Real Meme Coin aims to deliver a rewarding and luxurious experience
            for it's investors.
          </h1>
          <div className="w-[95%] mx-auto flex flex-col md:flex-row flex-wrap  items-center md:justify-between justify-around mt-20">
            <div className="lg:w-[23%] md:w-[40%] sm:w-[85%] w-full border bg-gradient-to-b from-[#C156DDB2] to-[#5C27FEB2] mb-12 h-[350px]">
              <div className="flex justify-center h-20 relative">
                <img
                  src="/assets/realworld-dog.png"
                  className="mx-auto  rounded-full -translate-y-10 absolute w-[7rem]"
                ></img>
              </div>
              <h1 className="uppercase text-center bg-white  text-base text-black font-bold py-2 font-fuzzy Bubbles">
                Earnings
              </h1>
              <p className="w-[90%] py-5 text-sm mx-auto text-center font-fuzzy Bubbles ">
                Investors can stake their RMC tokens to earn rewards. They can
                also provide liquidity to decentralized exchanges (DEXs) and
                earn transaction fees.
              </p>
            </div>
            <div className="lg:w-[23%] md:w-[40%] sm:w-[85%] w-full border bg-gradient-to-b from-[#C156DDB2] to-[#5C27FEB2] mb-12 h-[350px]">
              <div className="flex justify-center h-20 relative">
                <img
                  src="/assets/asset-dog.png"
                  className="mx-auto  rounded-full -translate-y-10 absolute w-[7rem]"
                ></img>
              </div>
              <h1 className="uppercase text-center bg-white  text-base text-black font-bold py-2 font-fuzzy Bubbles">
                Real World Assets
              </h1>
              <p className="w-[90%] py-5 text-sm mx-auto text-center font-fuzzy Bubbles">
                RMC Investors have access to a marketplace, to trade tokenized
                real-world assets and special investment opportunities providing
                access to high-value properties, luxury items, and more.s
              </p>
            </div>
            <div className="lg:w-[23%] md:w-[40%] sm:w-[85%] w-full border bg-gradient-to-b from-[#C156DDB2] to-[#5C27FEB2] mb-12 h-[350px]">
              <div className="flex justify-center h-20 relative">
                <img
                  src="/assets/earning-dog.png"
                  className="mx-auto h-[114px] rounded-full -translate-y-10 absolute "
                ></img>
              </div>
              <h1 className="uppercase text-center bg-white  text-base text-black font-bold py-2 font-fuzzy Bubbles">
                rewards and Governance
              </h1>
              <p className="w-[90%] py-5 text-sm  mx-auto text-center font-fuzzy Bubbles">
                Periodic airdrops and bonuses are distributed to active RMC
                Investors as rewards. RMC Investors will also have voting rights
                on key decisions regarding the development and direction of the
                RealMEME ecosystem.
              </p>
            </div>
            <div className="lg:w-[23%] md:w-[40%] sm:w-[85%] w-full border bg-gradient-to-b from-[#C156DDB2] to-[#5C27FEB2] mb-12 h-[350px]">
              <div className="flex justify-center h-20 relative">
                <img
                  src="/assets/lifestyle-dog.png"
                  className="mx-auto h-[108px] w-[106px] rounded-full -translate-y-10 absolute "
                ></img>
              </div>
              <h1 className="uppercase text-center bg-white text-base text-black font-bold py-2 font-fuzzy Bubbles">
                Lifestyle Benefits
              </h1>
              <p className="w-[90%] py-5 text-sm  mx-auto text-center font-fuzzy Bubbles">
                Exclusive access to events, luxury goods, and services,
                including private investment opportunities.
              </p>
            </div>
          </div>
        </div>

        <div className="pt-12 relative w-[80%] mx-auto flex items-center md:flex-row flex-col">
          <div className="md:w-[50%] w-full flex items-center justify-center text-center">
            <h3 className="text-[#68E0DE] tap-head md:text-[85px] text-4xl font-snap leading-[5rem]">
              TAP TO <br /> EARN
            </h3>
          </div>
          <div
            className="md:w-[50%] w-full bg-no-repeat bg-center md:bg-cover bg-contain  2xl:h-[600px] lg:h-[450px] xl:h-[450px]"
            style={{ backgroundImage: "url('.')" }}
          >
            <div className="flex flex-col z-10 mt-10">
              <img
                className="w-9/12  lg:-translate-y-[150px] "
                src="/assets/coming-soon.png"
              />

              <img
                className="  lg:-translate-y-[250px]"
                src="/assets/image 184.png"
              ></img>
            </div>
          </div>
        </div>

        <div className="sec-4 bg-[#C156DDF7] relative   ">
          <div className="flex flex-col md:flex-row md:w-[90%]  mx-auto">
            <div className=" md:w-[35%] relative">
              <img
                src="/assets/image 182.png"
                className="md:block hidden absolute lg:scale-125 2xl:top-[-160px] lg:top-[-75px] left-0"
              />
            </div>
            <div className=" md:w-[65%] md:pl-20">
              <div className="">
                <div className="w-[90%] md:w-[65%] mx-auto ">
                  <p className="font-fuzzy Bubbles text-center  md:text-left lg:text-3xl md:text-xl 2xl:text-4xl text-lg py-7">
                    RMC offers its Investors a taste of the billionaire
                    lifestyle, making high-value investments and luxury living
                    accessible at a cost.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sec-5 mb-10 md:mt-40 py-12">
          <div>
            <h1 className="text-2xl md:text-4xl text-center font-snap ITC text-[#FDE85C] second-head">
              HOW TO BUY ?
            </h1>
          </div>
          <div
            className="relative w-[95%] md:w-[75%] mx-auto flex justify-center  mt-5"
            style={{ backgroundImage: "url('')" }}
          >
            <div className="bg-[#ac4ce4] rounded-2xl py-10 px-5 md:px-8 z-10 bg-opacity-60">
              <div className="flex flex-col gap-4">
                <div>
                  <p className="font-joti text-[#FFF800] text-xl">
                    1. CONNECT YOUR CRYPTO WALLET
                  </p>
                </div>
                <div className="px-5">
                  <li className="text-white font-fuzzy ">
                    Click on "Connect Wallet": Usually located at the top right
                    corner. Select Wallet: Choose either MetaMask or Phantom.
                    Authorize Connection: Follow the prompts to authorize the
                    connection.
                  </li>
                  <li className="text-white font-fuzzy ">
                    Select Wallet: Choose either MetaMask or Phantom.
                  </li>
                  <li className="text-white font-fuzzy ">
                    Authorize Connection: Follow the prompts to authorize the
                    connection.
                  </li>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div>
                  <p className="font-joti text-[#FFF800] text-xl">
                    2. Select Payment Method
                  </p>
                </div>
                <div className="px-5">
                  <li className="text-white font-fuzzy ">
                    Choose Cryptocurrency: Select the cryptocurrency you want to
                    use for purchasing RMC tokens.
                  </li>
                  <li className="text-white font-fuzzy ">
                    Note: To purchase using Solana, you will need a
                    Solana-compatible wallet like Phantom.
                  </li>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div>
                  <p className="font-joti text-[#FFF800] text-xl">
                    3. Enter Purchase Amount
                  </p>
                </div>
                <div className="px-5">
                  <li className="text-white font-fuzzy ">
                    Specify Amount: Input the amount of cryptocurrency you want
                    to spend.
                  </li>
                  <li className="text-white font-fuzzy ">
                    Review Token Amount: Check the number of tokens you'll
                    receive.
                  </li>
                  <li className="text-white font-fuzzy ">Press ‘Buy Now’</li>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div>
                  <p className="font-joti text-[#FFF800] text-xl">
                    4. Complete the Purchase
                  </p>
                </div>
                <div className="px-5">
                  <li className="text-white font-fuzzy ">
                    Confirm Transaction: Review all details and confirm the
                    transaction in your wallet. Wait for Confirmation: Allow a
                    few moments for the transaction to be processed and
                    confirmed on the blockchain.
                  </li>
                  <li className="text-white font-fuzzy ">
                    Wait for Confirmation: Allow a few moments for the
                    transaction to be processed and confirmed on the blockchain.
                  </li>
                </div>
              </div>
            </div>
            <div className="absolute right-[42%] bottom-[0.1px]">
              <img src="/assets/image 193.png"></img>
            </div>
          </div>
        </div>
        <div>
          <p className="text-center text-white text-xl font-fuzzy">
            <span className="text-[#FDE85C] font-bold">Disclaimer:</span> “MEME
            COINS are subject to market risks, DYOR before investing”
          </p>
        </div>
        {/* tokenamics section */}
        <div className="md:py-10 py-4 w-[98%] mx-auto sec-6">
          {/* <h2 className="text-2xl md:text-4xl text-center font-chewy ITC text-[#FDE85C] second-head ">
            TOKENOMICS
          </h2> */}
          <div className=" w-full flex justify-center">
            <img src="/assets/Group 1597883170.png"></img>
          </div>
        </div>
        <div className="sec-7 py-10">
          <h1 className="text-2xl md:text-4xl text-center second-head text-[#FDE85C] font-snap ITC">
            The Master Plan
          </h1>
          <div className="w-[90%] mt-10 mx-auto flex flex-col md:flex-row gap-10 md:gap-0">
            <div className="md:w-[50%] ">
              <div className="bg-[#C156DDF5] md:w-[80%] mx-auto  p-10 rounded-xl border">
                <div className="flex justify-between relative">
                  <p className="text-2xl md:text-4xl text-center font-joti font-extrabold text-black asset-head">
                    Real-World <br /> Assets
                  </p>
                  <div className="">
                    <img
                      src="/assets/image 186.png"
                      className=" w-[30%] md:w-[30%] absolute right-0 -top-14"
                    ></img>
                  </div>
                </div>
                <p className="mt-5 md:text-2xl font-fuzzy Bubbles text-center md:w-[85%] mx-auto md:leading-8 text-white">
                  Real Meme Coin allows users to use real-world assets such as
                  real estate, jets, superyachts and more, making them easily
                  accessible.
                </p>
              </div>
            </div>
            <div className="md:w-[50%] ">
              <p className="text-2xl md:text-4xl text-center mt-10 font-joti font-extrabold text-black asset-head">
                Global Reach
              </p>
              <p className="mt-10 md:text-2xl font-fuzzy text-center md:w-[75%] mx-auto md:leading-8 text-white">
                The GoldPaw, travels around the world, showcasing different
                assets and landmarks, emphasizing the coin's international
                presence and appeal.
              </p>
            </div>
          </div>
        </div>
        {/* About us section */}

        <div className="py-10 sec-8 md:w-[85%] w-[95%] mx-auto relative">
          <div className="absolute inset-0 z-0">
            <img
              className="absolute -right-24 top-0 "
              src="/assets/Vector 29.png"
              alt="Vector"
            />
          </div>
          <div className="relative z-10">
            <span className="flex items-center justify-center">
              <img
                src="/assets/image 184.png"
                className="w-[200px] md:w-[280px]"
              />
            </span>
            <div className="bg-[#C156DD] border border-white p-8 rounded-lg">
              <h2
                className="my-4 text-xl md:text-3xl font-joti One text-[#FFF800] w-[50%] mx-auto text-center"
                style={{
                  WebkitTextStrokeColor: "black",
                  WebkitTextStrokeWidth: "0.5px",
                }}
              >
                About us
              </h2>
              <p className="text-center lg:w-[75%] md:w-[85%] sm:w-[95%] w-full mx-auto text-white font-fuzzy Bubbles my-4 lg:text-xl sm:text-lg text-base ">
                RealMEME Coin (RMC) is an innovative cryptocurrency that blends
                the fun and engaging aspects of meme culture with the tangible
                value of real-world assets. The RMC is designed to provide both
                entertainment and real-world utility, creating a unique
                opportunity for crypto enthusiasts and traditional investors
                alike.
              </p>

              <div className="border border-white rounded-lg bg-[#FFFFFF66] sm:px-6 px-4 pt-6">
                <div className="flex items-center justify-center sm:flex-row flex-col">
                  <div className="sm:w-[45%] md:w-[35%] w-full ">
                    <img src="/assets/image 193.png"></img>
                  </div>
                  <div className="md:w-[65%] sm:[55%] w-full md:px-6 px-2">
                    <p className="font-fuzzy Bubbles lg:text-2xl sm:text-lg text-base text-black my-4">
                      Meet GoldPaw, the coolest dog in the crypto jungle! With his
                      bling, shades, and swag, GoldPaw is here to show you how to
                      live the billionaire's life while keeping things fun and
                      fabulous. He's also leading the charge in building the
                      CryptoNation, our very own independent state!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sec-9 py-10 w-[100%] mx-auto">
          <h1 className="text-2xl md:text-4xl text-center second-head text-[#FDE85C] font-snap ITC">
            Story: The Adventures of GoldPaw
          </h1>
          <div className="bg-[#c156dd] w-[100%] h-[50px] mt-10"></div>
          <div className="lg:block hidden">
            <div className="flex justify-center mt-10 ">
              <div className=" w-[85%] ">
                <Slider  {...settings}>
                  <div>
                    <div className="carousel-card-container flex items-end">
                      <div className="w-[33%]  carousel-card  rounded-3xl ">
                        <div className="card-header flex flex-wrap items-center justify-center">
                          <div className="">
                            <img src="/assets/image 193.png" className="" />
                          </div>
                        </div>
                        <div className="bg-[#c156dd]"></div>

                        <div className=" bg-[#c156dd] px-2 h-[344.51px]  border-t border-b">
                          <h1 className="text-center font-atma text-black font-bold mt-2">
                            Chapter 1: The Genesis of Wealth
                          </h1>

                          <p className="text-center font-fuzzy text-white pt-5">
                            In the heart of CryptoVille, where the digital
                            currency revolution thrives, GoldPaw lives. He
                            exudes a charismatic charm and an air of affluence,
                            always dressed in the finest gold chains and
                            designer sunglasses. His journey began with a vision
                            to blend the whimsical world of memes with the
                            tangible value of real-world assets, thus giving
                            birth to RealMEME Coin (RMC).
                          </p>
                        </div>
                      </div>
                      <div className="w-[33%] carousel-card ounded-3xl ">
                        <div className="card-header flex flex-wrap items-center justify-center">
                          <div className="">
                            <img src="/assets/image 185.png" className="" />
                          </div>
                        </div>
                        <div className="bg-[#c156dd]"></div>

                        <div className=" bg-[#c156dd] px-2  h-[344.51px]  border-t border-b">
                          <h1 className="text-center font-atma text-black font-bold mt-2">
                            Chapter 2: The Quest for Treasure
                          </h1>

                          <p className="text-center font-fuzzy text-white pt-5">
                            {" "}
                            GoldPaw set out on a grand adventure, traveling
                            across the globe to gather valuable assets. With
                            every new asset acquired, GoldPaw's legend grew,
                            attracting investors eager to join his journey.
                          </p>
                        </div>
                      </div>
                      <div className="w-[33%] carousel-card  rounded-3xl ">
                        <div className="card-header flex flex-wrap items-center justify-center">
                          <div className="">
                            <img src="/assets/image 211.png" className="" />
                          </div>
                        </div>
                        <div className="bg-[#c156dd]"></div>

                        <div className=" bg-[#c156dd] px-2  h-[344.51px] border-t border-b">
                          <h1 className="text-center font-atma text-black font-bold mt-2">
                            Chapter 3: Building an Empire
                          </h1>

                          <p className="text-center font-fuzzy text-white pt-5">
                            Next, GoldPaw negotiated deals to acquire prime
                            real estate, bringing the world of high finance into
                            the realm of digital currency.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="carousel-card-container flex items-end">
                      <div className="w-[33%]  carousel-card  rounded-3xl ">
                        <div className="card-header flex flex-wrap items-center justify-center">
                          <div className="">
                            <img src="/assets/image 212.png" className="h-[345px]" />
                          </div>
                        </div>
                        <div className="bg-[#c156dd]"></div>

                        <div className=" bg-[#c156dd] px-2 h-[344.51px]  border-t border-b">
                          <h1 className="text-center font-atma text-black font-bold mt-2">
                            Chapter 4: The Collector's Dream
                          </h1>

                          <p className="text-center font-fuzzy text-white pt-5">
                            GoldPaw's adventures led him to the serene temples
                            of Kyoto, where he discovered rare artifacts and
                            collectibles. These treasures were added to the
                            RealMEME ecosystem, further increasing the coin's
                            value and appeal.
                          </p>
                        </div>
                      </div>
                      <div className="w-[33%] carousel-card ounded-3xl ">
                        <div className="card-header flex flex-wrap items-center justify-center">
                          <div className="">
                            <img
                              src="/assets/RMC_DG_01.png"
                              className=" h-[345px]"
                            />
                          </div>
                        </div>
                        <div className="bg-[#c156dd]"></div>

                        <div className=" bg-[#c156dd] px-2  h-[344.51px]  border-t border-b">
                          <h1 className="text-center font-atma text-black font-bold mt-2">
                            Chapter 5: The Global Network
                          </h1>

                          <p className="text-center font-fuzzy text-white pt-5">
                            {" "}
                            From the vineyards of France to the beaches of
                            Brazil, RealMEME Coin became synonymous with
                            innovation and prosperity. Investors from all
                            corners of the globe joined the adventure,
                            contributing to the growth and success of the coin.
                          </p>
                        </div>
                      </div>
                      <div className="w-[33%] carousel-card  rounded-3xl ">
                        <div className="card-header flex flex-wrap items-center justify-center">
                          <div className="">
                            <img src="/assets/image 181.png" className="" />
                          </div>
                        </div>
                        <div className="bg-[#c156dd]"></div>

                        <div className=" bg-[#c156dd] px-2  h-[344.51px] border-t border-b">
                          <h1 className="text-center font-atma text-black font-bold mt-2">
                            Epilogue: The Legacy Continues
                          </h1>

                          <p className="text-center font-fuzzy text-white pt-5">
                            GoldPaw's journey is far from over. As RealMEME
                            Coin continues to grow, so does the community of
                            investors who believe in the vision of blending
                            digital assets with real-world value.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-10 block lg:hidden">
            <div className="w-[85%] ">
              <Slider {...settings1}>
                <div className="w-[25%] bg-[#c156dd]">
                  <div>
                    <div className="bg-[#7834f5] flex justify-center">
                      <img
                        className="w-[61%]"
                        src="/assets/image 193.png"
                      ></img>
                    </div>
                    <div className="bg-[#c156dd] border-t border-b py-10 px-5">
                      <p className="text-center font-atma text-black font-bold">
                        Chapter 1: The Genesis of Wealth
                      </p>
                      <p className="text-center 2xl:text-[14px] lg:text-xs font-fuzzy text-white pt-5">
                        In the heart of CryptoVille, where the digital currency
                        revolution thrives, GoldPaw lives. He exudes a
                        charismatic charm and an air of affluence, always
                        dressed in the finest gold chains and designer
                        sunglasses. His journey began with a vision to blend the
                        whimsical world of memes with the tangible value of
                        real-world assets, thus giving birth to RealMEME Coin
                        (RMC).
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[25%]  bg-[#c156dd]">
                  <div className="">
                    <div className="bg-[#7834f5] flex justify-center">
                      <img
                        className="w-[74%]"
                        src="/assets/image 185.png"
                      ></img>
                    </div>
                    <div className="bg-[#c156dd] border-t border-b py-10 px-5 ">
                      <p className="text-center font-atma text-black font-bold">
                        Chapter 2: The Quest for Treasure
                      </p>
                      <p className="text-center  font-fuzzy text-white pt-5">
                        GoldPaw set out on a grand adventure, traveling across
                        the globe to gather valuable assets. With every new
                        asset acquired, GoldPaw legend grew, attracting
                        investors eager to join his journey.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[25%] bg-[#c156dd]">
                  <div>
                    <div className="bg-[#7834f5] flex justify-center">
                      <img
                        className="w-[73%]"
                        src="/assets/image 211.png"
                      ></img>
                    </div>
                    <div className="bg-[#c156dd] border-t border-b py-10 px-5">
                      <p className="text-center font-atma text-black font-bold">
                        Chapter 3: Building an Empire
                      </p>
                      <p className="text-center text-xl  font-fuzzy text-white pt-5 2xl:pb-[14px] lg:pb-[5px]">
                        Next, GoldPaw negotiated deals to acquire prime real
                        estate, bringing the world of high finance into the
                        realm of digital currency.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[25%] bg-[#c156dd]">
                  <div>
                    <div className="bg-[#7834f5] flex justify-center">
                      <img
                        className="w-[61%]"
                        src="/assets/image 193.png"
                      ></img>
                    </div>
                    <div className="bg-[#c156dd] border-t border-b py-10 px-5">
                      <p className="text-center font-atma text-black font-bold">
                        Chapter 4: The Collector's Dream
                      </p>
                      <p className="text-center2xl:text-[14px] lg:text-xs font-fuzzy text-white pt-5">
                        GoldPaw's adventures led him to the serene temples
                        of Kyoto, where he discovered rare artifacts and
                        collectibles. These treasures were added to the
                        RealMEME ecosystem, further increasing the coin's
                        value and appeal.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[25%] bg-[#c156dd]">
                  <div>
                    <div className="bg-[#7834f5] flex justify-center">
                      <img
                        className="w-[74%]"
                        src="/assets/image 185.png"
                      ></img>
                    </div>
                    <div className="bg-[#c156dd] border-t border-b py-10 px-5">
                      <p className="text-center font-atma text-black font-bold">
                        Chapter 5: The Global Network
                      </p>
                      <p className="text-center font-fuzzy text-white pt-5">
                        From the vineyards of France to the beaches of
                        Brazil, RealMEME Coin became synonymous with
                        innovation and prosperity. Investors from all
                        corners of the globe joined the adventure,
                        contributing to the growth and success of the coin.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[25%] bg-[#c156dd]">
                  <div>
                    <div className="bg-[#7834f5] flex justify-center">
                      <img
                        className="w-[73%]"
                        src="/assets/image 211.png"
                      ></img>
                    </div>
                    <div className="bg-[#c156dd] border-t border-b py-10 px-5">
                      <p className="text-center font-atma text-black font-bold">
                        Epilogue: The Legacy Continues
                      </p>
                      <p className="text-center text-xl  font-fuzzy text-white pt-5 2xl:pb-[14px] lg:pb-[5px]">
                        GoldPaw's journey is far from over. As RealMEME
                        Coin continues to grow, so does the community of
                        investors who believe in the vision of blending
                        digital assets with real-world value.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className="px-5">
            <p className="text-white text-center pt-10 text-xl">
              <span className="text-[#64ffff]">Disclaimer:&nbsp;</span>MEME
              COINS are subject to market risks, DYOR before investing.{" "}
            </p>
          </div>
        </div>
        <Footer />
      </div>

      {qrPopup && (
        <div className=" z-50 fixed inset-0 flex items-center  justify-center backdrop-blur-md bg-black/50">
          <div className="p-6 rounded-lg shadow-lg w-11/12 max-w-4xl border bg-[#8944b0] flex text-white">
            <div className="flex-1 py-8 flex items-center justify-center">
              <QRCode
                className="border-4 rounded-xl"
                value={adminWallet}
                size={200}
              />
            </div>
            <div className="flex-1 p-4 pl-6 flex flex-col justify-center items-center">
              <div className="flex items-center mb-4">
                <span className=" mr-2  text-bold text-md text-center">
                  {adminWallet}
                </span>
                <ClipboardDocumentIcon
                  className="w-5 h-5  cursor-pointer hover:text-gray-800 transition"
                  onClick={handleCopy}
                  title="Copy to clipboard"
                />
              </div>
              <div className=" mb-6 text-center">
                <p className="mb-1">
                  Amount: <span className="font-bold">{Amount}</span>
                </p>
                <p>
                  Token Name:{" "}
                  <span className="font-bold">
                    {selectedToken ? selectedToken.symbol : "USDT"}
                  </span>
                </p>
              </div>
              <div className="flex mt-6 justify-between w-full max-w-xs">
                <button
                  className="bg-[#15bffd] text-white px-4 py-2 rounded-lg shadow-md transition w-full mr-2"
                  onClick={() => setConfirmPopup(true)}
                >
                  Paid
                </button>
                <button
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg shadow-md hover:bg-gray-400 transition w-full ml-2"
                  onClick={() => setQrPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {confirmPopup && (
        <div className=" z-50 fixed inset-0 flex items-center justify-center  p-2  backdrop-blur-md bg-black/50">
          <div className="relative w-full md:w-[50%] xl:w-[45%]  p-6 rounded-xl bg-[#8944b0] shadow-lg  max-w-xlg">
            <button
              className="absolute text-2xl top-4 right-4 text-[#57345D] hover:text-gray-900 transition"
              onClick={() => {
                setQrPopup(false);
                setConfirmPopup(false);
              }}
            >
              &times;
            </button>
            <h2 className="text-black text-lg font-semibold mb-4 text-center">
              Confirm Payment
            </h2>
            <div className="mt-4 ">
              <label className="text-white text-lg font-semibold">
                Enter the wallet Address
              </label>
              <input
                type="text"
                placeholder="From Address"
                value={fromAddress}
                onChange={(e) => setFromAddress(e.target.value)}
                className="border-2 border-[#57345D] p-2 w-full mt-1 rounded-lg mb-2 focus:outline-none transition"
              />
            </div>
            <div className="mt-4 ">
              <label className="text-white text-lg font-semibold">
                Enter the Transaction Hash
              </label>
              <input
                type="text"
                placeholder="Transaction Hash"
                value={transactionHash}
                onChange={(e) => setTransactionHash(e.target.value)}
                className="border-2 border-[#57345D] p-2 w-full rounded-lg  mt-1 mb-4 focus:outline-none transition"
              />
            </div>
            <div className="flex justify-center">
              <button
                className="px-4 py-2 bg-[#15bffd] rounded-lg shadow-lg mt-4 text-white hover:bg-[#D93D57] transition"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
