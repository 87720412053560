import React from 'react'
import Header from './Header'

export default function Stake() {
    return (
        <div className='font-Suwan bg-[#622FC0] h-screen overflow-hidden overflow-y-auto'>
            <Header />
            <div className=' text-white py-10 flex justify-center items-center 2xl:h-[80vh] mt-10 '>
                <div className='flex flex-col md:flex-row gap-10 w-[90%] mx-auto z-10'>
                    <div className='w-[50%] bg-[#291A58]/65 p-7 rounded-2xl flex gap-2'>
                        <div className='w-[5%] '>
                            <div className='bg-black rounded-full w-6 h-6 text-xs flex items-center justify-center mx-auto'>1</div>
                            <div className='h-[77%] mx-auto w-1 bg-white/50'></div>
                            <div className='bg-black rounded-full w-6 h-6 text-xs flex items-center justify-center mx-auto'>2</div>
                        </div>
                        <div className='w-[95%]'>
                            <div className='flex justify-between '>
                                <p className='font-bold text-xl'>Staking</p>
                                <p className='text-end text-black my-auto'>Timeline Updates</p>
                            </div>
                            <p className='flex justify-between mt-2 '><p>Starts</p><p>2024-10-03 16:30</p></p>
                            <p className='flex justify-between mt-2'><p>Ends (Estimated)</p><p>2024-10-04 14:00</p></p>
                            <div className='w-[90%] mx-auto p-5 bg-white/30 rounded-xl my-5'>
                                <p className='text-black font-bold text-lg'>Annual Percentage Rate:</p>
                                <p className='my-5'>Estimated Rewards <br /> (All Chains)</p>
                                <p className='flex justify-between text-black'><p>Total Staked In this Pool</p><p className='font-bold my-auto'>2.291</p></p>
                            </div>
                            <div className='flex justify-between'>
                                <div>
                                    <p className='font-bold text-xl'>Claim</p>
                                    <p className='mt-2'>Starts</p>
                                </div>
                                <div>
                                    <div className='flex justify-center'><button className=' px-5 py-1 rounded-full bg-white text-black border border-black shadow-md shadow-black/50'>confirm</button></div>
                                    <p className='mt-2 text-xs'>Estimated: <span> 2024-10-04 16:20</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-[50%] bg-[#291A58]/65 p-7 rounded-2xl grid content-center'>
                        <p className='font-bold text-xl text-center'>Staking Ends In</p>
                        <p className='text-xs text-center mt-2'>00d : 00h : 22m : 11s</p>
                        <div className='mt-10 w-[80%] mx-auto '>
                            <div className='p-5 bg-white rounded-t-2xl  text-black flex justify-between'>
                                <p className='font-bold text-lg'>WETH</p>
                                <p className='text-sm my-auto'>Balance : <span>0</span></p>
                            </div>
                            <div className='p-5 bg-white/50 rounded-b-2xl text-black flex justify-between'>
                                <p className='text-[#5C27FE] bg-white px-3 py-1 rounded-lg font-bold text-sm'>MAX</p>
                                <p className='text-sm my-auto'>0</p>
                            </div>
                            <input className='mt-5 w-[100%] py-2 px-5 outline-none rounded-xl bg-white text-black border border-black shadow-sm shadow-black placeholder:text-black ' placeholder='Enter an amount'></input>
                        </div>
                    </div>
                </div>
                <div className='background shading bg-[#C156DD]/60 blur-3xl p-5 absolute w-full h-[90%] z-0 '></div>
            </div>
            <div className='w-full modal-overlay fixed inset-0 absolute top-[90px] z-10 bg-black bg-opacity-30 backdrop-blur-xl '>
                    <div className='flex justify-center pt-32'>
                       <img className='lg:w-4/12' src='/assets/coming-soon.png'></img>
                   </div>
                </div> 
        </div>
    )
}