import React from 'react'

export default function TokenSale() {
    return (
        <div className='bg-[#37159F] bg-cover bg-center bg-no-repeat relative flex items-center' >
            <div className='w-full z-10 h-screen py-10 overflow-hidden overflow-y-auto font-syne text-white '>
                <div className='p-3 py-5 sm:p-6 2xl:p-8  rounded-2xl border-[5px] border-white/40 bg-black/50 w-[90%] md:w-[60%] lg:w-[40%] mx-auto'>
                    <p className='font-syne text-center font-bold pb-3'>Token sale live</p>
                    <p className='mx-auto text-center font-impact flex justify-center'>
                    <p className='flex flex-col'><p className='text-2xl sm:text-3xl font-bold' style={{ WebkitTextStrokeColor: 'black', WebkitTextStrokeWidth: '0.3px' }}>1</p><p className='font-mono'>DAYS</p></p>
                    <span className='sm:px-3 text-2xl font-bold' style={{ WebkitTextStrokeColor: 'black', WebkitTextStrokeWidth: '0.3px' }}>:</span>
                    <p className='flex flex-col'><p className='text-2xl sm:text-3xl font-bold' style={{ WebkitTextStrokeColor: 'black', WebkitTextStrokeWidth: '0.3px' }}>20</p><p className='font-mono'>HOURS</p></p>
                    <span className='sm:px-3 text-2xl font-bold' style={{ WebkitTextStrokeColor: 'black', WebkitTextStrokeWidth: '0.3px' }}>:</span>
                    <p className='flex flex-col'><p className='text-2xl sm:text-3xl font-bold' style={{ WebkitTextStrokeColor: 'black', WebkitTextStrokeWidth: '0.3px' }}>12</p><p className='font-mono uppercase'>minutes</p></p>
                    <span className='sm:px-3 text-2xl font-bold' style={{ WebkitTextStrokeColor: 'black', WebkitTextStrokeWidth: '0.3px' }}>:</span>
                    <p className='flex flex-col'><p className='text-2xl sm:text-3xl font-bold' style={{ WebkitTextStrokeColor: 'black', WebkitTextStrokeWidth: '0.3px' }}>39</p><p className='font-mono'>SECONDS</p></p>
                    </p>
                    <button className='bg-[#CD03FF] w-full rounded-md mt-7 text-white font-bold font-impact text-xl shadow-md py-1.5'
                        style={{ WebkitTextStrokeColor: 'black', WebkitTextStrokeWidth: '0.5px' }}>
                        Complete Sale
                    </button>
                </div>
                <div className='p-6 2xl:p-8 mt-10 rounded-2xl border-[5px] border-white/40 bg-black/50 w-[90%] md:w-[60%] lg:w-[40%] mx-auto'>
                    <p className='font-syne text-center font-bold pb-3'>Create Token sale </p>
                    <label className='text-white/75'>Name:</label>
                    <input className='w-[100%] rounded-md bg-[#D9D9D936] py-2 px-5 mb-2 outline-none border border-white/30'></input>
                    <label className='text-white/75'>Price:</label>
                    <input className='w-[100%] rounded-md bg-[#D9D9D936] py-2 px-5 mb-2 outline-none border border-white/30'></input>
                    <label className='text-white/75'>Hard cap:</label>
                    <input className='w-[100%] rounded-md bg-[#D9D9D936] py-2 px-5 outline-none border border-white/30'></input>
                    <button className='bg-[#CD03FF] w-full rounded-md mt-7 text-white font-bold font-impact text-xl shadow-md py-1.5'
                        style={{ WebkitTextStrokeColor: 'black', WebkitTextStrokeWidth: '0.5px' }}>
                        Confirm
                    </button>
                </div>
            </div>
            <div className="background shading absolute bg-[#C156DD]/60 z-0 h-[90%] w-[100%] blur-3xl"></div>

        </div>
    )
}
