// // import React, { useState, useEffect, useRef } from "react";
// // import "animate.css";
// // import { NavLink } from "react-router-dom";
// // import {
// //   useWeb3ModalAccount,
// //   useDisconnect,
// //   useWeb3Modal,
// // } from "@web3modal/ethers5/react";
// // import axios from "axios";
// // import { NODE_URL } from "./Config";
// // import toast, { Toaster } from "react-hot-toast";
// // import "../App.css";

// // export default function Header() {
// //   const [isDropdownOpen, setDropdownOpen] = useState(false);
// //   const [isMobileDropdownOpen, setMobileDropdownOpen] = useState(false);
// //   const [phantomConnected, setPhantomConnected] = useState(false);

// //   const [walletAddress, setWalletAddress] = useState(null);

// //   const { open } = useWeb3Modal();
// //   const { disconnect } = useDisconnect();
// //   const { address, isConnected } = useWeb3ModalAccount();

// //   const dropdownRef = useRef(null);
// //   const buttonRef = useRef(null);

// //   const handleDropdown = () => {
// //     if (!isConnected) {
// //       setDropdownOpen((prev) => !prev);
// //     }
// //   };

// //   const handleMobileDropdown = () => {
// //     setMobileDropdownOpen((prev) => !prev);
// //   };
// //   console.log(walletAddress);

// //   useEffect(() => {
// //     const storeWalletAddress = async () => {
// //       if (walletAddress !== null) {
// //         try {
// //           const response = await axios.get(
// //             `${NODE_URL}/apiv2/store-wallet?walletAddress=${walletAddress}`
// //           );
// //           console.log(response.data.message);
// //         } catch (error) {
// //           console.error("Error storing wallet address:", error);
// //         }
// //       }
// //     };
// //     storeWalletAddress();
// //   }, [walletAddress]);

// //   const handleClickOutside = (event) => {
// //     if (
// //       dropdownRef.current &&
// //       !dropdownRef.current.contains(event.target) &&
// //       buttonRef.current &&
// //       !buttonRef.current.contains(event.target)
// //     ) {
// //       setDropdownOpen(false);
// //     }
// //   };

// //   useEffect(() => {
// //     if (isDropdownOpen) {
// //       document.addEventListener("mousedown", handleClickOutside);
// //     } else {
// //       document.removeEventListener("mousedown", handleClickOutside);
// //     }

// //     return () => {
// //       document.removeEventListener("mousedown", handleClickOutside);
// //     };
// //   }, [isDropdownOpen]);

// //   const connectEvmWallet = async () => {
// //     try {
// //       if (!isConnected) {
// //         await open();
// //       } else {
// //         setWalletAddress(address);
// //         localStorage.setItem("walletAddress", address);
// //         localStorage.setItem("walletAddressType", "EVM");
// //         setDropdownOpen(false);
// //       }
// //     } catch (error) {
// //       console.error("Error connecting to EVM wallet:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     if (address !== undefined) {
// //       localStorage.setItem("walletAddress", address);
// //       localStorage.setItem("walletAddressType", "EVM");
// //     }
// //     const storedAddress = localStorage.getItem("walletAddress");
// //     const storedAddressType = localStorage.getItem("walletAddressType");
// //     console.log("Stored Address:", storedAddress);

// //     if (storedAddress && storedAddressType === "EVM") {
// //       setWalletAddress(storedAddress);
// //     } else if (storedAddress && storedAddressType === "PHANTOM") {
// //       setWalletAddress(storedAddress);
// //       setPhantomConnected(true);
// //     }
// //   }, [address]);

// //   useEffect(() => {
// //     const storedAddress = localStorage.getItem("walletAddress");
// //     const storedAddressType = localStorage.getItem("walletAddressType");
// //     console.log("Stored Address:", storedAddress);

// //     if (storedAddress && storedAddressType === "EVM") {
// //       setWalletAddress(storedAddress);
// //     } else if (storedAddress && storedAddressType === "PHANTOM") {
// //       setWalletAddress(storedAddress);
// //       setPhantomConnected(true);
// //     }
// //   }, []);

// //   const connectPhantomWallet = async () => {
// //     if (window.solana && window.solana.isPhantom) {
// //       try {
// //         const response = await window.solana.connect();
// //         const phantomAddress = response.publicKey.toString();
// //         console.log("Connected to Phantom wallet:", phantomAddress);
// //         setPhantomConnected(true);
// //         setWalletAddress(phantomAddress);
// //         localStorage.setItem("walletAddress", phantomAddress);
// //         localStorage.setItem("walletAddressType", "PHANTOM");
// //         setMobileDropdownOpen(false);
// //       } catch (error) {
// //         toast.error("Please install Solana Wallet");
// //         console.error("Error connecting to Phantom wallet:", error);
// //       }
// //     } else {
// //       console.error("Phantom wallet is not installed!");
// //     }
// //   };

// //   const disconnectEvmWallet = () => {
// //     disconnect();
// //     localStorage.removeItem("walletAddress");
// //     localStorage.removeItem("walletAddressType");
// //     setWalletAddress(null);
// //     setDropdownOpen(false);
// //   };

// //   const disconnectPhantomWallet = () => {
// //     setPhantomConnected(false);
// //     setWalletAddress(null);
// //     localStorage.removeItem("walletAddress");
// //     localStorage.removeItem("walletAddressType");
// //   };

// //   const formatAddress = (address) => {
// //     if (address && address.length > 10) {
// //       return `${address.slice(0, 6)}...${address.slice(-4)}`;
// //     } else {
// //       return address;
// //     }
// //   };

// //   const evmAddress = formatAddress(address);
// //   const phantomAddress = formatAddress(walletAddress);

// //   return (
// //     <div>
// //       <Toaster toastOptions={{ position: "top-center" }} />
// //       <div className="hidden lg:block bg-transparent">
// //         <div className="flex justify-end items-center 2xl:gap-9 lg:gap-4 px-10 py-6 text-white  cursor-pointer  ">
// //           <NavLink to="/">
// //             <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
// //               Home
// //             </a>
// //           </NavLink>
// //           <NavLink to="/sale/pre_sale_of_rmc_tokens/action=Pre_sale">
// //             <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
// //               Join Pre-Sale
// //             </a>
// //           </NavLink>
// //           {walletAddress && (
// //             <>
// //               <NavLink to="/RMC_tokens_trans_history/q=trans_history">
// //                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
// //                   Trans History
// //                 </a>
// //               </NavLink>
// //               <NavLink to="/RMC_tokens_Support">
// //                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
// //                   Debate
// //                 </a>
// //               </NavLink>
// //               <NavLink to="/Stake/stake_of_rmc_tokens/action=stake">
// //                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
// //                   Stake
// //                 </a>
// //               </NavLink>
// //               <NavLink to="/Bridge/bridge_of_rmc_tokens/action=bridge">
// //                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
// //                   RMC Bridge
// //                 </a>
// //               </NavLink>
// //               <NavLink to="/RMC_tokens_Stake_history_claim_tokens">
// //                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
// //                   Claim Tokens
// //                 </a>
// //               </NavLink>
// //             </>
// //           )}
// //           <NavLink to="/RMC_tokens_FAQ">
// //             <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
// //               FAQ
// //             </a>
// //           </NavLink>

// //           <div className="z-10 relative">
// //             {isConnected ? (
// //               <div className="flex items-center gap-4">
// //                 <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
// //                   {evmAddress} (EVM)
// //                 </span>
// //                 <button
// //                   onClick={disconnectEvmWallet}
// //                   className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
// //                 >
// //                   Disconnect
// //                 </button>
// //               </div>
// //             ) : phantomConnected ? (
// //               <div className="flex items-center gap-4">
// //                 <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
// //                   {phantomAddress} (SOLANA)
// //                 </span>
// //                 <button
// //                   onClick={disconnectPhantomWallet}
// //                   className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
// //                 >
// //                   Disconnect
// //                 </button>
// //               </div>
// //             ) : (
// //               <button
// //                 onClick={handleDropdown}
// //                 className="text-white lg:text-sm 2xl:text-lg font-poppins bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg transition-colors duration-300"
// //               >
// //                 Connect Wallet
// //               </button>
// //             )}
// //             {isDropdownOpen && !isConnected && !phantomConnected && (
// //               <div
// //                 className="dropdown-content  absolute right-0 mt-2 bg-black text-white w-[16rem] rounded-lg p-5 animate__animated animate__fadeInUp"
// //                 ref={dropdownRef}
// //               >
// //                 <div className="flex flex-col z-30 font-poppins gap-3 lg:text-sm 2xl:text-lg">
// //                   <button
// //                     onClick={connectEvmWallet}
// //                     className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
// //                   >
// //                     Connect EVM Wallet
// //                   </button>
// //                   <button
// //                     onClick={connectPhantomWallet}
// //                     className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
// //                   >
// //                     Connect Solana Wallet
// //                   </button>
// //                 </div>
// //               </div>
// //             )}
// //           </div>
// //         </div>
// //       </div>
// //       <div className="lg:hidden block bg-[#5c27fe] border-b border-black flex justify-end py-5 px-4">
// //         <i
// //           class="fa-solid fa-bars fa-xl"
// //           style={{ color: "#f8f9fc" }}
// //           onClick={handleMobileDropdown}
// //           ref={buttonRef}
// //         ></i>
// //       </div>
// //       {isMobileDropdownOpen && (
// //         <div
// //           className="dropdown-content text-black z-50 w-[14rem] absolute right-5 mt-2 top-10 animate__animated animate__fadeInUp"
// //           ref={dropdownRef}
// //         >
// //           <div className="flex flex-col gap-3 bg-black text-white rounded-lg p-5">
// //             <NavLink to="/">
// //               <a className="text-white text-center cursor-pointer">Home</a>
// //             </NavLink>
// //             <NavLink to="/sale/pre_sale_of_rmc_tokens/action=Pre_sale">
// //               <a className="text-white text-center cursor-pointer">
// //                 Join Pre-Sale
// //               </a>
// //             </NavLink>
// //             {walletAddress && (
// //               <>
// //                 <NavLink to="/RMC_tokens_trans_history/q=trans_history">
// //                   <a className="text-white text-center cursor-pointer">
// //                     Trans History
// //                   </a>
// //                 </NavLink>
// //                 <NavLink to="/RMC_tokens_Support">
// //                   <a className="text-white text-center cursor-pointer">
// //                     Debate
// //                   </a>
// //                 </NavLink>
// //                 <NavLink to="/Stake/stake_of_rmc_tokens/action=stake">
// //                   <a className="text-white text-center cursor-pointer">Stake</a>
// //                 </NavLink>
// //                 <NavLink to="/Bridge/bridge_of_rmc_tokens/action=bridge">
// //                   <a className="text-white text-center cursor-pointer ">
// //                     RMC Bridge
// //                   </a>
// //                 </NavLink>
// //                 <NavLink to="/RMC_tokens_Stake_history_claim_tokens">
// //                   <a className="text-white text-center cursor-pointer">
// //                     Claim Tokens
// //                   </a>
// //                 </NavLink>
// //               </>
// //             )}
// //             <NavLink to="/RMC_tokens_FAQ">
// //               <a className="text-white text-center cursor-pointer">FAQ</a>
// //             </NavLink>

// //             <div className="z-10 relative">
// //               {isConnected ? (
// //                 <div className="flex flex-col items-center gap-1">
// //                   <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
// //                     {evmAddress} (EVM)
// //                   </span>
// //                   <button
// //                     onClick={disconnectEvmWallet}
// //                     className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
// //                   >
// //                     Disconnect
// //                   </button>
// //                 </div>
// //               ) : phantomConnected ? (
// //                 <div className="flex flex-col items-center gap-1">
// //                   <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
// //                     {phantomAddress} (SOLANA)
// //                   </span>
// //                   <button
// //                     onClick={disconnectPhantomWallet}
// //                     className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
// //                   >
// //                     Disconnect
// //                   </button>
// //                 </div>
// //               ) : (
// //                 <button
// //                   onClick={handleDropdown}
// //                   className="text-white lg:text-sm 2xl:text-lg font-poppins bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg transition-colors duration-300"
// //                 >
// //                   Connect Wallet
// //                 </button>
// //               )}
// //               {isDropdownOpen && !isConnected && !phantomConnected && (
// //                 <div
// //                   className="dropdown-content  absolute -right-5 mt-2 bg-black text-black w-[16rem] rounded-lg p-5 animate__animated animate__fadeInUp"
// //                   ref={dropdownRef}
// //                 >
// //                   <div className="flex flex-col z-30 font-poppins gap-3 text-sm  2xl:text-lg">
// //                     <button
// //                       onClick={connectEvmWallet}
// //                       className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
// //                     >
// //                       Connect EVM Wallet
// //                     </button>
// //                     <button
// //                       onClick={connectPhantomWallet}
// //                       className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
// //                     >
// //                       Connect Solana Wallet
// //                     </button>
// //                   </div>
// //                 </div>
// //               )}
// //             </div>
// //           </div>
// //         </div>
// //       )}
// //     </div>
// //   );
// // }

// import React, { useState, useEffect, useRef } from "react";
// import "animate.css";
// import { NavLink } from "react-router-dom";
// import {
//   useWeb3ModalAccount,
//   useDisconnect,
//   useWeb3Modal,
// } from "@web3modal/ethers5/react";
// import axios from "axios";
// import { NODE_URL } from "./Config";
// import toast, { Toaster } from "react-hot-toast";
// import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
// import Solflare from "@solflare-wallet/sdk";
// import "../App.css";
// import { BitcoinNetworkType, getAddress, AddressPurpose } from "sats-connect";

// export default function Header() {
//   const [isDropdownOpen, setDropdownOpen] = useState(false);
//   const [isMobileDropdownOpen, setMobileDropdownOpen] = useState(false);
//   const [phantomConnected, setPhantomConnected] = useState(false);
//   const [tronConnected, setTronConnected] = useState(false);
//   const [walletAddress, setWalletAddress] = useState(null);
//   const [walletAddressType, setWalletAddressType] = useState(null);

//   const [btcConnected, setBtcConnected] = useState(false);
//   const [evmConnected, setEvmConnected] = useState(false);
//   const [network, setNetwork] = useState(null);

//   const { open } = useWeb3Modal();
//   const { disconnect } = useDisconnect();
//   const { address, isConnected } = useWeb3ModalAccount();
//   const userFriendlyAddress = useTonAddress();
//   const [tonConnectUI] = useTonConnectUI();
//   const wallet = new Solflare();

//   const dropdownRef = useRef(null);
//   const buttonRef = useRef(null);

//   const handleDropdown = () => {
//     if (!isConnected) {
//       setDropdownOpen((prev) => !prev);
//     }
//   };

//   const handleMobileDropdown = () => {
//     setMobileDropdownOpen((prev) => !prev);
//   };

//   console.log(walletAddress);

//   useEffect(() => {
//     const storeWalletAddress = async () => {
//       if (walletAddress !== null) {
//         try {
//           const response = await axios.get(
//             `${NODE_URL}/apiv2/store-wallet?walletAddress=${walletAddress}`
//           );
//           console.log(response.data.message);
//         } catch (error) {
//           console.error("Error storing wallet address:", error);
//         }
//       }
//     };
//     storeWalletAddress();
//   }, [walletAddress]);

//   const handleClickOutside = (event) => {
//     if (
//       dropdownRef.current &&
//       !dropdownRef.current.contains(event.target) &&
//       buttonRef.current &&
//       !buttonRef.current.contains(event.target)
//     ) {
//       setDropdownOpen(false);
//     }
//   };

//   useEffect(() => {
//     if (isDropdownOpen) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isDropdownOpen]);

//   const connectEvmWallet = async () => {
//     try {
//       if (!isConnected) {
//         await open();
//       } else {
//         setWalletAddress(address);
//         localStorage.setItem("walletAddress", address);
//         localStorage.setItem("walletAddressType", "EVM");
//         setDropdownOpen(false);
//       }
//     } catch (error) {
//       console.error("Error connecting to EVM wallet:", error);
//     }
//   };

//   useEffect(() => {
//     if (address !== undefined) {
//       localStorage.setItem("walletAddress", address);
//       localStorage.setItem("walletAddressType", "EVM");
//     }
//     const storedAddress = localStorage.getItem("walletAddress");
//     const storedAddressType = localStorage.getItem("walletAddressType");
//     console.log("Stored Address:", storedAddress, storedAddressType);

//     if (storedAddress && storedAddressType === "EVM") {
//       setWalletAddress(storedAddress);
//       setWalletAddressType("EVM");
//       setDropdownOpen(false);
//       setEvmConnected(true);
//     } else if (storedAddress && storedAddressType === "PHANTOM") {
//       setWalletAddress(storedAddress);
//       setDropdownOpen(false);
//       setPhantomConnected(true);
//     } else if (storedAddress && storedAddressType === "TON") {
//       setWalletAddress(storedAddress);
//       setDropdownOpen(false);
//     } else if (storedAddress && storedAddressType === "TRON") {
//       setWalletAddress(storedAddress);
//       setDropdownOpen(false);
//       setTronConnected(true);
//     }
//   }, [address]);

//   useEffect(() => {
//     const storedAddress = localStorage.getItem("walletAddress");
//     const storedAddressType = localStorage.getItem("walletAddressType");
//     console.log("Stored Address:", storedAddress);

//     if (storedAddress && storedAddressType === "EVM") {
//       setWalletAddress(storedAddress);
//     } else if (storedAddress && storedAddressType === "PHANTOM") {
//       setWalletAddress(storedAddress);
//       setPhantomConnected(true);
//     } else if (storedAddress && storedAddressType === "TON") {
//       setWalletAddress(storedAddress);
//     } else if (storedAddress && storedAddressType === "TRON") {
//       setWalletAddress(storedAddress);
//       setTronConnected(true);
//     } else if (storedAddress && storedAddressType === "BTC") {
//       setWalletAddress(storedAddress);
//       setBtcConnected(true);
//     }
//   }, []);

//   // const connectPhantomWallet = async () => {
//   //   try {
//   //     await wallet.connect();
//   //     const phantomAddress = wallet.publicKey.toString();
//   //     console.log("Connected to Phantom wallet:", phantomAddress);
//   //     setPhantomConnected(true);
//   //     setWalletAddress(phantomAddress);
//   //     localStorage.setItem("walletAddress", phantomAddress);
//   //     localStorage.setItem("walletAddressType", "PHANTOM");
//   //     setMobileDropdownOpen(false);
//   //   } catch (error) {
//   //     toast.error("Please install Solana Wallet");
//   //     console.error("Error connecting to Phantom wallet:", error);
//   //   }
//   // };

//   const connectPhantomWallet = async () => {
//     const isMobile = /Mobi|Android/i.test(navigator.userAgent);

//     if (window.solana && window.solana.isPhantom) {
//       try {
//         const response = await window.solana.connect();
//         const phantomAddress = response.publicKey.toString();
//         console.log("Connected to Phantom wallet:", phantomAddress);
//         setPhantomConnected(true);
//         setWalletAddress(phantomAddress);
//         localStorage.setItem("walletAddress", phantomAddress);
//         localStorage.setItem("walletAddressType", "PHANTOM");
//         setMobileDropdownOpen(false);
//       } catch (error) {
//         const errorMessage = isMobile
//           ? "Please open Solana Wallet in a web browser."
//           : "Please install Solana Wallet.";
//         toast.error(errorMessage);
//         console.error("Error connecting to Phantom wallet:", error);
//       }
//     } else {
//       const installMessage = isMobile
//         ? "Solana wallet is not installed! Please open in web browser."
//         : "Solana wallet is not installed! Please install Solana Wallet.";
//       console.error(installMessage);
//       toast.error(installMessage);
//     }
//   };

//   const connectTonWallet = async () => {
//     try {
//       if (userFriendlyAddress === "") {
//         await tonConnectUI.connectWallet();
//       } else {
//         setWalletAddress(userFriendlyAddress);
//         localStorage.setItem("walletAddress", userFriendlyAddress);
//         localStorage.setItem("walletAddressType", "TON");
//         setDropdownOpen(false);
//       }
//     } catch (error) {
//       console.error("Error connecting to EVM wallet:", error);
//     }
//   };

//   const connectTronWallet = async () => {
//     const isMobile = /Mobi|Android/i.test(navigator.userAgent);

//     try {
//       if (window.tronWeb) {
//         await window.tronWeb.request({ method: "tron_requestAccounts" });
//         const tronAddress = window.tronWeb.defaultAddress.base58;

//         if (!tronAddress) {
//           console.log("Failed to get Tron address");
//           toast.error("Failed to get Tron address. Please try again.");
//           return;
//         }

//         console.log("Connected to TronLink wallet:", tronAddress);
//         setTronConnected(true);
//         setWalletAddress(tronAddress);
//         localStorage.setItem("walletAddress", tronAddress);
//         localStorage.setItem("walletAddressType", "TRON");
//         setDropdownOpen(false);
//         setMobileDropdownOpen(false);
//       } else {
//         const installMessage = isMobile
//           ? "Tron wallet is not installed! Please open in web browser."
//           : "Tron wallet is not installed! Please install TronLink Wallet.";
//         toast.error(installMessage);
//         console.error(installMessage);
//       }
//     } catch (error) {
//       console.error("Error connecting to TronLink wallet:", error);
//       toast.error("Error connecting to TronLink wallet. Please try again.");
//     }
//   };

//   useEffect(() => {
//     if (userFriendlyAddress !== "") {
//       localStorage.setItem("walletAddress", userFriendlyAddress);
//       localStorage.setItem("walletAddressType", "TON");
//     }
//     const storedAddress = localStorage.getItem("walletAddress");
//     const storedAddressType = localStorage.getItem("walletAddressType");
//     console.log("Stored Address:", storedAddress);

//     if (storedAddress && storedAddressType === "EVM") {
//       setWalletAddress(storedAddress);
//       setWalletAddressType("EVM");
//       setDropdownOpen(false);
//       setEvmConnected(true);
//     } else if (storedAddress && storedAddressType === "PHANTOM") {
//       setWalletAddress(storedAddress);
//       setDropdownOpen(false);
//       setPhantomConnected(true);
//     } else if (storedAddress && storedAddressType === "TON") {
//       setWalletAddress(storedAddress);
//       setDropdownOpen(false);
//     } else if (storedAddress && storedAddressType === "TRON") {
//       setWalletAddress(storedAddress);
//       setDropdownOpen(false);
//       setTronConnected(true);
//     }
//   }, [userFriendlyAddress]);

//   const disconnectEvmWallet = () => {
//     if (isConnected === true) {
//       disconnect();
//     }
//     setNetwork(null);
//     setEvmConnected(false);
//     localStorage.removeItem("walletAddress");
//     localStorage.removeItem("walletAddressType");
//     setWalletAddress(null);
//     setDropdownOpen(false);
//   };

//   // const disconnectPhantomWallet = async () => {
//   //   await wallet.disconnect();
//   //   setPhantomConnected(false);
//   //   setWalletAddress(null);
//   //   localStorage.removeItem("walletAddress");
//   //   localStorage.removeItem("walletAddressType");
//   // };

//   const disconnectPhantomWallet = () => {
//     setPhantomConnected(false);
//     setWalletAddress(null);
//     localStorage.removeItem("walletAddress");
//     localStorage.removeItem("walletAddressType");
//   };

//   const disconnectTonWallet = async () => {
//     await tonConnectUI.disconnect();
//     localStorage.removeItem("walletAddress");
//     localStorage.removeItem("walletAddressType");
//     setWalletAddress(null);
//     setDropdownOpen(false);
//   };

//   const disconnectTronWallet = () => {
//     setTronConnected(false);
//     setWalletAddress(null);
//     localStorage.removeItem("walletAddress");
//     localStorage.removeItem("walletAddressType");
//   };

//   const connectBtcWallet = async () => {
//     try {
//       await getAddress({
//         payload: {
//           purposes: [AddressPurpose.Payment],
//           message: "My awesome dapp needs your address info",
//           network: {
//             type: BitcoinNetworkType.Mainnet, //BitcoinNetworkType.Mainnet,
//           },
//         },
//         onFinish: (response) => {
//           if (response && response.addresses && response.addresses.length > 0) {
//             console.log(response.addresses[0].address);
//             setWalletAddress(response.addresses[0].address);

//             setBtcConnected(true);
//             localStorage.setItem(
//               "walletAddress",
//               response.addresses[0].address
//             );
//             localStorage.setItem("walletAddressType", "BTC");
//             setDropdownOpen(false);
//           } else {
//             toast("No wallet detected");
//           }
//         },
//         onCancel: () => {
//           toast("User cancelled the request");
//         },
//       });
//     } catch (error) {
//       if (error.message.includes("No Bitcoin wallet installed")) {
//         toast(
//           "No Bitcoin wallet detected. Please install a wallet and try again."
//         );
//       } else {
//         toast("An unexpected error occurred. Please try again.");
//       }
//       console.error("Error connecting to Bitcoin wallet:", error);
//     }
//   };

//   const disconnectBtcWallet = () => {
//     setBtcConnected(false);
//     setWalletAddress(null);
//     localStorage.removeItem("walletAddress");
//     localStorage.removeItem("walletAddressType");
//   };

//   const formatAddress = (address) => {
//     if (address && address.length > 10) {
//       return `${address.slice(0, 6)}...${address.slice(-4)}`;
//     } else {
//       return address;
//     }
//   };

//   const evmAddress = formatAddress(address);
//   const phantomAddress = formatAddress(walletAddress);
//   const tonAddress = formatAddress(walletAddress);
//   const tronAddress = formatAddress(walletAddress);
//   const btcAddress = formatAddress(walletAddress);

//   return (
//     <div>
//       <Toaster toastOptions={{ position: "top-center" }} />
//       <div className="hidden lg:block bg-transparent">
//         <div className="flex justify-end items-center 2xl:gap-9 lg:gap-4 px-10 py-6 text-white  cursor-pointer  ">
//           <NavLink to="/">
//             <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//               Home
//             </a>
//           </NavLink>
//           <NavLink to="/sale/pre_sale_of_rmc_tokens/action=Pre_sale">
//             <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//               Join Pre-Sale
//             </a>
//           </NavLink>
//           {walletAddress && (
//             <>
//               <NavLink to="/RMC_tokens_trans_history/q=trans_history">
//                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//                   Trans History
//                 </a>
//               </NavLink>
//               <NavLink to="/RMC_tokens_Support">
//                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//                   Debate
//                 </a>
//               </NavLink>
//               <NavLink to="/Stake/stake_of_rmc_tokens/action=stake">
//                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//                   Stake
//                 </a>
//               </NavLink>
//               <NavLink to="/Bridge/bridge_of_rmc_tokens/action=bridge">
//                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//                   RMC Bridge
//                 </a>
//               </NavLink>
//               <NavLink to="/RMC_tokens_Stake_history_claim_tokens">
//                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//                   Claim Tokens
//                 </a>
//               </NavLink>
//             </>
//           )}
//           <NavLink to="/RMC_tokens_FAQ">
//             <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//               FAQ
//             </a>
//           </NavLink>

//           <div className="z-30 relative">
//             {address || evmConnected ? (
//               <div className="flex items-center gap-4">
//                 <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                   {evmAddress} (EVM)
//                 </span>
//                 <button
//                   onClick={disconnectEvmWallet}
//                   className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                 >
//                   Disconnect
//                 </button>
//               </div>
//             ) : phantomConnected ? (
//               <div className="flex items-center gap-4">
//                 <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                   {phantomAddress} (SOLANA)
//                 </span>
//                 <button
//                   onClick={disconnectPhantomWallet}
//                   className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                 >
//                   Disconnect
//                 </button>
//               </div>
//             ) : userFriendlyAddress !== "" ? (
//               <div className="flex items-center gap-4">
//                 <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                   {tonAddress} (TON)
//                 </span>
//                 <button
//                   onClick={disconnectTonWallet}
//                   className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                 >
//                   Disconnect
//                 </button>
//               </div>
//             ) : tronConnected ? (
//               <div className="flex items-center gap-4">
//                 <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                   {tronAddress} (TRON)
//                 </span>
//                 <button
//                   onClick={disconnectTronWallet}
//                   className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                 >
//                   Disconnect
//                 </button>
//               </div>
//             ) : btcConnected ? (
//               <div className="flex items-center gap-4">
//                 <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                   {btcAddress} (BTC)
//                 </span>
//                 <button
//                   onClick={disconnectBtcWallet}
//                   className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                 >
//                   Disconnect
//                 </button>
//               </div>
//             ) : (
//               <button
//                 onClick={handleDropdown}
//                 className="text-white lg:text-sm 2xl:text-lg font-poppins bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg transition-colors duration-300"
//               >
//                 Connect Wallet
//               </button>
//             )}
//             {isDropdownOpen &&
//               !isConnected &&
//               !phantomConnected &&
//               !userFriendlyAddress &&
//               !tronAddress &&
//               !btcAddress && (
//                 <div
//                   className="dropdown-content  absolute right-0 mt-2 bg-black text-white w-[16rem] rounded-lg p-5 animate__animated animate__fadeInUp"
//                   ref={dropdownRef}
//                 >
//                   <div className="flex flex-col z-30 font-poppins gap-3 lg:text-sm 2xl:text-lg">
//                     <button
//                       onClick={connectEvmWallet}
//                       className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                     >
//                       Connect EVM Wallet
//                     </button>
//                     <button
//                       onClick={connectPhantomWallet}
//                       className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                     >
//                       Connect Solana Wallet
//                     </button>
//                     <button
//                       onClick={connectTonWallet}
//                       className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                     >
//                       Connect Ton Wallet
//                     </button>
//                     <button
//                       onClick={connectTronWallet}
//                       className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                     >
//                       Connect Tron Wallet
//                     </button>
//                     <button
//                       onClick={connectBtcWallet}
//                       className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                     >
//                       Connect BTC Wallet
//                     </button>
//                   </div>
//                 </div>
//               )}
//           </div>
//         </div>
//       </div>
//       <div className="lg:hidden block bg-[#5c27fe] border-b border-black flex justify-end py-5 px-4">
//         <i
//           class="fa-solid fa-bars fa-xl"
//           style={{ color: "#f8f9fc" }}
//           onClick={handleMobileDropdown}
//           ref={buttonRef}
//         ></i>
//       </div>
//       {isMobileDropdownOpen && (
//         <div
//           className="dropdown-content text-black z-50 w-[14rem] absolute right-5 mt-2 top-10 animate__animated animate__fadeInUp"
//           ref={dropdownRef}
//         >
//           <div className="flex flex-col gap-3 bg-black text-white rounded-lg p-5">
//             <NavLink to="/">
//               <a className="text-white text-center cursor-pointer">Home</a>
//             </NavLink>
//             <NavLink to="/sale/pre_sale_of_rmc_tokens/action=Pre_sale">
//               <a className="text-white text-center cursor-pointer">
//                 Join Pre-Sale
//               </a>
//             </NavLink>
//             {walletAddress && (
//               <>
//                 <NavLink to="/RMC_tokens_trans_history/q=trans_history">
//                   <a className="text-white text-center cursor-pointer">
//                     Trans History
//                   </a>
//                 </NavLink>
//                 <NavLink to="/RMC_tokens_Support">
//                   <a className="text-white text-center cursor-pointer">
//                     Debate
//                   </a>
//                 </NavLink>
//                 <NavLink to="/Stake/stake_of_rmc_tokens/action=stake">
//                   <a className="text-white text-center cursor-pointer">Stake</a>
//                 </NavLink>
//                 <NavLink to="/Bridge/bridge_of_rmc_tokens/action=bridge">
//                   <a className="text-white text-center cursor-pointer ">
//                     RMC Bridge
//                   </a>
//                 </NavLink>
//                 <NavLink to="/RMC_tokens_Stake_history_claim_tokens">
//                   <a className="text-white text-center cursor-pointer">
//                     Claim Tokens
//                   </a>
//                 </NavLink>
//               </>
//             )}
//             <NavLink to="/RMC_tokens_FAQ">
//               <a className="text-white text-center cursor-pointer">FAQ</a>
//             </NavLink>

//             <div className="z-10 relative">
//               {isConnected ? (
//                 <div className="flex flex-col items-center gap-1">
//                   <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                     {evmAddress} (EVM)
//                   </span>
//                   <button
//                     onClick={disconnectEvmWallet}
//                     className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                   >
//                     Disconnect
//                   </button>
//                 </div>
//               ) : phantomConnected ? (
//                 <div className="flex flex-col items-center gap-1">
//                   <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                     {phantomAddress} (SOLANA)
//                   </span>
//                   <button
//                     onClick={disconnectPhantomWallet}
//                     className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                   >
//                     Disconnect
//                   </button>
//                 </div>
//               ) : userFriendlyAddress !== "" ? (
//                 <div className="flex flex-col items-center gap-1">
//                   <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                     {tonAddress} (TON)
//                   </span>
//                   <button
//                     onClick={disconnectTonWallet}
//                     className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                   >
//                     Disconnect
//                   </button>
//                 </div>
//               ) : tronConnected ? (
//                 <div className="flex flex-col items-center gap-1">
//                   <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                     {tronAddress} (TRON)
//                   </span>
//                   <button
//                     onClick={disconnectTronWallet}
//                     className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                   >
//                     Disconnect
//                   </button>
//                 </div>
//               ) : btcAddress === true ? (
//                 <div className="flex flex-col items-center gap-1">
//                   <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                     {btcAddress} (BTC)
//                   </span>
//                   <button
//                     onClick={disconnectBtcWallet}
//                     className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                   >
//                     Disconnect
//                   </button>
//                 </div>
//               ) : (
//                 <button
//                   onClick={handleDropdown}
//                   className="text-white lg:text-sm 2xl:text-lg font-poppins bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                 >
//                   Connect Wallet
//                 </button>
//               )}
//               {isDropdownOpen &&
//                 !isConnected &&
//                 !phantomConnected &&
//                 !userFriendlyAddress &&
//                 !tronAddress &&
//                 !btcAddress && (
//                   <div
//                     className="dropdown-content  absolute -right-5 mt-2 bg-black text-black w-[16rem] rounded-lg p-5 animate__animated animate__fadeInUp"
//                     ref={dropdownRef}
//                   >
//                     <div className="flex flex-col z-30 font-poppins gap-3 text-sm  2xl:text-lg">
//                       <button
//                         onClick={connectEvmWallet}
//                         className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                       >
//                         Connect EVM Wallet
//                       </button>
//                       <button
//                         onClick={connectPhantomWallet}
//                         className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                       >
//                         Connect Solana Wallet
//                       </button>
//                       <button
//                         onClick={connectTonWallet}
//                         className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                       >
//                         Connect Ton Wallet
//                       </button>
//                       <button
//                         onClick={connectTronWallet}
//                         className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                       >
//                         Connect Tron Wallet
//                       </button>
//                       <button
//                         onClick={connectBtcWallet}
//                         className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                       >
//                         Connect BTC Wallet
//                       </button>
//                     </div>
//                   </div>
//                 )}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// import React, { useState, useEffect, useRef } from "react";
// import "animate.css";
// import { NavLink } from "react-router-dom";
// import {
//   useWeb3ModalAccount,
//   useDisconnect,
//   useWeb3Modal,
// } from "@web3modal/ethers5/react";
// import axios from "axios";
// import { NODE_URL } from "./Config";
// import toast, { Toaster } from "react-hot-toast";
// import "../App.css";

// export default function Header() {
//   const [isDropdownOpen, setDropdownOpen] = useState(false);
//   const [isMobileDropdownOpen, setMobileDropdownOpen] = useState(false);
//   const [phantomConnected, setPhantomConnected] = useState(false);

//   const [walletAddress, setWalletAddress] = useState(null);

//   const { open } = useWeb3Modal();
//   const { disconnect } = useDisconnect();
//   const { address, isConnected } = useWeb3ModalAccount();

//   const dropdownRef = useRef(null);
//   const buttonRef = useRef(null);

//   const handleDropdown = () => {
//     if (!isConnected) {
//       setDropdownOpen((prev) => !prev);
//     }
//   };

//   const handleMobileDropdown = () => {
//     setMobileDropdownOpen((prev) => !prev);
//   };
//   console.log(walletAddress);

//   useEffect(() => {
//     const storeWalletAddress = async () => {
//       if (walletAddress !== null) {
//         try {
//           const response = await axios.get(
//             `${NODE_URL}/apiv2/store-wallet?walletAddress=${walletAddress}`
//           );
//           console.log(response.data.message);
//         } catch (error) {
//           console.error("Error storing wallet address:", error);
//         }
//       }
//     };
//     storeWalletAddress();
//   }, [walletAddress]);

//   const handleClickOutside = (event) => {
//     if (
//       dropdownRef.current &&
//       !dropdownRef.current.contains(event.target) &&
//       buttonRef.current &&
//       !buttonRef.current.contains(event.target)
//     ) {
//       setDropdownOpen(false);
//     }
//   };

//   useEffect(() => {
//     if (isDropdownOpen) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isDropdownOpen]);

//   const connectEvmWallet = async () => {
//     try {
//       if (!isConnected) {
//         await open();
//       } else {
//         setWalletAddress(address);
//         localStorage.setItem("walletAddress", address);
//         localStorage.setItem("walletAddressType", "EVM");
//         setDropdownOpen(false);
//       }
//     } catch (error) {
//       console.error("Error connecting to EVM wallet:", error);
//     }
//   };

//   useEffect(() => {
//     if (address !== undefined) {
//       localStorage.setItem("walletAddress", address);
//       localStorage.setItem("walletAddressType", "EVM");
//     }
//     const storedAddress = localStorage.getItem("walletAddress");
//     const storedAddressType = localStorage.getItem("walletAddressType");
//     console.log("Stored Address:", storedAddress);

//     if (storedAddress && storedAddressType === "EVM") {
//       setWalletAddress(storedAddress);
//     } else if (storedAddress && storedAddressType === "PHANTOM") {
//       setWalletAddress(storedAddress);
//       setPhantomConnected(true);
//     }
//   }, [address]);

//   useEffect(() => {
//     const storedAddress = localStorage.getItem("walletAddress");
//     const storedAddressType = localStorage.getItem("walletAddressType");
//     console.log("Stored Address:", storedAddress);

//     if (storedAddress && storedAddressType === "EVM") {
//       setWalletAddress(storedAddress);
//     } else if (storedAddress && storedAddressType === "PHANTOM") {
//       setWalletAddress(storedAddress);
//       setPhantomConnected(true);
//     }
//   }, []);

//   const connectPhantomWallet = async () => {
//     if (window.solana && window.solana.isPhantom) {
//       try {
//         const response = await window.solana.connect();
//         const phantomAddress = response.publicKey.toString();
//         console.log("Connected to Phantom wallet:", phantomAddress);
//         setPhantomConnected(true);
//         setWalletAddress(phantomAddress);
//         localStorage.setItem("walletAddress", phantomAddress);
//         localStorage.setItem("walletAddressType", "PHANTOM");
//         setMobileDropdownOpen(false);
//       } catch (error) {
//         toast.error("Please install Solana Wallet");
//         console.error("Error connecting to Phantom wallet:", error);
//       }
//     } else {
//       console.error("Phantom wallet is not installed!");
//     }
//   };

//   const disconnectEvmWallet = () => {
//     disconnect();
//     localStorage.removeItem("walletAddress");
//     localStorage.removeItem("walletAddressType");
//     setWalletAddress(null);
//     setDropdownOpen(false);
//   };

//   const disconnectPhantomWallet = () => {
//     setPhantomConnected(false);
//     setWalletAddress(null);
//     localStorage.removeItem("walletAddress");
//     localStorage.removeItem("walletAddressType");
//   };

//   const formatAddress = (address) => {
//     if (address && address.length > 10) {
//       return `${address.slice(0, 6)}...${address.slice(-4)}`;
//     } else {
//       return address;
//     }
//   };

//   const evmAddress = formatAddress(address);
//   const phantomAddress = formatAddress(walletAddress);

//   return (
//     <div>
//       <Toaster toastOptions={{ position: "top-center" }} />
//       <div className="hidden lg:block bg-transparent">
//         <div className="flex justify-end items-center 2xl:gap-9 lg:gap-4 px-10 py-6 text-white  cursor-pointer  ">
//           <NavLink to="/">
//             <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//               Home
//             </a>
//           </NavLink>
//           <NavLink to="/sale/pre_sale_of_rmc_tokens/action=Pre_sale">
//             <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//               Join Pre-Sale
//             </a>
//           </NavLink>
//           {walletAddress && (
//             <>
//               <NavLink to="/RMC_tokens_trans_history/q=trans_history">
//                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//                   Trans History
//                 </a>
//               </NavLink>
//               <NavLink to="/RMC_tokens_Support">
//                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//                   Debate
//                 </a>
//               </NavLink>
//               <NavLink to="/Stake/stake_of_rmc_tokens/action=stake">
//                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//                   Stake
//                 </a>
//               </NavLink>
//               <NavLink to="/Bridge/bridge_of_rmc_tokens/action=bridge">
//                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//                   RMC Bridge
//                 </a>
//               </NavLink>
//               <NavLink to="/RMC_tokens_Stake_history_claim_tokens">
//                 <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//                   Claim Tokens
//                 </a>
//               </NavLink>
//             </>
//           )}
//           <NavLink to="/RMC_tokens_FAQ">
//             <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
//               FAQ
//             </a>
//           </NavLink>

//           <div className="z-10 relative">
//             {isConnected ? (
//               <div className="flex items-center gap-4">
//                 <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                   {evmAddress} (EVM)
//                 </span>
//                 <button
//                   onClick={disconnectEvmWallet}
//                   className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                 >
//                   Disconnect
//                 </button>
//               </div>
//             ) : phantomConnected ? (
//               <div className="flex items-center gap-4">
//                 <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                   {phantomAddress} (SOLANA)
//                 </span>
//                 <button
//                   onClick={disconnectPhantomWallet}
//                   className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                 >
//                   Disconnect
//                 </button>
//               </div>
//             ) : (
//               <button
//                 onClick={handleDropdown}
//                 className="text-white lg:text-sm 2xl:text-lg font-poppins bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg transition-colors duration-300"
//               >
//                 Connect Wallet
//               </button>
//             )}
//             {isDropdownOpen && !isConnected && !phantomConnected && (
//               <div
//                 className="dropdown-content  absolute right-0 mt-2 bg-black text-white w-[16rem] rounded-lg p-5 animate__animated animate__fadeInUp"
//                 ref={dropdownRef}
//               >
//                 <div className="flex flex-col z-30 font-poppins gap-3 lg:text-sm 2xl:text-lg">
//                   <button
//                     onClick={connectEvmWallet}
//                     className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                   >
//                     Connect EVM Wallet
//                   </button>
//                   <button
//                     onClick={connectPhantomWallet}
//                     className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                   >
//                     Connect Solana Wallet
//                   </button>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//       <div className="lg:hidden block bg-[#5c27fe] border-b border-black flex justify-end py-5 px-4">
//         <i
//           class="fa-solid fa-bars fa-xl"
//           style={{ color: "#f8f9fc" }}
//           onClick={handleMobileDropdown}
//           ref={buttonRef}
//         ></i>
//       </div>
//       {isMobileDropdownOpen && (
//         <div
//           className="dropdown-content text-black z-50 w-[14rem] absolute right-5 mt-2 top-10 animate__animated animate__fadeInUp"
//           ref={dropdownRef}
//         >
//           <div className="flex flex-col gap-3 bg-black text-white rounded-lg p-5">
//             <NavLink to="/">
//               <a className="text-white text-center cursor-pointer">Home</a>
//             </NavLink>
//             <NavLink to="/sale/pre_sale_of_rmc_tokens/action=Pre_sale">
//               <a className="text-white text-center cursor-pointer">
//                 Join Pre-Sale
//               </a>
//             </NavLink>
//             {walletAddress && (
//               <>
//                 <NavLink to="/RMC_tokens_trans_history/q=trans_history">
//                   <a className="text-white text-center cursor-pointer">
//                     Trans History
//                   </a>
//                 </NavLink>
//                 <NavLink to="/RMC_tokens_Support">
//                   <a className="text-white text-center cursor-pointer">
//                     Debate
//                   </a>
//                 </NavLink>
//                 <NavLink to="/Stake/stake_of_rmc_tokens/action=stake">
//                   <a className="text-white text-center cursor-pointer">Stake</a>
//                 </NavLink>
//                 <NavLink to="/Bridge/bridge_of_rmc_tokens/action=bridge">
//                   <a className="text-white text-center cursor-pointer ">
//                     RMC Bridge
//                   </a>
//                 </NavLink>
//                 <NavLink to="/RMC_tokens_Stake_history_claim_tokens">
//                   <a className="text-white text-center cursor-pointer">
//                     Claim Tokens
//                   </a>
//                 </NavLink>
//               </>
//             )}
//             <NavLink to="/RMC_tokens_FAQ">
//               <a className="text-white text-center cursor-pointer">FAQ</a>
//             </NavLink>

//             <div className="z-10 relative">
//               {isConnected ? (
//                 <div className="flex flex-col items-center gap-1">
//                   <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                     {evmAddress} (EVM)
//                   </span>
//                   <button
//                     onClick={disconnectEvmWallet}
//                     className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                   >
//                     Disconnect
//                   </button>
//                 </div>
//               ) : phantomConnected ? (
//                 <div className="flex flex-col items-center gap-1">
//                   <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
//                     {phantomAddress} (SOLANA)
//                   </span>
//                   <button
//                     onClick={disconnectPhantomWallet}
//                     className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                   >
//                     Disconnect
//                   </button>
//                 </div>
//               ) : (
//                 <button
//                   onClick={handleDropdown}
//                   className="text-white lg:text-sm 2xl:text-lg font-poppins bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg transition-colors duration-300"
//                 >
//                   Connect Wallet
//                 </button>
//               )}
//               {isDropdownOpen && !isConnected && !phantomConnected && (
//                 <div
//                   className="dropdown-content  absolute -right-5 mt-2 bg-black text-black w-[16rem] rounded-lg p-5 animate__animated animate__fadeInUp"
//                   ref={dropdownRef}
//                 >
//                   <div className="flex flex-col z-30 font-poppins gap-3 text-sm  2xl:text-lg">
//                     <button
//                       onClick={connectEvmWallet}
//                       className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                     >
//                       Connect EVM Wallet
//                     </button>
//                     <button
//                       onClick={connectPhantomWallet}
//                       className="bg-gradient-to-b bg-[#DB416B] via-[#FFF98C] to-[#E4AF18] text-black font-bold px-4 py-2 rounded-lg transition-colors duration-300"
//                     >
//                       Connect Solana Wallet
//                     </button>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

import React, { useState, useEffect, useRef } from "react";
import "animate.css";
import { NavLink } from "react-router-dom";
import {
  useWeb3ModalAccount,
  useDisconnect,
  useWeb3Modal, useWeb3ModalProvider
} from "@web3modal/ethers5/react";
import axios from "axios";
import { NODE_URL, Contract_details } from "./Config";
import toast, { Toaster } from "react-hot-toast";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import Solflare from "@solflare-wallet/sdk";
import "../App.css";
import { BitcoinNetworkType, getAddress, AddressPurpose } from "sats-connect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { ethers } from "ethers";



export default function Header() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileDropdownOpen, setMobileDropdownOpen] = useState(false);
  const [phantomConnected, setPhantomConnected] = useState(false);
  const [tronConnected, setTronConnected] = useState(false);
  const [evmConnected, setEvmConnected] = useState(false);

  const [walletAddress, setWalletAddress] = useState(null);
  const [walletAddressType, setWalletAddressType] = useState(null);

  const [btcConnected, setBtcConnected] = useState(false);
  const [network, setNetwork] = useState(null);

  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const userFriendlyAddress = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();
  const wallet = new Solflare();

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const networks = {
    ethereum: {
      chainId: 1,
      rpcUrls: [Contract_details.ETH_RPC],
      chainName: "Ethereum Mainnet",
      nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
      blockExplorerUrls: [Contract_details.ETH_EXPLORER],
    },
    polygon: {
      chainId: 137,
      rpcUrls: [Contract_details.POLYGON_RPC],
      chainName: "Polygon Mainnet",
      nativeCurrency: { name: "Matic", symbol: "MATIC", decimals: 18 },
      blockExplorerUrls: [Contract_details.POLYGON_EXPLORER],
    },
    base: {
      chainId: 8453,
      rpcUrls: [Contract_details.BASE_RPC],
      chainName: "Base Mainnet",
      nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
      blockExplorerUrls: [Contract_details.BASE_EXPLORER],
    },
    bsc: {
      chainId: 56,
      rpcUrls: [Contract_details.BSC_RPC],
      chainName: "Binance Smart Chain",
      nativeCurrency: { name: "Binance Coin", symbol: "BNB", decimals: 18 },
      blockExplorerUrls: [Contract_details.BSC_EXPLORER],
    },
  };

  const handleDropdown = () => {
    if (!isConnected) {
      setDropdownOpen((prev) => !prev);
    }
  };

  const handleMobileDropdown = () => {
    setMobileDropdownOpen((prev) => !prev);
  };

  // console.log(walletAddress);

  useEffect(() => {
    const storeWalletAddress = async () => {
      if (walletAddress !== null) {
        try {
          const response = await axios.get(
            `${NODE_URL}/apiv2/store-wallet?walletAddress=${walletAddress}`
          );
          console.log(response.data.message);
        } catch (error) {
          console.error("Error storing wallet address:", error);
        }
      }
    };
    storeWalletAddress();
  }, [walletAddress]);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    console.log(isDropdownOpen);
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.classList.add("no-scroll");
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const connectEvmWallet = async (chain) => {
    try {
      setNetwork(chain);
      if (!isConnected) {
        await open();
      } else {
        setWalletAddress(address);
        setDropdownOpen(false);
        localStorage.setItem("walletAddress", address);
        localStorage.setItem("walletAddressType", "EVM");
        setDropdownOpen(false);
      }
    } catch (error) {
      console.error("Error connecting to EVM wallet:", error);
    }
  };

  const switchNetwork = async (chainId) => {
    const network = Object.values(networks).find(
      (net) => net.chainId === chainId
    );

    try {
      if (!network) {
        throw new Error("Network not supported.");
      }

      await walletProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexValue(chainId) }],
      });

      toast.success(`Switched to ${network.chainName}`);
    } catch (switchError) {
      console.error("Switch Error:", switchError);

      if (switchError.code === 4902) {
        try {
          await walletProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: ethers.utils.hexValue(chainId),
                chainName: network.chainName,
                nativeCurrency: network.nativeCurrency,
                rpcUrls: network.rpcUrls,
                blockExplorerUrls: network.blockExplorerUrls,
              },
            ],
          });
          toast.success(`Added and switched to ${network.chainName}`);
        } catch (addError) {
          console.error("Add Error:", addError);
          toast.error(`Failed to add ${network.chainName} network.`);
        }
      } else {
        toast.error(`Failed to switch to ${network.chainName} network.`);
      }
    }
  };

  useEffect(() => {
    if (chainId !== undefined && network !== null) {
      switchNetwork(network);
    }
  }, [address]);

  useEffect(() => {
    if (address !== undefined) {
      localStorage.setItem("walletAddress", address);
      localStorage.setItem("walletAddressType", "EVM");
    }
    const storedAddress = localStorage.getItem("walletAddress");
    const storedAddressType = localStorage.getItem("walletAddressType");
    console.log("Stored Address:", storedAddress, storedAddressType);

    if (storedAddress && storedAddressType === "EVM") {
      setWalletAddress(storedAddress);
      setWalletAddressType("EVM");
      setDropdownOpen(false);
      setEvmConnected(true);
    } else if (storedAddress && storedAddressType === "PHANTOM") {
      setWalletAddress(storedAddress);
      setDropdownOpen(false);
      setPhantomConnected(true);
    } else if (storedAddress && storedAddressType === "TON") {
      setWalletAddress(storedAddress);
      setDropdownOpen(false);
    } else if (storedAddress && storedAddressType === "TRON") {
      setWalletAddress(storedAddress);
      setDropdownOpen(false);
      setTronConnected(true);
    }
  }, [address]);

  useEffect(() => {
    const storedAddress = localStorage.getItem("walletAddress");
    const storedAddressType = localStorage.getItem("walletAddressType");
    console.log("Stored Address:", storedAddress);

    if (storedAddress && storedAddressType === "EVM") {
      setWalletAddress(storedAddress);
    } else if (storedAddress && storedAddressType === "PHANTOM") {
      setWalletAddress(storedAddress);
      setPhantomConnected(true);
    } else if (storedAddress && storedAddressType === "TON") {
      setWalletAddress(storedAddress);
    } else if (storedAddress && storedAddressType === "TRON") {
      setWalletAddress(storedAddress);
      setTronConnected(true);
    } else if (storedAddress && storedAddressType === "BTC") {
      setWalletAddress(storedAddress);
      setBtcConnected(true);
    }
  }, []);

  // const connectPhantomWallet = async () => {
  //   try {
  //     await wallet.connect();
  //     const phantomAddress = wallet.publicKey.toString();
  //     console.log("Connected to Phantom wallet:", phantomAddress);
  //     setPhantomConnected(true);
  //     setWalletAddress(phantomAddress);
  //     localStorage.setItem("walletAddress", phantomAddress);
  //     localStorage.setItem("walletAddressType", "PHANTOM");
  //     setMobileDropdownOpen(false);
  //   } catch (error) {
  //     toast.error("Please install Solana Wallet");
  //     console.error("Error connecting to Phantom wallet:", error);
  //   }
  // };

  const connectPhantomWallet = async () => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

    if (window.solana && window.solana.isPhantom) {
      try {
        const response = await window.solana.connect();
        const phantomAddress = response.publicKey.toString();
        console.log("Connected to Phantom wallet:", phantomAddress);
        setPhantomConnected(true);
        setWalletAddress(phantomAddress);
        setDropdownOpen(false);
        localStorage.setItem("walletAddress", phantomAddress);
        localStorage.setItem("walletAddressType", "PHANTOM");
        setMobileDropdownOpen(false);
      } catch (error) {
        const errorMessage = isMobile
          ? "Please open Solana Wallet in a web browser."
          : "Please install Solana Wallet.";
        toast.error(errorMessage);
        console.error("Error connecting to Phantom wallet:", error);
      }
    } else {
      const installMessage = isMobile
        ? "Solana wallet is not installed! Please open in web browser."
        : "Solana wallet is not installed! Please install Solana Wallet.";
      console.error(installMessage);
      toast.error(installMessage);
    }
  };

  const connectTonWallet = async () => {
    try {
      if (userFriendlyAddress === "") {
        await tonConnectUI.connectWallet();
      } else {
        setWalletAddress(userFriendlyAddress);
        localStorage.setItem("walletAddress", userFriendlyAddress);
        localStorage.setItem("walletAddressType", "TON");
        setDropdownOpen(false);
        setDropdownOpen(false);
      }
    } catch (error) {
      console.error("Error connecting to EVM wallet:", error);
    }
  };

  const connectTronWallet = async () => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

    try {
      if (window.tronWeb) {
        await window.tronWeb.request({ method: "tron_requestAccounts" });
        const tronAddress = window.tronWeb.defaultAddress.base58;

        if (!tronAddress) {
          console.log("Failed to get Tron address");
          toast.error("Open Tron wallet manually.");
          return;
        }

        console.log("Connected to TronLink wallet:", tronAddress);
        setTronConnected(true);
        setWalletAddress(tronAddress);
        localStorage.setItem("walletAddress", tronAddress);
        localStorage.setItem("walletAddressType", "TRON");
        setDropdownOpen(false);
        setMobileDropdownOpen(false);
      } else {
        const installMessage = isMobile
          ? "Tron wallet is not installed! Please open in web browser."
          : "Tron wallet is not installed! Please install TronLink Wallet.";
        toast.error(installMessage);
        console.error(installMessage);
      }
    } catch (error) {
      console.error("Error connecting to TronLink wallet:", error);
      toast.error("Error connecting to TronLink wallet. Please try again.");
    }
  };

  useEffect(() => {
    if (userFriendlyAddress !== "") {
      localStorage.setItem("walletAddress", userFriendlyAddress);
      localStorage.setItem("walletAddressType", "TON");
    }
    const storedAddress = localStorage.getItem("walletAddress");
    const storedAddressType = localStorage.getItem("walletAddressType");
    console.log("Stored Address:", storedAddress);

    if (storedAddress && storedAddressType === "EVM") {
      setWalletAddress(storedAddress);
      setWalletAddressType("EVM");
      setDropdownOpen(false);
      setEvmConnected(true);
    } else if (storedAddress && storedAddressType === "PHANTOM") {
      setWalletAddress(storedAddress);
      setDropdownOpen(false);
      setPhantomConnected(true);
    } else if (storedAddress && storedAddressType === "TON") {
      setWalletAddress(storedAddress);
      setDropdownOpen(false);
    } else if (storedAddress && storedAddressType === "TRON") {
      setWalletAddress(storedAddress);
      setDropdownOpen(false);
      setTronConnected(true);
    }
  }, [userFriendlyAddress]);

  const disconnectEvmWallet = () => {
    if (isConnected === true) {
      disconnect();
    }
    setNetwork(null);
    setEvmConnected(false);
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("walletAddressType");
    setWalletAddress(null);
    setDropdownOpen(false);
  };

  // const disconnectPhantomWallet = async () => {
  //   await wallet.disconnect();
  //   setPhantomConnected(false);
  //   setWalletAddress(null);
  //   localStorage.removeItem("walletAddress");
  //   localStorage.removeItem("walletAddressType");
  // };

  const disconnectPhantomWallet = () => {
    setPhantomConnected(false);
    setWalletAddress(null);
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("walletAddressType");
    setDropdownOpen(false);
  };

  const disconnectTonWallet = async () => {
    await tonConnectUI.disconnect();
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("walletAddressType");
    setWalletAddress(null);
    setDropdownOpen(false);
  };

  const disconnectTronWallet = () => {
    setTronConnected(false);
    setWalletAddress(null);
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("walletAddressType");
    setDropdownOpen(false);
  };

  const connectBtcWallet = async () => {
    try {
      await getAddress({
        payload: {
          purposes: [AddressPurpose.Payment],
          message: "My awesome dapp needs your address info",
          network: {
            type: BitcoinNetworkType.Mainnet, //BitcoinNetworkType.Mainnet,
          },
        },
        onFinish: (response) => {
          if (response && response.addresses && response.addresses.length > 0) {
            console.log(response.addresses[0].address);
            setWalletAddress(response.addresses[0].address);

            setBtcConnected(true);
            localStorage.setItem(
              "walletAddress",
              response.addresses[0].address
            );
            localStorage.setItem("walletAddressType", "BTC");
            setDropdownOpen(false);
          } else {
            toast("No wallet detected");
          }
        },
        onCancel: () => {
          toast("User cancelled the request");
        },
      });
    } catch (error) {
      if (error.message.includes("No Bitcoin wallet installed")) {
        toast(
          "No Xverse wallet detected. Please install a wallet and try again."
        );
      } else {
        toast("An unexpected error occurred. Please try again.");
      }
      console.error("Error connecting to Bitcoin wallet:", error);
    }
  };

  const disconnectBtcWallet = () => {
    setBtcConnected(false);
    setWalletAddress(null);
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("walletAddressType");
    setDropdownOpen(false);
  };

  const formatAddress = (address) => {
    if (address && address.length > 10) {
      return `${address.slice(0, 6)}...${address.slice(-4)}`;
    } else {
      return address;
    }
  };

  const formatEVMAddress = (address) => {
    if (address && address.length > 10) {
      return `${address.slice(0, 6)}...${address.slice(-4)}`;
    } else if (walletAddressType === "EVM" && walletAddress !== null) {
      return `${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}`;
    } else {
      return address;
    }
  };

  const evmAddress = formatEVMAddress(address);
  const phantomAddress = formatAddress(walletAddress);
  const tonAddress = formatAddress(walletAddress);
  const tronAddress = formatAddress(walletAddress);
  const btcAddress = formatAddress(walletAddress);

  return (
    <div>
      <Toaster toastOptions={{ position: "top-center" }} />
      <div className="hidden lg:block bg-transparent font-playfair">
        <div className="flex justify-end items-center 2xl:gap-9 lg:gap-4 px-10 py-6 text-white  cursor-pointer  ">
          <NavLink to="/">
            <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
              Home
            </a>
          </NavLink>
          {/* <NavLink to="/sale/pre_sale_of_rmc_tokens/action=Pre_sale">
            <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
              Join Pre-Sale
            </a>
          </NavLink> */}
          {walletAddress && (
            <>
              <NavLink to="/RMC_tokens_trans_history/q=trans_history">
                <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
                  Trx History
                </a>
              </NavLink>
              <NavLink to="/RMC_tokens_Support">
                <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
                  Debate
                </a>
              </NavLink>
              <NavLink to="/Stake/stake_of_rmc_tokens/action=stake">
                <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
                  Stake
                </a>
              </NavLink>
              <NavLink to="/Bridge/bridge_of_rmc_tokens/action=bridge">
                <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
                  RMC Bridge
                </a>
              </NavLink>
              <NavLink to="/RMC_tokens_Stake_history_claim_tokens">
                <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
                  Claim Tokens
                </a>
              </NavLink>
            </>
          )}
          <NavLink to="/RMC_tokens_FAQ">
            <a className="text-white 2xl:text-xl lg:text-sm cursor-pointer font-bold ">
              FAQ
            </a>
          </NavLink>

          <div className="z-10 relative">
            {address || evmConnected ? (
              <div className="flex items-center gap-4">
                <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
                  {evmAddress} (EVM)
                </span>
                <button
                  onClick={disconnectEvmWallet}
                  className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
                >
                  Disconnect
                </button>
              </div>
            ) : phantomConnected ? (
              <div className="flex items-center gap-4">
                <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
                  {phantomAddress} (SOLANA)
                </span>
                <button
                  onClick={disconnectPhantomWallet}
                  className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
                >
                  Disconnect
                </button>
              </div>
            ) : userFriendlyAddress !== "" ? (
              <div className="flex items-center gap-4">
                <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
                  {tonAddress} (TON)
                </span>
                <button
                  onClick={disconnectTonWallet}
                  className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
                >
                  Disconnect
                </button>
              </div>
            ) : tronConnected ? (
              <div className="flex items-center gap-4">
                <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
                  {tronAddress} (TRON)
                </span>
                <button
                  onClick={disconnectTronWallet}
                  className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
                >
                  Disconnect
                </button>
              </div>
            ) : btcConnected ? (
              <div className="flex items-center gap-4">
                <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
                  {btcAddress} (BTC)
                </span>
                <button
                  onClick={disconnectBtcWallet}
                  className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
                >
                  Disconnect
                </button>
              </div>
            ) : (
              <button
                onClick={handleDropdown}
                className="text-white lg:text-sm 2xl:text-lg font-poppins bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg transition-colors duration-300"
              >
                Connect Wallet
              </button>
            )}
            {isDropdownOpen &&
              !isConnected &&
              !phantomConnected &&
              !userFriendlyAddress &&
              !tronAddress &&
              !btcAddress && (
                <div className="fixed w-full h-screen left-0 top-0 model-overlay z-50 p-14 flex items-start justify-center backdrop-blur-md bg-black/30 animate__animated animate__fadeInUp">
                  <div className="relative w-[95%] md:w-[60%] lg:w-[37%] text-white rounded-2xl bg-[#AA4CE4] border border-black p-3">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faXmark}
                        className=" cursor-pointer text-xs"
                        onClick={handleDropdown}
                      />
                    </div>
                    <p
                      className="text-center font-joti text-xl tracking-wider"
                      style={{
                        textShadow: "2px 2px 4px rgba(255, 255, 255, 0.4)",
                      }}
                    >
                      Choose your preferred Wallet
                    </p>
                    <div className="grid grid-cols-2 gap-5 px-3 mt-5 mb-10">
                      <div
                        className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                        onClick={() =>
                          connectEvmWallet(networks.ethereum.chainId)
                        }
                      >
                        <img
                          src="https://etherscan.io/images/svg/brands/ethereum-original.svg"
                          className="h-[50px] w-[50px] mt-1.5"
                        ></img>
                        <p className="text-center font-Share">Ethereum</p>
                      </div>
                      <div
                        className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                        onClick={() =>
                          connectEvmWallet(networks.polygon.chainId)
                        }
                      >
                        <img
                          src="https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.6.3.0"
                          className="h-[50px] w-[50px] mt-1.5"
                        ></img>
                        <p className="text-center font-Share">Polygon</p>
                      </div>
                      <div
                        className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                        onClick={() => connectEvmWallet(networks.base.chainId)}
                      >
                        <img
                          src="https://basescan.org/assets/base/images/svg/logos/chain-light.svg?v=24.7.2.0"
                          className="h-[50px] w-[50px] mt-1.5"
                        ></img>
                        <p className="text-center font-Share">Base</p>
                      </div>
                      <div
                        className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                        onClick={() => connectEvmWallet(networks.bsc.chainId)}
                      >
                        <img
                          src="https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg?v=24.7.2.0"
                          className="h-[50px] w-[50px] mt-1.5"
                        ></img>
                        <p className="text-center font-Share">BSC</p>
                      </div>
                      <div
                        className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                        onClick={connectPhantomWallet}
                      >
                        <img
                          src="https://cryptologos.cc/logos/solana-sol-logo.png"
                          className="h-[50px] w-[50px] mt-1.5"
                        ></img>
                        <p className="text-center font-Share">Solana</p>
                      </div>
                      <div
                        className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                        onClick={connectTonWallet}
                      >
                        <img
                          src="https://cryptologos.cc/logos/toncoin-ton-logo.png"
                          className="h-[50px] w-[50px] mt-1.5"
                        ></img>
                        <p className="text-center font-Share">Ton</p>
                      </div>
                      <div
                        className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                        onClick={connectTronWallet}
                      >
                        <img
                          src="https://cryptologos.cc/logos/tron-trx-logo.png"
                          className="h-[50px] w-[50px] mt-1.5"
                        ></img>
                        <p className="text-center font-Share">Tron</p>
                      </div>
                      <div
                        className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                        onClick={connectBtcWallet}
                      >
                        <img
                          src="https://cryptologos.cc/logos/bitcoin-btc-logo.png"
                          className="h-[50px] w-[50px] mt-1.5"
                        ></img>
                        <p className="text-center font-Share">Bitcoin</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="lg:hidden block">
        <div className=" bg-[#5c27fe] border-b border-black flex justify-end py-5 px-4">
          <i
            class="fa-solid fa-bars fa-xl"
            style={{ color: "#f8f9fc" }}
            onClick={handleMobileDropdown}
            ref={buttonRef}
          ></i>
        </div>
        {isMobileDropdownOpen && (
          <div
            className="dropdown-content text-black z-50 w-[14rem] absolute right-5 mt-2 top-10 animate__animated animate__fadeInUp"
            ref={dropdownRef}
          >
            <div className="flex flex-col gap-3 bg-black text-white rounded-lg p-5">
              <NavLink to="/">
                <a className="text-white text-center cursor-pointer">Home</a>
              </NavLink>
              {/* <NavLink to="/sale/pre_sale_of_rmc_tokens/action=Pre_sale">
              <a className="text-white text-center cursor-pointer">
                Join Pre-Sale
              </a>
            </NavLink> */}
              {walletAddress && (
                <>
                  <NavLink to="/RMC_tokens_trans_history/q=trans_history">
                    <a className="text-white text-center cursor-pointer">
                      Trx History
                    </a>
                  </NavLink>
                  <NavLink to="/RMC_tokens_Support">
                    <a className="text-white text-center cursor-pointer">
                      Debate
                    </a>
                  </NavLink>
                  <NavLink to="/Stake/stake_of_rmc_tokens/action=stake">
                    <a className="text-white text-center cursor-pointer">Stake</a>
                  </NavLink>
                  <NavLink to="/Bridge/bridge_of_rmc_tokens/action=bridge">
                    <a className="text-white text-center cursor-pointer ">
                      RMC Bridge
                    </a>
                  </NavLink>
                  <NavLink to="/RMC_tokens_Stake_history_claim_tokens">
                    <a className="text-white text-center cursor-pointer">
                      Claim Tokens
                    </a>
                  </NavLink>
                </>
              )}
              <NavLink to="/RMC_tokens_FAQ">
                <a className="text-white text-center cursor-pointer">FAQ</a>
              </NavLink>

              <div className="z-10 relative">
                {address || evmConnected ? (
                  <div className="flex flex-col items-center gap-1">
                    <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
                      {evmAddress} (EVM)
                    </span>
                    <button
                      onClick={disconnectEvmWallet}
                      className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
                    >
                      Disconnect
                    </button>
                  </div>
                ) : phantomConnected ? (
                  <div className="flex flex-col items-center gap-1">
                    <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
                      {phantomAddress} (SOLANA)
                    </span>
                    <button
                      onClick={disconnectPhantomWallet}
                      className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
                    >
                      Disconnect
                    </button>
                  </div>
                ) : userFriendlyAddress !== "" ? (
                  <div className="flex flex-col items-center gap-1">
                    <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
                      {tonAddress} (TON)
                    </span>
                    <button
                      onClick={disconnectTonWallet}
                      className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
                    >
                      Disconnect
                    </button>
                  </div>
                ) : tronConnected ? (
                  <div className="flex flex-col items-center gap-1">
                    <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
                      {tronAddress} (TRON)
                    </span>
                    <button
                      onClick={disconnectTronWallet}
                      className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
                    >
                      Disconnect
                    </button>
                  </div>
                ) : btcAddress === true ? (
                  <div className="flex flex-col items-center gap-1">
                    <span className="text-white lg:text-sm 2xl:text-lg font-poppins">
                      {btcAddress} (BTC)
                    </span>
                    <button
                      onClick={disconnectBtcWallet}
                      className="text-white lg:text-sm 2xl:text-lg font-poppins bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg transition-colors duration-300"
                    >
                      Disconnect
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={handleDropdown}
                    className="text-white lg:text-sm 2xl:text-lg font-poppins bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg transition-colors duration-300"
                  >
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {isDropdownOpen &&
          !isConnected &&
          !phantomConnected &&
          !userFriendlyAddress &&
          !tronAddress &&
          !btcAddress && (
            <div className="fixed w-full h-screen left-0 top-0 model-overlay z-50 flex items-center justify-center backdrop-blur-md bg-black/30 animate_animated animate_fadeInUp">
              <div className="relative w-[95%] text-sm md:w-[60%] lg:w-[45%] xl:w-[40%] text-white rounded-2xl bg-[#AA4CE4] border border-black p-3">
                <div className="flex justify-end">
                  <FontAwesomeIcon
                    icon={faXmark}
                    className=" cursor-pointer text-xs"
                    onClick={handleDropdown}
                  />
                </div>
                <p
                  className="text-center font-joti text-xl tracking-wider"
                  style={{
                    textShadow: "2px 2px 4px rgba(255, 255, 255, 0.4)",
                  }}
                >
                  Choose your preferred Wallet
                </p>
                <div className="grid grid-cols-2 gap-5 px-3 md:px-7 xl:px-10 mt-5 mb-10">
                  <div
                    className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                    onClick={() => connectEvmWallet(networks.ethereum.chainId)}
                  >
                    <img
                      src="https://etherscan.io/images/svg/brands/ethereum-original.svg"
                      className="h-[50px] w-[50px] mt-1.5"
                    ></img>
                    <p className="text-center font-Share">Ethereum</p>
                  </div>
                  <div
                    className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                    onClick={() => connectEvmWallet(networks.polygon.chainId)}
                  >
                    <img
                      src="https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.6.3.0"
                      className="h-[50px] w-[50px] mt-1.5"
                    ></img>
                    <p className="text-center font-Share">Polygon</p>
                  </div>
                  <div
                    className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                    onClick={() => connectEvmWallet(networks.base.chainId)}
                  >
                    <img
                      src="https://basescan.org/assets/base/images/svg/logos/chain-light.svg?v=24.7.2.0"
                      className="h-[50px] w-[50px] mt-1.5"
                    ></img>
                    <p className="text-center font-Share">Base</p>
                  </div>
                  <div
                    className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                    onClick={() => connectEvmWallet(networks.bsc.chainId)}
                  >
                    <img
                      src="https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg?v=24.7.2.0"
                      className="h-[50px] w-[50px] mt-1.5"
                    ></img>
                    <p className="text-center font-Share">BSC</p>
                  </div>
                  <div
                    className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                    onClick={connectPhantomWallet}
                  >
                    <img
                      src="https://cryptologos.cc/logos/solana-sol-logo.png"
                      className="h-[50px] w-[50px] mt-1.5"
                    ></img>
                    <p className="text-center font-Share">Solana</p>
                  </div>
                  <div
                    className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                    onClick={connectTonWallet}
                  >
                    <img
                      src="https://cryptologos.cc/logos/toncoin-ton-logo.png"
                      className="h-[50px] w-[50px] mt-1.5"
                    ></img>
                    <p className="text-center font-Share">Ton</p>
                  </div>
                  <div
                    className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                    onClick={connectTronWallet}
                  >
                    <img
                      src="https://cryptologos.cc/logos/tron-trx-logo.png"
                      className="h-[50px] w-[50px] mt-1.5"
                    ></img>
                    <p className="text-center font-Share">Tron</p>
                  </div>
                  <div
                    className="rounded-2xl cursor-pointer px-3 py-1 duration-500 flex flex-row-reverse gap-3 items-center justify-center bg-[#4D4E5357] border border-[#1C213E30] shadow-md shadow-black/30 hover:shadow-md hover:shadow-white/40"
                    onClick={connectBtcWallet}
                  >
                    <img
                      src="https://cryptologos.cc/logos/bitcoin-btc-logo.png"
                      className="h-[50px] w-[50px] mt-1.5"
                    ></img>
                    <p className="text-center font-Share">Bitcoin</p>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>

    </div>
  );
}
