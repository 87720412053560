import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminHeader from "./AdminHeader";
import toast, { Toaster } from "react-hot-toast";
import { NODE_URL, TELEGRAM_URL1 } from "../Components/Config";
import Layout from "./Layout";

const Settings = () => {
  const [PreOrder, setPreOrder] = useState(null);
  const [TokenUSDprice, setTokenUSDprice] = useState(null);
  const [MinQuantity, setMinQuantity] = useState(null);
  const [MaxQuantity, setMaxQuantity] = useState(null);
  const [PreSaleStatus, setPreSaleStatus] = useState(null);
  const [showText, setshowText] = useState();

  const handlePreOrderChange = async () => {
    try {
      if (PreOrder === null) {
        toast.error("Enter the Amount");
        return false;
      }

      const response = await axios.get(
        `${NODE_URL}/apiv2/PreOrder?PreOrder=${PreOrder}`
      );
      console.log(response.data.status);
      if (response && response.data.status === "success") {
        toast.success("PreOrder Updated Successfully");
      } else {
        toast.error("Failed to Update PreOrder");
      }
      setPreOrder("");
    } catch (error) {
      console.error("Error in PreOrder :", error);
    }
  };

  const handleUSDpriceChange = async () => {
    try {
      if (TokenUSDprice === null) {
        toast.error("Enter the Amount");
        return false;
      }

      const response = await axios.get(
        `${NODE_URL}/apiv2/TokenUSDprice?TokenUSDprice=${TokenUSDprice}`
      );
      console.log(response.data.status);
      if (response && response.data.status === "success") {
        toast.success("Token Price Updated Successfully");

        const currentTime = new Date();
        const timeIn3Minutes = new Date(currentTime.getTime() + 1 * 60000);

        const formattedDate = timeIn3Minutes.toLocaleDateString("en-CA");
        const formattedTime = timeIn3Minutes.toTimeString().split(" ")[0];

        const announcement = {
          message: `The token USD price has been updated to ${TokenUSDprice}`,
          date: formattedDate,
          time: formattedTime,
        };

        console.log(announcement);

        const telegramResponse = await axios.post(
          `${TELEGRAM_URL1}/apiv5/postAnnouncement`,
          announcement
        );
        setTokenUSDprice(null);
      } else {
        toast.error("Failed to Update Token Price");
      }
      setTokenUSDprice("");
    } catch (error) {
      console.error("Error in Token USD Price :", error);
    }
  };

  const handleMinQuantityChange = async () => {
    try {
      if (MinQuantity === null) {
        toast.error("Enter the Amount");
        return false;
      }

      const response = await axios.get(
        `${NODE_URL}/apiv2/MinAmount?MinAmount=${MinQuantity}`
      );
      console.log(response.data.status);
      if (response && response.data.status === "success") {
        toast.success("Minimun Amount Updated Successfully");
        setMinQuantity(null);
      } else {
        toast.error("Failed to Update Minimun Amount");
      }
      setMinQuantity("");
    } catch (error) {
      console.error("Error in Minimun Token Price :", error);
    }
  };

  const handleMaxQuantityChange = async () => {
    try {
      if (MaxQuantity === null) {
        toast.error("Enter the Amount");
        return false;
      }

      const response = await axios.get(
        `${NODE_URL}/apiv2/MaxAmount?MaxAmount=${MaxQuantity}`
      );
      console.log(response.data.status);
      if (response && response.data.status === "success") {
        toast.success("Maximun Amount Updated Successfully");
        setMaxQuantity(null);
      } else {
        toast.error("Failed to Update Maximun Amount");
      }
      setMaxQuantity("");
    } catch (error) {
      console.error("Error in Maximun Token Price :", error);
    }
  };

  const handleEndPresale = async () => {
    try {
      const response = await axios.get(`${NODE_URL}/apiv2/endPreSale`);
      console.log(response.data.status);
      if (response && response.data.status === "success") {
        FetchPresaleStatus();
        toast.success("Pre-Sale Ended");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to End Pre-Sale");
      }
    } catch (error) {
      console.error("Error in Ending Pre-Sale :", error);
    }
  };

  const FetchPresaleStatus = async () => {
    try {
      const result = await axios.get(`${NODE_URL}/apiv2/fetchPresaleStatus`);
      console.log(result, "result of pre sale status");
      if (result.data.status == true && result.data.result == "inactive") {
        setshowText(true);
      } else {
        setshowText(false);
      }
    } catch (err) {
      console.log(err);
      setshowText(false);
    }
  };

  useEffect(() => {
    FetchPresaleStatus();
  }, []);

  return (
    <div className="flex flex-col lg:flex-row">
      <Layout></Layout>
      <div
        className="w-[100%] bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: 'url("/assets/purple.png")' }}
      >
        <div className="relative bg-no-repeat bg-center bg-cover h-screen overflow-hidden overflow-y-auto pt-10 pl-[10%] pr-[10%] items-center ">
          <div className=" grid md:grid-cols-2 gap-10">
            <div className=" p-6  mt-10 rounded shadow-md bg-[#9d47d2]">
              <h1 className="text-xl font-bold mb-4 text-white">Pre Order</h1>
              <div className="mb-4 ">
                <label className="block text-white">Enter the Quantity</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-[#fff] text-black p-2 border border-gray-300 rounded"
                  value={PreOrder}
                  onChange={(e) => setPreOrder(e.target.value)}
                />
              </div>
              <button
                onClick={handlePreOrderChange}
                className="w-full bg-[#000] text-white font-bold p-2 rounded "
              >
                Update Pre Order Amount
              </button>
            </div>

            <div className=" p-6 mt-10 rounded shadow-md bg-[#9d47d2]">
              <h1 className="text-xl font-bold mb-4 text-white">
                Token Price in USD
              </h1>
              <div className="mb-4 ">
                <label className="block text-white">Enter the Price:</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-[#fff] text-black p-2 border border-gray-300 rounded"
                  value={TokenUSDprice}
                  onChange={(e) => setTokenUSDprice(e.target.value)}
                />
              </div>
              <button
                onClick={handleUSDpriceChange}
                className="w-full bg-[#000] text-white font-bold p-2 rounded "
              >
                Update USD Price
              </button>
            </div>

            <div className=" p-6 mt-10 mb-10 rounded shadow-md bg-[#9d47d2]">
              <h1 className="text-xl font-bold mb-4 text-white">
                Minimum Tokens Purchases
              </h1>
              <div className="mb-4 ">
                <label className="block text-white">Enter the Quantity:</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-[#fff] text-black p-2 border border-gray-300 rounded"
                  value={MinQuantity}
                  onChange={(e) => setMinQuantity(e.target.value)}
                />
              </div>

              <button
                onClick={handleMinQuantityChange}
                className="w-full bg-[#000] text-white font-bold p-2 rounded "
              >
                Update Minimun Quantity
              </button>
            </div>

            <div className=" p-6 mt-10 mb-10 rounded shadow-md bg-[#9d47d2]">
              <h1 className="text-xl font-bold mb-4 text-white">
                Maximum Tokens Purchases
              </h1>
              <div className="mb-4 ">
                <label className="block text-white">Enter the Quantity:</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-[#fff] text-black p-2 border border-gray-300 rounded"
                  value={MaxQuantity}
                  onChange={(e) => setMaxQuantity(e.target.value)}
                />
              </div>

              <button
                onClick={handleMaxQuantityChange}
                className="w-full bg-[#000] text-white font-bold p-2 rounded "
              >
                Update Maximum Quantity
              </button>
            </div>

            <div className=" p-6 mt-10 mb-10 rounded shadow-md bg-[#9d47d2]">
              <h1 className="text-xl font-bold mb-4 text-white">
                End Pre-Sale
              </h1>
              {showText ? (
                <p className="text-black text-center">
                  Presale Ended Successfully!!!!
                </p>
              ) : (
                <button
                  onClick={handleEndPresale}
                  className="w-full bg-[#000] text-white font-bold p-2 rounded "
                >
                  End Pre Sale
                </button>
              )}
            </div>

            {/* <div className=" p-6  mt-10 rounded shadow-md bg-[#9d47d2]">
            <h1 className="text-xl font-bold mb-4 text-white">Enable Admin Token Tran</h1>
            <div className="mb-4 ">
              <label className="block text-white">Enter the Quantity</label>
              <input
                type="text"
                className="mt-1 block w-full bg-[#fff] text-black p-2 border border-gray-300 rounded"
                value={PreOrder}
                onChange={(e) => setPreOrder(e.target.value)}
              />
            </div>
            <button
              onClick={handlePreOrderChange}
              className="w-full bg-[#000] text-white font-bold p-2 rounded "
            >
              Update Pre Order Amount
            </button>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
