// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const user = window.sessionStorage.getItem('adminLoggedIn');
    const isLoggedIn = user ? true : false;

    return isLoggedIn ? children : <Navigate to="/Admin/login" />;
};

export default ProtectedRoute;
