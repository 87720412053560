import React, { useEffect, useState } from "react";
import axios from "axios";
import AdminHeader from "./AdminHeader";

const ManagePoll = () => {
  const [polls, setPolls] = useState([]);
  const [votes, setVotes] = useState({});

  useEffect(() => {
    // Fetch all polls
    axios
      .get("http://localhost:3008/polls")
      .then((response) => {
        setPolls(response.data);
      })
      .catch((error) => {
        console.error("Error fetching polls:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch votes for each poll
    const fetchVotes = async () => {
      const votesData = {};
      for (const poll of polls) {
        try {
          const response = await axios.get(
            `http://localhost:3008/polls/${poll.id}/votes`
          );
          votesData[poll.id] = response.data;
        } catch (error) {
          console.error(`Error fetching votes for poll ${poll.id}:`, error);
        }
      }
      setVotes(votesData);
    };

    fetchVotes();
  }, [polls]);

  const getHighestVotedOption = (poll) => {
    const optionVotes = poll.options.map((option, index) => {
      return (
        votes[poll.id]?.filter((vote) => vote.optionIndex === index).length || 0
      );
    });
    const highestVoteCount = Math.max(...optionVotes);
    const highestVotedOptions = poll.options.filter(
      (option, index) => optionVotes[index] === highestVoteCount
    );
    return highestVotedOptions.join(", ");
  };

  return (
    <div
      className="bg-page min-h-screen bg-gray-100 "
      style={{ backgroundImage: 'url("/assets/bcg-image.png")' }}
    >
      <AdminHeader />
      <div className="p-6">
        <h1 className="text-2xl text-white font-bold mb-4 pt-10">
          Poll Results
        </h1>
        {polls.map((poll) => (
          <div
            key={poll.id}
            className="mb-8 bg-[#080b19] p-4 border rounded-xl shadow text-white"
          >
            <h2 className="text-xl font-semibold mb-2">{poll.question}</h2>
            <ul className="list-disc ml-5">
              {poll.options.map((option, index) => {
                const optionVotes =
                  votes[poll.id]?.filter((vote) => vote.optionIndex === index)
                    .length || 0;
                return (
                  <li key={index} className="mb-1">
                    {option}:{" "}
                    <span className="font-semibold">{optionVotes} votes</span>
                  </li>
                );
              })}
            </ul>
            <p className="mt-2">
              <span className="font-bold">Highest Voted Option(s):</span>{" "}
              {getHighestVotedOption(poll)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManagePoll;
