import { useEffect, useState, useRef, useCallback } from "react";
import React from "react";
import Header from "./AdminHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import {
  format,
  isToday,
  isYesterday,
  formatDistanceToNowStrict,
  parseISO,
} from "date-fns";
import {
  faCheck,
  faChevronDown,
  faEllipsis,
  faMagnifyingGlass,
  faMicrophone,
  faPaperclip,
  faPaperPlane,
  faPencil,
  faPhone,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash.debounce";
import axios from "axios";
// import Modal from 'react-modal';
import "../Components/Scroll.css";
import socketIOClient, { io } from "socket.io-client";
// import { walletAddress } from './Header';
import { NODE_URL } from "../Components/Config";
import toast, { Toaster } from "react-hot-toast";
import Layout from "./Layout";

export default function Chat() {
  // const { address, chainId, isConnected } = useWeb3ModalAccount()
  const [activeButton, setActiveButton] = useState("div1");
  const [chatButton, setchatButton] = useState("0");
  const [messages, setMessages] = useState([]);
  const [TotalMessages, setTotalMessages] = useState([]);
  const [input, setInput] = useState("");
  const [Address, setAddress] = useState("");
  const [showMsgBox, setshowMsgBox] = useState(false);
  const [chatMessages, setchatMessages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [currentDateLabel, setCurrentDateLabel] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);

  const [filteredChats, setFilteredChats] = useState([]);
  const [selectedChatIndex, setSelectedChatIndex] = useState(null);
  const [selectedSearchResult, setSelectedSearchResult] = useState(null);

  const handleAnchorClick = (id) => {
    setActiveButton(id);
  };
  const handleChatButton = async (id, walletAddress) => {
    setchatButton(id);
    setAddress(walletAddress);
    window.localStorage.setItem("rec_Address", walletAddress);
    setSelectedChatIndex(id);
    setSelectedSearchResult(walletAddress); // Set the selected search result
    setshowMsgBox(true); // Show the chat box

    await axios
      .get(
        `${NODE_URL}/apiv2/getMessagesByWalletAddress?walletAddress=${walletAddress}&toAddress=ADMIN`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_AUTH_KEY,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == true) {
          setchatMessages(res.data.data);
        } else {
          setchatMessages([]);
        }
      });
  };

  const chatContainerRef = useRef(null);
  const latestMessageRef = useRef(null);
  const formatMessageDate = (dateString) => {
    const date = parseISO(dateString);

    if (isToday(date)) {
      return "Today";
    } else if (isYesterday(date)) {
      return "Yesterday";
    } else if (formatDistanceToNowStrict(date, { unit: "day" }) < 7) {
      return format(date, "EEEE");
    } else {
      return format(date, "MMM d, yyyy");
    }
  };
  const groupMessagesByDate = (messages) => {
    const groupedMessages = {};

    messages.forEach((msg) => {
      const dateLabel = formatMessageDate(msg.sent_at);
      if (!groupedMessages[dateLabel]) {
        groupedMessages[dateLabel] = [];
      }
      groupedMessages[dateLabel].push(msg);
    });

    return groupedMessages;
  };
  const socket = io("https://realmemecoin.com", { transports: ["websocket"] });

  const sendMessage = async (file = null) => {
    if (input.trim() !== "") {
      const formData = {
        fromAddress: "ADMIN",
        message: input,
        toAddress: Address,
      };

      try {
        await axios
          .get(
            `${NODE_URL}/apiv2/sendMessagesToEveryone?fromAddress=ADMIN&toAddress=${Address}&message=${input}`
          )
          .then((res) => {
            if (res.data.status == true) {
              socket.emit("sendUserMessage", formData);
              socket.emit("newUserChat", "ADMIN", Address);
              getMessages();
              data();
            } else {
              toast.error("Cant able to send Messages..please try again later");
              return false;
            }
          });
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }

    const currentTimestamp = new Date().toISOString();

    // setchatMessages((prevMessages) => [
    //     ...prevMessages,
    //     { message: input, sender_id: "ADMIN",recipient_id:Address, sent_at: currentTimestamp }
    // ]);

    setInput("");
  };

  const getMessages = async () => {
    await axios
      .get(`${NODE_URL}/apiv2/getAllMessagesOfUser?walletAddress=ADMIN`, {
        headers: { "x-api-key": process.env.REACT_APP_AUTH_KEY },
      })
      .then((res) => {
        console.log(res, "all messages");
        if (res.data.status === true) {
          setAddress(res.data.data[0]?.sender_id);
          setTotalMessages(res.data.data);
        } else {
          setTotalMessages([]);
        }
      });
  };

  useEffect(() => {
    setshowMsgBox(false);
    getMessages();
  }, []);

  const combineMessagesByUser = (messages) => {
    const combinedMessages = {};

    messages.forEach((msg) => {
      const otherUserId =
        msg.sender_id !== "ADMIN" ? msg.sender_id : msg.recipient_id;

      if (otherUserId) {
        if (!combinedMessages[otherUserId]) {
          combinedMessages[otherUserId] = {
            sender_id: otherUserId,
            messages: [],
          };
        }
        combinedMessages[otherUserId].messages.push({
          message: msg.message,
          sent_at: msg.sent_at,
        });
      }
    });

    // Convert combinedMessages object to array and sort by the most recent message timestamp
    const sortedMessages = Object.values(combinedMessages)
      .map((chat) => {
        return {
          ...chat,
          lastMessageTime: chat.messages.reduce((latest, msg) => {
            const msgTime = new Date(msg.sent_at).getTime();
            return msgTime > latest ? msgTime : latest;
          }, 0),
          messages: chat.messages.sort(
            (a, b) => new Date(a.sent_at) - new Date(b.sent_at)
          ),
        };
      })
      .sort((a, b) => b.lastMessageTime - a.lastMessageTime);

    return sortedMessages;
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      console.log("enter");
      sendMessage();
    }
  };
  const formatDateTime = (dateString) => {
    if (!dateString) return "";
    const date = parseISO(dateString);
    return format(date, "p");
  };

  useEffect(() => {
    if (latestMessageRef.current) {
      latestMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      if (file.size <= 15 * 1024 * 1024) {
        setSelectedFile(file);
        sendMessage(file);
      } else {
        alert("File size should be below 15MB.");
      }
    } else {
      alert("file not found");
    }
  };

  const isImageFile = (fileName) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(fileName);
  };

  const isVideoFile = (fileName) => {
    return /\.(mp4|mov|avi)$/i.test(fileName);
  };
  function renderFilePreview(fileName) {
    if (!fileName) return null;

    const fileExt = getFileExtension(fileName);

    switch (fileExt.toLowerCase()) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return (
          <img
            src={`/file_Docs/${fileName}`}
            alt="Image"
            className="w-9/12 rounded-xl"
          />
        );
      case "mp4":
      case "mov":
      case "avi":
        return (
          <video
            src={`/file_Docs/${fileName}`}
            className="w-9/12 rounded-xl"
            controls
          />
        );
      case "pdf":
        return (
          <img
            src="https://cdn-icons-png.flaticon.com/512/7670/7670113.png"
            alt="PDF Document"
            className="w-9/12 rounded-xl"
          />
        );
      case "doc":
        return (
          <img
            src="https://png.pngtree.com/png-vector/20190406/ourmid/pngtree-doc-file-document-icon-png-image_913809.jpg"
            alt="Word Document"
            className="w-9/12 rounded-xl"
          />
        );
      case "docx":
        return (
          <img
            src="https://png.pngtree.com/png-vector/20190406/ourmid/pngtree-doc-file-document-icon-png-image_913809.jpg"
            alt="Word Document"
            className="w-9/12 rounded-xl"
          />
        );
      case "xls":
        return (
          <img
            src="https://image.pngaaa.com/843/2161843-small.png"
            alt="Excel Document"
            className="w-9/12 rounded-xl"
          />
        );
      case "xlsx":
        return (
          <img
            src="https://image.pngaaa.com/843/2161843-small.png"
            alt="Excel Document"
            className="w-9/12 rounded-xl"
          />
        );
      default:
        return (
          <img
            src="https://freeiconshop.com/wp-content/uploads/edd/folder-open-flat.png"
            alt="File"
            className="w-9/12 rounded-xl"
          />
        );
    }
  }

  function getFileExtension(fileName) {
    return fileName.split(".").pop();
  }

  const closePreview = () => {
    setPreviewImage(null);
    setIsPreviewOpen(false);
  };

  const groupedMessages = groupMessagesByDate(chatMessages);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;
      const messageElements = Array.from(
        chatContainer.getElementsByClassName("message-group")
      );
      for (let i = messageElements.length - 1; i >= 0; i--) {
        const messageElement = messageElements[i];
        const { top } = messageElement.getBoundingClientRect();
        if (top <= 100) {
          setCurrentDateLabel(messageElement.getAttribute("data-date-label"));
          break;
        }
      }
    }
  };
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [chatMessages]);

  const data = async () => {
    const combinedMessages = combineMessagesByUser(TotalMessages);
    console.log(combinedMessages);
    if (combinedMessages.length > 0) {
      setAddress(combinedMessages[0].sender_id);
      console.log(combinedMessages[0].sender_id, Address);
      // Fetch messages for the selected user
      await axios
        .get(
          `${NODE_URL}/apiv2/getMessagesByWalletAddress?walletAddress=ADMIN&toAddress=${combinedMessages[0].sender_id}`,
          {
            headers: {
              "x-api-key": process.env.REACT_APP_AUTH_KEY,
            },
          }
        )
        .then((res) => {
          if (res.data.status === true) {
            console.log(res.data.data);
            setchatMessages(res.data.data);
          } else {
            setchatMessages([]);
          }
          console.log(res);
        });
    }
  };

  useEffect(() => {
    data();
  }, [TotalMessages]);

  console.log(chatMessages);

  useEffect(() => {
    const handleNewUserMessage = (message) => {
      console.log(message, Address);
      const ADD = window.localStorage.getItem("rec_Address");
      if (ADD && message.sender_id == ADD && message.recipient_id == "ADMIN") {
        setchatMessages((prevMessages) => [...prevMessages, message]);
      }
    };

    socket.on("connect", () => {
      console.log("Connected to socket server");
    });

    socket.on("newUserMessage", handleNewUserMessage);

    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    socket.on("newUserChat", (address) => {
      getMessages(address);
    });

    return () => {
      socket.off("newUserMessage", handleNewUserMessage);
      socket.disconnect();
    };
  }, []);

  const combinedMessages = combineMessagesByUser(TotalMessages);

  // useEffect(() => {
  //     if (searchQuery.trim() === '') {
  //         setFilteredChats(combinedMessages);
  //     } else {
  //         const filtered = combinedMessages.filter(chat =>
  //             chat.sender_id.toLowerCase().includes(searchQuery.toLowerCase())
  //         );
  //         setFilteredChats(filtered);
  //     }
  // }, [searchQuery, combinedMessages]);

  const handleSearch = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term) {
      try {
        const response = await axios.get(NODE_URL + "/apiv2/search", {
          params: {
            term: term,
            excludeAddress: "ADMIN",
          },
        });

        const searchResults = response.data;

        const filteredResults = searchResults.filter(
          (result) => result.WalletAddress.toLowerCase() === term.toLowerCase()
        );

        setResults(filteredResults);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      setResults([]);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row">
      <Layout></Layout>
      <div
        className="w-[100%] bg-cover bg-center bg-no-repeat min-h-screen"
        style={{ backgroundImage: 'url("/assets/purple.png")' }}
      >
        <div className=" h-screen overflow-hidden overflow-y-scroll">

          <div className="flex flex-col md:flex-row gap-5 md:gap-0 my-3  w-[90%] md:w-[80%] mx-auto overflow-hidden font-poppins text-white bg-[#080808]/30 backdrop-blur-md bg-cover bg-center bg-no-repeat rounded-xl shadow-md shadow-black/25 border border-[#0A0F21]/20">
            <div className="md:w-[30%] py-5 flex flex-col gap-5 bg-[#4d2780] backdrop-blur-md ">
              <div className="flex justify-between items-center px-5">
                <div>
                  <img src="/assets/Group 12.png"></img>
                </div>
                <div className="flex flex-col ">
                  <p className="font-syne text-xl">SUPER ADMIN</p>
                 </div>
              </div>
              <div className="relative px-5">
                <div className="flex rounded-full border px-3 py-2">
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </div>
                  <input
                    type="text"
                    className="outline-none bg-transparent px-3 w-[95%] text-xs"
                    placeholder="Search or start new chat"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
                <div className="search-results absolute top-full left-0 w-full z-10">
                  {results.length > 0 && (
                    <ul className="bg-[#434344] rounded-b-xl p-2 text-sm">
                      {results.map((item, index) => (
                        <React.Fragment key={item.WalletAddress}>
                          <li
                            onClick={() => {
                              handleChatButton(index, item.WalletAddress);
                              setResults([]);
                            }}
                            className="cursor-pointer hover:bg-gray-700 p-2 rounded"
                          >
                            {item.WalletAddress}
                          </li>
                          {index < results.length - 1 && (
                            <hr className="my-2 border-black" />
                          )}
                        </React.Fragment>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div className="px-8">
                <div className="flex gap-10 text-sm ">
                  <button
                    onClick={() => handleAnchorClick("div1")}
                    className={`${
                      activeButton === "div1"
                        ? "text-white font-bold"
                        : "text-white/50"
                    }`}
                  >
                    ALL CHATS
                  </button>
                </div>
              </div>
              <div
                id="div1"
                className="duration-500 lg:h-[528px] overflow-hidden overflow-y-auto"
                style={{ display: activeButton === "div1" ? "block" : "none" }}
              >
                {combinedMessages && combinedMessages.length > 0
                  ? combinedMessages.map((Msg, index) => (
                      <div
                        key={index}
                        className={`user1 flex py-2 px-8 cursor-pointer hover:bg-black/40 rounded ${
                          Address === Msg.sender_id ? "bg-black/60" : ""
                        }`}
                        onClick={() => handleChatButton(index, Msg.sender_id)}
                      >
                        <div className="w-[20%] flex">
                          <img
                            src="/assets/dp.jpg"
                            className="w-[50px] h-[50px] rounded-full"
                          />
                          <div className="flex items-end relative">
                            <div className="absolute w-2.5 h-2.5 border bg-green-500 rounded-full -translate-x-2 -translate-y-1"></div>
                          </div>
                        </div>
                        <div className="w-[60%] pl-3">
                          <p className="font-bold">
                            {Msg?.sender_id !== "ADMIN" && (
                              <p>{Msg.sender_id.substring(0, 10) + "..."}</p>
                            )}
                            {Msg?.recipient_id &&
                              Msg.recipient_id !== "ADMIN" && (
                                <p>
                                  {Msg.recipient_id.substring(0, 10) + "..."}
                                </p>
                              )}
                            {Msg?.sender_id === "ADMIN" && (
                              <p>{Msg.sender_id.substring(0, 10)}</p>
                            )}
                            {Msg?.recipient_id &&
                              Msg.recipient_id === "ADMIN" && (
                                <p>{Msg.recipient_id.substring(0, 10)}</p>
                              )}
                          </p>
                          <p className="truncate text-white/50 text-sm">
                            Sent From Support Chat
                          </p>
                        </div>
                        <div className="w-[20%]">
                          <p className="text-xs text-white/50">Recent</p>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
            {showMsgBox ? (
              <div className="md:w-[70%] bg-[#1a1037]">
                <div className="grid content-between h-[100%]">
                  <div className=" flex justify-between md:justify-start bg-gradient-to-b from-[#0A0F214D] to-[#AAAAAA4D] rounded-b-2xl py-3 px-5">
                    <div className="md:w-[10%]">
                      <img
                        src="/assets/Ellipse 18.png"
                        className="w-[50px] h-[50px]"
                      />
                    </div>
                    <div className="md:w-[60%] py-2 ">
                      <p className="font-bold">
                        {Address != "ADMIN"
                          ? Address.substring(0, 10) + "..."
                          : Address}
                      </p>
                      <p className="text-xs text-white/50">Online</p>
                    </div>
                    <div className="md:w-[30%] flex justify-end gap-3">
                      <div className="flex items-center h-full">
                        {/* <button className="bg-[#131313] px-2 py-1 text-xl text-white/80 rounded-full">
                          <FontAwesomeIcon icon={faEllipsis} />
                        </button> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="overflow-y-scroll custom-scrollbar flex-grow px-5"
                    style={{ maxHeight: "calc(90vh)" }}
                    ref={chatContainerRef}
                  >
                    {Object.keys(groupedMessages).length === 0 ? (
                      <div className="flex items-center justify-center h-full">
                        <p className="text-gray-500 font-syne">
                          No messages yet
                        </p>
                      </div>
                    ) : (
                      Object.keys(groupedMessages).map((dateLabel, index) => (
                        <div
                          key={index}
                          className="message-group"
                          data-date-label={dateLabel}
                        >
                          <div className="flex justify-center">
                            <button className="bg-white text-black font-syne px-8 py-1 rounded-full">
                              {dateLabel}
                            </button>
                          </div>
                          {groupedMessages[dateLabel].map((msg, idx) => (
                            <div
                              key={idx}
                              ref={
                                idx === groupedMessages[dateLabel].length - 1
                                  ? latestMessageRef
                                  : null
                              }
                            >
                              <div
                                className={`flex ${
                                  msg.sender_id == "ADMIN"
                                    ? "justify-end"
                                    : "justify-start"
                                } mt-5`}
                              >
                                {msg.Docs ? (
                                  <div
                                    className={`flex justify-end items-center gap-2 py-2 lg:py-4 px-5`}
                                    style={{
                                      borderRadius: "15px 0px 35px 21px",
                                    }}
                                  >
                                    <a
                                      className="w-9/12 flex justify-end"
                                      href={`/file_Docs/${msg.Docs}`}
                                      download
                                    >
                                      {renderFilePreview(msg.Docs)}
                                    </a>
                                    <div></div>
                                  </div>
                                ) : (
                                  <div
                                    className={`${
                                      msg.sender_id == "ADMIN"
                                        ? "bg-[#131313] rounded-b-lg rounded-l-lg"
                                        : "bg-[#61C554] rounded-b-lg rounded-r-lg"
                                    } py-2 lg:py-4 px-8`}
                                    
                                  >
                                    <p
                                      className={`${
                                        msg.sender_id == "ADMIN"
                                          ? "text-white"
                                          : "text-black"
                                      } font-syne`}
                                    >
                                      {msg.message}
                                    </p>
                                  </div>
                                )}
                              </div>
                              <div
                                className={`flex ${
                                  msg.sender_id == "ADMIN"
                                    ? "justify-end"
                                    : "justify-start"
                                } lg:w-[100%] lg:px-5 px-3 mt-2`}
                              >
                                <p
                                  className={`${
                                    msg.sender_id == "ADMIN"
                                      ? "text-start"
                                      : "text-end"
                                  } text-white font-syne`}
                                >
                                  {format(parseISO(msg.sent_at), "p")}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))
                    )}
                  </div>
                  <div className="flex gap-3 pb-5 px-5">
                    <div className="flex justify-between w-[90%] bg-[#131313] rounded-full p-3">
                      <input
                        className="bg-transparent px-3 w-[90%] outline-none"
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        value={input}
                        autoFocus
                        placeholder="Type a message"
                      ></input>
                      {/* <div className='flex items-center px-3'>
                                        <FontAwesomeIcon icon={faPaperclip} className='text-xl text-white/50' />
                                    </div> */}
                    </div>
                    <div className="w-[10%] flex justify-center">
                      <button
                        className="bg-[#61C554] h-full w-[80%] rounded-full text-black text-xl "
                        onClick={sendMessage}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="md:w-[70%] bg-[#] ">
                <div className="flex justify-center pt-10">
                  <img
                    className="w-4/12"
                    src="https://cdni.iconscout.com/illustration/premium/thumb/chat-with-friends-2691872-2231685.png"
                  ></img>
                </div>
                <div>
                  <br></br>
                  <p className="text-white text-center text-xl font-bold">
                    No Chats Selected!!
                  </p>
                  <br />
                  <p className="text-white text-center text-xl font-bold">
                    Please select any Older chats to Continue or else Start a
                    new Chat
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
