import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { NODE_URL } from "../Components/Config";
import Chart from "react-apexcharts";
import moment from "moment";

export default function AdminDashboard() {
  
  const [datas, setDatas] = useState({});
  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    fetchDetails();
    getMonthlyCoinAmounts();
  }, []);

  const fetchDetails = async () => {
    try {
      const response = await fetch(`${NODE_URL}/apiv2/getAdminDatas`);
      const result = await response.json();
      if (result.status) {
        setDatas(result.data);
      }
    } catch (error) {
      console.error("Error fetching transaction details: ", error);
    }
  };

  const getMonthlyCoinAmounts = async () => {
    try {
      const response = await fetch(`${NODE_URL}/apiv2/getMonthlyCoinAmounts`);
      const result = await response.json();
      if (result.status) {
        console.log(result.data);
        setSeriesData(result.data);
      }
    } catch (error) {
      console.error("Error fetching transaction details: ", error);
    }
  };

  const getMonthLabels = () => {
    const now = moment();
    const labels = [
      now.clone().subtract(1, "month").format("MMMM"),
      now.format("MMMM"),
      now.clone().add(1, "month").format("MMMM"),
    ];
    return labels;
  };

  const state = {
    options: {
      colors: ["#9381FF"],
      stroke: { width: 2, curve: "smooth" },
      chart: {
        id: "basic-bar",
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: getMonthLabels(),
        labels: {
          style: {
            colors: "#ffffff",
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return Math.floor(value); // Format y-axis labels as integers
          },
          style: {
            colors: "#ffffff",
            fontSize: "12px",
          },
        },
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        theme: "dark",
        style: {
          background: "#9381FF",
        },
      },
    },
    series: [
      {
        name: "Purchased",
        data: seriesData,
      },
    ],
  };
  
  return (
    <div className="flex flex-col lg:flex-row">
      <Layout></Layout>

      <div className="w-[100%] bg-cover bg-no-repeat bg-center bg-[#37159F]  relative flex items-center overflow-hidden min-h-screen py-10 px-10 ">
        <div className="w-full z-10">
          <div className="flex flex-col lg:flex-row justify-center gap-10">
            <div className="lg:w-[45%] bg-[#291A58D4] border-[#787AA0] border-2 bg-opacity-40 rounded-lg flex flex-col lg:flex-row items-center justify-center px-4 py-4">
              <div className="w-[50%] flex flex-col gap-4">
                <div className="flex flex-col gap-3">
                  <p className="text-white font-syne text-center text-xl">Token Sales</p>
                  <p className="text-white text-xl font-syne text-center font-semibold">
                  {Number(datas.totalCoinAmount).toFixed(3)} 
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-[#A9ACB3] font-sm">
                    {/* This week <span className="text-[#00F85F]">36% ^</span> */}
                  </p>
                  <div className="flex justify-center items-center gap-2 px-2">
                    <p className="text-lg text-center font-inter text-white  font-semibold">
                      {/* $13,690 */}
                    </p>
                    <img src="/assets/Vector 7.png"></img>
                  </div>
                </div>
                <div>
                  <p className="text-white font-bold text-center ">
                    {/* Last activity at 21 May, 2021 */}
                    $ {Number(datas.totalTokenAmountUSD).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="w-[50%] flex justify-center">
                <img className="" src="/assets/image 186.png"></img>
              </div>
            </div>
            <div className="lg:w-[45%] border-[#787AA0] border-2 bg-[#291A58D4] bg-opacity-40 rounded-lg ">
              <div className="w-[100%] flex justify-between  px-7 pt-7">
                <div>
                  <p className="text-white text-xl font-syne">
                    Overall Revenue
                  </p>
                  {/* <p className="text-[#00F85F]">36% ^</p> */}
                </div>
                <div>
                  <p className="font-syne text-xl text-white">
                  ${Number(datas.totalTokenAmountUSD).toFixed(5)}
                  </p>
                </div>
              </div>
              <div className="pt-10">
              {seriesData.length > 0 ? (
                <Chart
                  options={state.options}
                  series={state.series}
                  type="line"
                  width="100%"
                  height="100%"
                />
              ) : (
                <p className="text-white text-center">Loading chart...</p>
              )}
            </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-center gap-10 mt-5 px-10">
            <div className="lg:w-[35%] flex flex-col gap-10">
              <div className="bg-[#0A0F214D] rounded-lg border border-[#71747b] py-2">
                <div className="py-4 border-b border-[#71747b]">
                  <p className="text-white  text-center font-syne text-xl font-bold">
                  {datas.userCount}
                  </p>
                </div>
                <p className="text-white font-syne text-center mt-2">
                  Total Users
                </p>
              </div>
              {/*
              <div className='bg-[#0A0F214D] rounded-lg border border-[#71747b] py-2'>
                <div className='py-4 border-b border-[#71747b]'>
                  <p className='text-white  text-center font-syne text-xl font-bold'>4000</p>
                </div>
                <p className='text-white font-syne text-center mt-2'>Total Airdrops</p>
              </div>
              */}
            </div>
            <div className="lg:w-[65%]">
              <img src="/assets/Sales Mapping.png"></img>
            </div>
          </div>
        </div>
        <div className="background shading absolute bg-[#C156DD]/60 z-0 h-[90%] w-[93%] blur-3xl"></div>
      </div>
    </div>
  );
}
